import React from "react";
import { Euler, Vector3 } from "three";

import { useAudio } from "../../audio/hook";
import { MatterportVideo } from "../../matterport-video";

import video from "./clackerboard.mp4";

const position = new Vector3(
  -30.689842309787507,
  2.8061361794516924,
  -50.24055823614774
);

const audioThreshold = {
  min: 1,
  max: 12,
  atenuation: 0.8,
  position: new Vector3(
    -31.329557418823242,
    1.4916725158691406,
    -52.49986267089844
  ),
};

const geometry = { w: 1.9, h: 0.6 };
const rotation = new Euler(-0.015, -1.6126, 0);

const inputs = {
  opacity: 1,
  source: video,
  geometry,
  rotation,
  audioThreshold,
};

export const ClackerboardVideo = () => {
  const sfx = useAudio();

  return (
    <MatterportVideo
      muted={sfx.isMuted()}
      paused={sfx.isAmbientPaused()}
      position={position}
      inputs={inputs}
    />
  );
};
