import styled from "styled-components";

export interface ButtonContainerProps {
  side: "left" | "right";
}

/**
 * A container for menu buttons, to be placed in the same place
 * all over the experience
 */
export const MenuButtonContainer = styled.div<ButtonContainerProps>`
  font-size: 1.28em;
  position: absolute;
  top: 3em;
  ${(props) => props.side}: 1em;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.sm}) {
    top: 1em;
  }

  @media screen and (orientation: landscape) {
    top: 1em;
  }
`;
