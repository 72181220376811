import { useEffect, useRef } from "react";
import { TranslateMatterportTag } from "src/helpers";
import { useMatterportSDK } from "./get-matterport-sdk";

export const useMatterportTagTranslation = () => {
  const sdk = useMatterportSDK();
  const TranslationRef = useRef<TranslateMatterportTag>();

  const updateLanguage = () => TranslationRef.current?.updateAllTags();

  useEffect(() => {
    if (sdk != null && !TranslationRef.current) {
      TranslationRef.current = new TranslateMatterportTag(sdk);
    }
  }, [sdk]);

  return updateLanguage;
};
