import styled from "styled-components";
import FlipPage from "react-flip-page";
import { useTranslation } from "react-i18next";
import React, { useRef, useState, useCallback } from "react";

import Full from "../imgs/full.jpg";
import { Artwork } from "./artwork";
import { PageNav } from "./page-nav";
import { breakpoint } from "src/style";
import { FieldJournalProgressType } from ".";
import { JournalIndex } from "./journal-index";
import { DefaultChapter } from "./default-chapter";
import {
  CentralAmericaChapter,
  DryingChapter,
  HarvestChapter,
  NurseryChapter,
  ProcessingChapter,
  ResearchChapter,
  RoastingChapter,
  VarietalsChapter,
  WorldOfCoffeeChapter,
} from "src/pages/tour/state";

const borderRadius = "15px";

const BookContainer = styled.div`
  top: 50%;
  left: 50%;
  width: 866px;
  color: black;
  position: absolute;
  min-height: 630px;
  border-radius: ${borderRadius};
  background-image: url(${Full});
  background-size: cover;

  @media screen and (min-width: ${breakpoint("xs")}) {
    transform: translate(-50%, -50%) scale(0.4);
  }

  @media screen and (min-width: ${breakpoint("sm")}) {
    transform: translate(-50%, -50%) scale(0.4);
  }

  @media screen and (min-width: ${breakpoint("md")}) {
    transform: translate(-50%, -50%) scale(0.8);
  }

  @media screen and (min-width: ${breakpoint("lg")}) {
    transform: translate(-50%, -50%) scale(0.9);
  }

  @media screen and (min-width: ${breakpoint("xlg")}) {
    transform: translate(-50%, -50%) scale(1.1);
  }

  @media screen and (min-width: ${breakpoint("xlg")}) {
    transform: translate(-50%, -50%) scale(1.2);
  }

  .flip-book {
    border-radius: ${borderRadius} !important;

    .rfp-touchZone {
      cursor: pointer;
      width: 5% !important;
    }

    div {
      border-radius: ${borderRadius};
    }
  }
`;

export const Book: FieldJournalProgressType = ({ progress }) => {
  const { t } = useTranslation();
  const language = t("language-name");

  const flipPageRef = useRef<any>();
  const [currentPage, setCurrentPage] = useState(0);

  const gotoPreviousPage = useCallback(() => {
    flipPageRef.current.gotoPreviousPage();
  }, []);

  const goToPage = useCallback((page: number) => {
    flipPageRef.current.gotoPage(page);
  }, []);

  const gotoNextPage = useCallback(() => {
    flipPageRef.current.gotoNextPage();
  }, []);

  return (
    <BookContainer>
      <FlipPage
        uncutPages
        width="866"
        height="630"
        noShadow={false}
        maxOpacity={0.9}
        flipOnTouch={true}
        perspective="115em"
        loopForever={false}
        disableSwipe={true}
        className={`flip-book page-${currentPage}`}
        animationDuration="250"
        orientation="horizontal"
        pageBackground="transparent"
        onPageChange={(pageIndex: number) => {
          setCurrentPage(pageIndex);
        }}
        ref={(component: any) => {
          flipPageRef.current = component;
        }}
      >
        <JournalIndex progress={progress} goToPage={goToPage} />

        <DefaultChapter
          title="The World Of Coffee"
          unlocked={progress?.[WorldOfCoffeeChapter] || false}
          pageLeft={`/field-journal/${language}/01/01.jpg`}
          pageRight={`/field-journal/${language}/01/02.jpg`}
        />

        <DefaultChapter
          title="Central America"
          unlocked={progress?.[CentralAmericaChapter] || false}
          pageLeft={`/field-journal/${language}/02/01.jpg`}
          pageRight={`/field-journal/${language}/02/02.jpg`}
        />

        <DefaultChapter
          title="Nursery"
          unlocked={progress?.[NurseryChapter] || false}
          pageLeft={`/field-journal/${language}/03/01.jpg`}
          pageRight={`/field-journal/${language}/03/02.jpg`}
        />

        <DefaultChapter
          title="Coffee Varietals"
          unlocked={progress?.[VarietalsChapter] || false}
          pageLeft={`/field-journal/${language}/04/01.jpg`}
          pageRight={`/field-journal/${language}/04/02.jpg`}
        />

        <DefaultChapter
          title="Harvesting"
          unlocked={progress?.[HarvestChapter] || false}
          pageLeft={`/field-journal/${language}/05/01.jpg`}
          pageRight={`/field-journal/${language}/05/02.jpg`}
        />

        <DefaultChapter
          title="Processing"
          unlocked={progress?.[ProcessingChapter] || false}
          pageLeft={`/field-journal/${language}/06/01.jpg`}
          pageRight={`/field-journal/${language}/06/02.jpg`}
        />

        <DefaultChapter
          title="Drying"
          unlocked={progress?.[DryingChapter] || false}
          pageLeft={`/field-journal/${language}/07/01.jpg`}
          pageRight={`/field-journal/${language}/07/02.jpg`}
        />

        <DefaultChapter
          title="Quality Sorting"
          unlocked={progress?.[DryingChapter] || false}
          pageLeft={`/field-journal/${language}/08/01.jpg`}
          pageRight={`/field-journal/${language}/08/02.jpg`}
        />

        <DefaultChapter
          title="Greenhouse"
          unlocked={progress?.[ResearchChapter] || false}
          pageLeft={`/field-journal/${language}/09/01.jpg`}
          pageRight={`/field-journal/${language}/09/02.jpg`}
        />

        <DefaultChapter
          title="Roasting"
          unlocked={progress?.[RoastingChapter] || false}
          pageLeft={`/field-journal/${language}/10/01.jpg`}
          pageRight={`/field-journal/${language}/10/02.jpg`}
        />

        <DefaultChapter
          title="Ethically Sourced"
          unlocked={progress?.[RoastingChapter] || false}
          pageLeft={`/field-journal/${language}/11/01.jpg`}
          pageRight={`/field-journal/${language}/11/02.jpg`}
        />

        <DefaultChapter
          title="Sustainable Future"
          unlocked={progress?.[RoastingChapter] || false}
          pageLeft={`/field-journal/${language}/lastpage/01.jpg`}
          pageRight={`/field-journal/${language}/lastpage/02.jpg`}
        />

        <DefaultChapter
          title="Extra Content"
          unlocked={progress?.[RoastingChapter] || false}
          pageLeft={`/field-journal/${language}/extra-content/01.jpg`}
          pageRight={`/field-journal/${language}/extra-content/02.jpg`}
        />

        <Artwork unlocked={progress?.[RoastingChapter] || false} />

        <DefaultChapter
          title="Americano con Crema Recipe"
          unlocked={progress?.[RoastingChapter] || false}
          pageLeft={`/field-journal/${language}/12/01.jpg`}
          pageRight={`/field-journal/${language}/12/02.jpg`}
        />

        <DefaultChapter
          title="Shakerato Recipe"
          unlocked={progress?.[RoastingChapter] || false}
          pageLeft={`/field-journal/${language}/13/01.jpg`}
          pageRight={`/field-journal/${language}/13/02.jpg`}
        />

        <DefaultChapter
          title="Sparkling Espresso with Mint"
          unlocked={progress?.[RoastingChapter] || false}
          pageLeft={`/field-journal/${language}/14/01.jpg`}
          pageRight={`/field-journal/${language}/14/02.jpg`}
        />
      </FlipPage>

      <PageNav
        page={currentPage}
        goToPage={goToPage}
        gotoNextPage={gotoNextPage}
        gotoPreviousPage={gotoPreviousPage}
      />
    </BookContainer>
  );
};
