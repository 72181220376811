import React, { useCallback, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import VimeoPlayer, { Options, EventName, Player } from "@vimeo/player";
import { BaseOverlay } from "src/pages/tour/components";
import { WhiteOutlinedLinkButton } from "src/components";
import { useTranslation } from "react-i18next";
import { a11yKey, enA11y, frA11y } from "src/i18n";

interface ContainerProps {
  fading?: boolean;
}

const FullScreenVideoContainer = styled.div<ContainerProps>`
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  @keyframes fade-out {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  ${(props) =>
    props.fading &&
    css`
      opacity: 0;
      animation: fade-out ease-in-out 1s;
    `}
`;

const Video = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  > iframe {
    bottom: 0;
    height: 100vh;
    left: 0;
    margin-left: calc(((((100vh * 16) / 9) - 100vw) / 2) * -1);
    position: absolute;
    right: 0;
    top: 0;
    width: calc((100vh * 16) / 9);
  }
`;

const Btn = styled(WhiteOutlinedLinkButton)`
  margin-top: 40px;
  background-color: ${(props) => props.theme.brand.white};
  color: ${(props) => props.theme.brand.black};
`;

const PrivacyText = styled.p`
  max-width: 200px;
  font-size: 16px;
  letter-spacing: 0.5px;
`;

const PrivacyError = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

interface VideoProps {
  onFadeOut?: CallableFunction;
  onEnded: { (): void };
  onCanPlay: { (): void };
  play: boolean;
  onPlay: { (): void };
}

export const IntroVideo = ({
  play,
  onFadeOut,
  onEnded,
  onCanPlay,
  onPlay,
}: VideoProps) => {
  const { t } = useTranslation();
  const [fading, setFadeOut] = useState(false);
  const [playerRef, setPlayerRef] = useState<Player>();
  const [privacyError, setPivacyError] = useState(false);
  const getVideoId = () => {
    let id = 508647493;
    if (sessionStorage.getItem(a11yKey) === enA11y) {
      //English Accessibility
      id = 531400485;
    }
    if (sessionStorage.getItem(a11yKey) === frA11y) {
      //French Accessibility
      id = 535927166;
    }
    return id;
  };
  const getVideoRef = useCallback(
    (div: HTMLDivElement) => {
      if (div) {
        const player = new VimeoPlayer(div, {
          id: getVideoId(),
          controls: false,
        } as Options);

        interface playerEventInterface {
          seconds: number;
          percent: number;
        }

        player.on(
          "timeupdate" as EventName,
          ({ percent }: playerEventInterface) => {
            if (percent >= 98.0) {
              setFadeOut(true);
            }
          }
        );

        player.ready().then(() => {
          onCanPlay();
        });

        player.on("play", () => {
          onPlay();
        });

        player.on("ended" as EventName, () => {
          onEnded();
        });

        player.on(
          "error" as EventName,
          (err: { method: string; name: string }) => {
            console.error(err);
            if (err.method === "play" && err.name === "NotAllowedError") {
              onPlay();
              setPivacyError(true);
            }
          }
        );

        setPlayerRef(player);
      }
    },
    [onCanPlay, onEnded, onPlay]
  );

  useEffect(() => {
    if (playerRef) {
      if (play)
        playerRef?.play().catch(() => {
          onPlay();
          setPivacyError(true);
        });

      if (!play && !privacyError) playerRef.pause();
    }
  }, [play, playerRef, onPlay, setPivacyError, privacyError]);

  useEffect(() => {
    if (fading) onFadeOut?.();
  }, [fading, onFadeOut]);

  return (
    <FullScreenVideoContainer fading={fading}>
      <Video ref={getVideoRef} className="video-loading" />
      <BaseOverlay
        title={t("landing.privacy.playVideo.title", "Privacy Error")}
        shown={privacyError}
      >
        {privacyError && (
          <PrivacyError>
            <PrivacyText>
              {t(
                "landing.privacy.playVideo.message",
                "In order to have the best experience you need to grant permission to the browser so it could execute audio and video."
              )}
            </PrivacyText>
            <Btn
              onClick={() => {
                playerRef?.play().catch(() => {
                  onPlay();
                  setPivacyError(true);
                });
                playerRef?.setMuted(false);
                setPivacyError(false);
              }}
            >
              {t("landing.privacy.button", "Grant Access")}
            </Btn>
          </PrivacyError>
        )}
      </BaseOverlay>
    </FullScreenVideoContainer>
  );
};
