import React, { useCallback, useState } from "react";

import { ai } from "src/telemetryService";
import useAnalytics from "../../../../../../../hooks/use-analytics";
import { Guide, Josh, Meli } from "../../../../guide-selection";

import { Choose } from "./choose";
import { Chosen } from "./chosen";

interface GuideSelectionState {
  video: string;
  name: string;
  guide: Guide;
}

export interface GuideSelectorProps {
  onGuideSelect?: (guide: Guide) => void;
}

export const GuideSelectorContent = ({ onGuideSelect }: GuideSelectorProps) => {
  const [selected, setSelected] = useState<GuideSelectionState | null>(null);
  const { sendEvent } = useAnalytics();

  const handleSelection = useCallback((guide: symbol) => {
    const selected: GuideSelectionState =
      guide === Josh
        ? {
            video: "502316866",
            name: "Josh",
            guide: Josh,
          }
        : {
            video: "502315924",
            name: "Meli",
            guide: Meli,
          };

    setSelected(selected);
    ai.trackEvent({ name: `${selected.name} guide selected` });
    sendEvent(
      "Experience",
      `Tour Guid ${selected.name} Card - click`,
      null,
      {}
    );
  }, []);

  const handleGuideClear = useCallback(() => {
    setSelected(null);
  }, []);

  const handleGuideSelect = useCallback(() => {
    onGuideSelect?.(selected!.guide);
  }, [onGuideSelect, selected]);

  return !selected ? (
    <Choose onChoose={handleSelection} />
  ) : (
    <Chosen
      play={!!selected}
      nameSelected={selected.name}
      videoId={selected.video}
      onFinish={handleGuideSelect}
      onBack={handleGuideClear}
    />
  );
};
