import React from "react";
import styled from "styled-components";

import { Icon } from "src/components";

import { MenuUIButton } from "./button";
import { useTranslation } from "react-i18next";

interface SoundProps extends React.HtmlHTMLAttributes<HTMLButtonElement> {
  muted?: boolean;
}

const SoundToggleButton = styled(MenuUIButton)`
  text-transform: uppercase;
`;

export const SoundToggle = ({ muted, ...props }: SoundProps) => {
  const { t } = useTranslation();
  const statusText = muted ? t("off", "Off") : t("on", "On");
  const iconName = muted ? "speaker-off" : "speaker-on";

  return (
    <SoundToggleButton {...props}>
      <Icon name={iconName} /> {statusText}
    </SoundToggleButton>
  );
};
