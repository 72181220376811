import React, { useCallback } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { WhiteOutlinedButton } from "src/components";

import { useAudio } from "../../audio";
import { BaseOverlay } from "../overlay";
import { BaseOverlayProps } from "../overlay/base";

const Message = styled.p`
  width: 100%;
  display: inline-block;
  font-size: 1.225em;
  line-height: 1.8em;
`;

const Content = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const ChoiceButton = styled(WhiteOutlinedButton)`
  margin: 0 0.5em;
`;

const PriorityOverlay = styled(BaseOverlay)`
  z-index: ${(props) => (props.shown ? 6 : -1)};
`;

type InheritedOverlayProps = Pick<BaseOverlayProps, "onClose" | "shown">;

export interface ExitPromptOverlayProps extends InheritedOverlayProps {
  onCancel: () => void;
}

export const ExitPromptOverlay = ({
  onCancel,
  onClose = onCancel,
  ...props
}: ExitPromptOverlayProps) => {
  const sfx = useAudio();
  const { t } = useTranslation();

  const handleCancel = useCallback(() => {
    sfx.click();
    onCancel?.();
  }, [sfx, onCancel]);

  const handleAccept = useCallback(() => {
    sfx.click();
  }, [sfx]);

  return (
    <PriorityOverlay
      title={t("tour.exit.title", "Leave Farm")}
      onClose={onClose}
      {...props}
    >
      <Content>
        <Message>{t("tour.exit.content", "Go back to the home page?")}</Message>

        <div>
          <ChoiceButton onClick={handleAccept} as={Link} to="/">
            {t("yes", "Yes")}
          </ChoiceButton>
          <ChoiceButton onClick={handleCancel}>{t("no", "No")}</ChoiceButton>
        </div>
      </Content>
    </PriorityOverlay>
  );
};
