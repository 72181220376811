import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { OverlayArticle } from "../../../../../ui/overlay";
import { Title } from "src/pages/tour/components/ui/overlay/content/article";

import image from "./sparkling.jpg";

const RecipeTitle = styled(Title)`
  small {
    opacity: 1;
    font-size: 0.835em;
  }
`;

export const SparklingRecipe = () => {
  const { t } = useTranslation();

  return (
    <OverlayArticle
      image={image}
      title={t(
        "tour.coffee-recipes.sparkling.title",
        "Sparkling Espresso with Mint"
      )}
      subtitle={t("tour.coffee-recipes.sparkling.subtitle", "Serves 1")}
    >
      <RecipeTitle>
        <small>{t("tour.coffee-recipes.ingredients", "INGREDIENTS")}</small>
      </RecipeTitle>

      <ul>
        <li>
          {t(
            "tour.coffee-recipes.sparkling.ingredients.0",
            "2 tablespoons simple syrup"
          )}
        </li>
        <li>
          {t(
            "tour.coffee-recipes.sparkling.ingredients.1",
            "8 sprigs of fresh mint"
          )}
        </li>
        <li>
          {t(
            "tour.coffee-recipes.sparkling.ingredients.2",
            "2 fluid oz espresso"
          )}
        </li>
        <li>
          {t("tour.coffee-recipes.sparkling.ingredients.3", "Sparkling water")}
        </li>
      </ul>

      <RecipeTitle>
        <small>{t("tour.coffee-recipes.directions", "DIRECTIONS")}</small>
      </RecipeTitle>
      <p>
        {t(
          "tour.coffee-recipes.sparkling.directions.0",
          "Fill cocktail shaker with ice. Add classic or simple syrup, fresh mint and espresso. Shake vigorously for about 10 seconds. Strain into a glass filled with ice. Top with sparkling water. Serve and enjoy."
        )}
      </p>
    </OverlayArticle>
  );
};
