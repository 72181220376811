import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import {
  GuideChooser,
  GuideChooserProps,
} from "../../../../guide-selection/guide-chooser";

import { Title } from "../../../../ui/overlay";

const SelectorContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 80vh;
  flex-direction: column;
  width: 100%;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.xs}) {
    flex-direction: row;
    justify-content: center;
  }
`;

export const Choose = (props: GuideChooserProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Title>{t("tour.guide.title", "Choose your guide")}</Title>

      <SelectorContainer>
        <GuideChooser expand {...props} />
      </SelectorContainer>
    </>
  );
};
