import React, { useState, useCallback } from "react";
import { Vector3 } from "three";

import { CustomTag } from "../../../tag";
import { CafeMenuOverlay } from "./overlay";
import useAnalytics from "../../../../../../hooks/use-analytics";

export const position = new Vector3(
  -46.81009630549522,
  1.6599023971978064,
  -53.0236004315906
);

const sweep = "b335b379c0ef4194959347602a5214d7";

export interface CafeMenuTagProps {
  isVisible?: boolean;
}

export const CafeMenuTag = ({ isVisible = false }: CafeMenuTagProps) => {
  const { sendEvent } = useAnalytics();
  const [isOverlayOpen, setIsOverlayOpen] = useState<boolean>(false);

  const handleOverlayClose = useCallback(() => {
    setIsOverlayOpen(false);
  }, []);

  const handleOverlayOpen = useCallback(() => {
    sendEvent("Experience", `Cafe Menu - click`, null, {});//GTM-Green tag, CAFÉ MENU
    setIsOverlayOpen(true);
  }, []);

  return (
    <>
      <CustomTag
        src="/imgs/extra-tag.png"
        isVisible={isVisible}
        name="cafe-menu"
        location={position}
        onOpen={handleOverlayOpen}
        sweepIdToGo={sweep}
        size={{ width: 0.2, height: 0.2 }}
      />

      <CafeMenuOverlay shown={isOverlayOpen} onClose={handleOverlayClose} />
    </>
  );
};
