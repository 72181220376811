import styled from "styled-components";

export const Bar = styled.div`
  @keyframes loading {
    0% {
      left: -100vw;
    }

    100% {
      left: 0;
    }
  }

  animation: loading 4s linear 1s;
  background-color: ${({ theme: { brand } }) => brand.white};
  height: 100vh;
  right: 0;
  left: -100vw;
  position: absolute;
  width: 100vw;
`;

export const BarContainer = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.brand.black};
  width: 100vw;
  height: 100vh;
  flex-shrink: 0;
  z-index: 12;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.md}) {
    max-height: unset;
  }
`;
