import React, { useCallback, useState } from "react";
import styled from "styled-components";

import { Overlay, OverlayContent, Title, CloseButton } from ".";
import type { OverlayProps } from ".";
import { MenuButtonContainer } from "../menu-container";

type InheritedProps = OverlayProps & React.HtmlHTMLAttributes<HTMLDivElement>;

export interface BaseOverlayProps extends InheritedProps {
  children?: React.ReactNode;
  onClose?: () => void;
  title?: string;
}

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
`;

export const BaseOverlay = ({
  children,
  onClose,
  title,
  shown,
  ...props
}: BaseOverlayProps) => {
  const [isClosing, setClosing] = useState(false);

  const handleAnimationEnd = useCallback(() => {
    if (isClosing) {
      onClose?.();
      setClosing(false);
    }
  }, [isClosing, onClose]);

  const handleCloseClick = useCallback(() => {
    setClosing(true);
  }, []);

  return shown || isClosing ? (
    <Overlay
      shown={shown && !isClosing}
      onAnimationEnd={handleAnimationEnd}
      {...props}
    >
      {onClose && (
        <MenuButtonContainer side="right">
          <CloseButton onClick={handleCloseClick} />
        </MenuButtonContainer>
      )}
      <OverlayContent>
        <Content>
          {title && <Title>{title}</Title>}
          {/* only mount children elements if shown */}
          {children}
        </Content>
      </OverlayContent>
    </Overlay>
  ) : null;
};
