import React from "react";
import styled from "styled-components";

import { Separator } from "src/components";

const contentBreakpoint = "864px";

const ArticleContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  margin: auto;
  flex: 0;
  flex-direction: column;

  @media screen and (orientation: landscape) {
    flex-direction: row;
  }

  @media screen and (min-width: ${contentBreakpoint}) {
    flex-direction: row;
    height: 410px;
  }
`;

const ArticleContent = styled.div`
  flex: 1 1;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  max-width: 410px;
  max-height: 35vh;
  padding: 0 1em;

  @media screen and (orientation: landscape) {
    flex: 0 0 50%;
    max-height: 70vh;
  }

  @media screen and (min-width: ${contentBreakpoint}) {
    padding: 0;
    margin-left: 2.4em;
    height: 410px;
    max-height: 410px;
    flex: 0 0 410px;
  }
`;

export const HorizontalSeparator = styled(Separator)`
  max-width: 2em;
`;

const Img = styled.img`
  border-radius: 3px;
  border: 1.75417px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 10.525px 10.525px rgba(0, 0, 0, 0.17);
  max-width: 410px;
  margin-bottom: 1em;
  max-height: 35vh;

  @media screen and (orientation: landscape) {
    max-height: 70vh;
  }

  @media screen and (min-width: ${contentBreakpoint}) {
    max-height: none;
    height: 410px;
    width: 410px;
    margin-bottom: 0;
  }
`;

const ContentSection = styled.div`
  line-height: 150%;
  opacity: 0.7;
  font-size: 0.78em;
  overflow-y: scroll;
  flex: 1 1;
  padding-bottom: 2.5em;

  p {
    margin: 0 0 1em;
  }

  scrollbar-width: thin;
  scrollbar-color: rgb(116, 116, 116) transparent;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgb(116, 116, 116);
    border-radius: 32px;
  }
`;

const TitleSection = styled.section``;

export const Title = styled.h4`
  font-size: 1.115em;
  font-family: ${(props) => props.theme.fonts.emphasis};
  margin-bottom: 0.5em;
  line-height: 1.5em;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  font-style: normal;
  font-weight: normal;

  small {
    font-family: ${(props) => props.theme.fonts.main};
    font-style: normal;
    font-weight: 600;
    font-size: 0.7em;
    letter-spacing: 2.5px;
    color: ${(props) => props.theme.brand.white};
    opacity: 0.7;
    margin-bottom: 0;
  }
`;

const Author = styled.span`
  font-family: ${(props) => props.theme.fonts.main};
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  line-height: 170%;
  margin: 0.5em 0;
  font-size: 0.89em;
  margin-bottom: 1.5em;
`;

const ContentFade = styled.div`
  position: absolute;
  width: 100%;
  height: 3em;
  left: 0;
  bottom: 0;

  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.85) 0%,
    rgba(0, 0, 0, 0) 100%
  );
`;

export interface OverlayArticleProps {
  author?: string;
  title?: string;
  subtitle?: string;
  image: string;
  children?: React.ReactNode;
}

export const OverlayArticle = ({
  author,
  title,
  subtitle,
  children,
  image,
}: OverlayArticleProps) => {
  const hasTitle = Boolean(title || subtitle);
  const hasHeading = Boolean(title || subtitle || author);

  return (
    <ArticleContainer>
      <Img src={image} />

      <ArticleContent>
        {hasHeading && (
          <TitleSection>
            {hasTitle && (
              <Title>
                {title}
                {subtitle && <small>{subtitle}</small>}
              </Title>
            )}

            <HorizontalSeparator side="left" />

            {author && <Author>{author}</Author>}
          </TitleSection>
        )}

        <ContentSection>{children}</ContentSection>
        <ContentFade />
      </ArticleContent>
    </ArticleContainer>
  );
};
