import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { ai } from "src/telemetryService";
import { VimeoVideo } from "src/components";
import { VimeoComponentProps } from "src/components/vimeo";

import { UIButton } from "../../button";
import useAnalytics from "../../../../../../hooks/use-analytics";

const VideoContainer = styled.div`
  position: relative;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
  flex: 1 1;
  width: 90vw;

  @media screen and (orientation: landscape) {
    width: 60vw;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 60vw;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.md}) {
    width: 70vw;
  }

  @media screen and (min-aspect-ratio: 21/9) {
    width: 60vw;
  }
`;

const Video = styled(VimeoVideo)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: fill;
`;

const SkipButton = styled(UIButton)`
  top: 18px;
  right: 18px;
  position: absolute;
`;

export interface OverlayVideoProps extends VimeoComponentProps {
  // Either to show or not the skip button
  skippable?: boolean;
  // On video ended or skipped
  onFinish?: () => void;
  skipEventName?: string;
}

const EmbedResponsive = styled.div`
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  border-radius: 6px;
  background-color: ${(props) => props.theme.brand.black};
  overflow: hidden;

  &::before {
    display: block;
    content: "";
    padding-top: 56.25%;
  }
`;

const VideoControls = styled.div`
  position: relative;
  width: 100%;
`;

export const OverlayVideo = ({
  skippable = true,
  onFinish,
  skipEventName,
  ...props
}: OverlayVideoProps) => {
  const { t } = useTranslation();
  const { sendEvent } = useAnalytics();

  return (
    <VideoContainer>
      <VideoControls>
        <EmbedResponsive>
          <Video {...props} autoplay autopause={false} onEnded={onFinish} />
        </EmbedResponsive>

        {skippable && (
          <SkipButton
            onClick={() => {
              if (skipEventName) {
                ai.trackEvent({ name: skipEventName });
                sendEvent(
                  "Experience",
                  `Skip ${skipEventName} Button - click`,
                  null,
                  {}
                );
              }
              onFinish && onFinish();
            }}
          >
            {t("tour.skip", "Skip")}
          </SkipButton>
        )}
      </VideoControls>
    </VideoContainer>
  );
};
