import styled from "styled-components";

interface Props {
  primary?: boolean;
  side?: "left" | "right";
}

export const Separator = styled.hr<Props>`
  background-color: ${(props) =>
    props.primary ? props.theme.primary : props.theme.secondary};
  height: 3px;
  width: 115px;
  border: 0;
  ${(props) =>
    typeof props.side === "string" ? `margin-${props.side}: 0` : ""}
`;
