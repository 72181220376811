import React from "react";
import styled, { css } from "styled-components";

import MarkerImg from "./imgs/marker-shadow.svg";

const MarkerContainer = styled.div<MarkerProps>`
  width: 35px;
  height: 35px;
  position: absolute;
  transition: all 0.33s ease;
  background-image: url(${MarkerImg});
  background-size: cover;
  background-repeat: no-repeat;

  ${({ isUnlocked }) =>
    isUnlocked &&
    css`
      .circle {
        color: #ffffff;
        background-color: #00754a;
      }
    `}

  ${({ isUnlocked }) =>
    !isUnlocked &&
    css`
      .circle {
        color: #333333;
        background-color: #cccccc;
      }
    `}

  .circle {
    top: 5px;
    left: 7px;
    width: 20px;
    height: 20px;
    display: flex;
    cursor: pointer;
    font-weight: 600;
    line-height: 14px;
    position: absolute;
    font-style: normal;
    align-items: center;
    border-radius: 100%;
    font-size: 8.16224px;
    justify-content: center;
    font-family: "SoDo Sans";
  }

  .hover-effect {
    transform: scale(1.05);
    background-color: #d2175a;
  }
`;

interface MarkerProps {
  number?: string;
  isUnlocked?: boolean;
  className?: string;
  isLinkActive?: boolean;
  onClick?: () => void;
  setHoverStateByNumber?: (id: number, flag: boolean) => void;
}

type MarkerType = React.FC<MarkerProps>;

export const Marker: MarkerType = ({
  number,
  className,
  onClick,
  isUnlocked,
  isLinkActive,
  setHoverStateByNumber,
}) => {
  return (
    <MarkerContainer
      onClick={onClick}
      className={className}
      isUnlocked={isUnlocked}
      onMouseOver={() => setHoverStateByNumber?.(Number(number), true)}
      onMouseOut={() => setHoverStateByNumber?.(Number(number), false)}
    >
      <div
        className={`${
          isLinkActive && isUnlocked ? "circle hover-effect" : "circle"
        }`}
      >
        {number}
      </div>
    </MarkerContainer>
  );
};
