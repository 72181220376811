import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { breakpoint } from "src/style";
import { BaseOverlay } from "../../../ui/overlay";
import { BaseOverlayProps } from "../../../ui/overlay/base";
import { NavButtons, Page, usePages } from "../../../nav-paginator";

import {
  AmericanoConCremaRecipe,
  ShakeratoRecipe,
  SparklingRecipe,
} from "./recipes";

export type CoffeeRecipesOverlayProps = Pick<
  BaseOverlayProps,
  "shown" | "onClose"
>;

const recipes = [
  <AmericanoConCremaRecipe />,
  <ShakeratoRecipe />,
  <SparklingRecipe />,
];

const Container = styled.div`
  margin: auto;
`;

const NavButtonsCustom = styled(NavButtons)`
  @media screen and (min-width: ${breakpoint("xs")}) {
    margin-top: -55px;
    transform: scale(0.6);
  }

  @media screen and (min-width: ${breakpoint("sm")}) {
    margin-top: -55px;
    transform: scale(0.7);
  }

  @media screen and (min-width: ${breakpoint("md")}) {
    margin-top: 0;
    transform: scale(1);
  }
`;

export const CoffeeRecipesOverlay = (props: CoffeeRecipesOverlayProps) => {
  const { t } = useTranslation();
  const pagination = usePages({ totalPages: recipes.length });

  return (
    <BaseOverlay
      color="dark"
      title={t("tour.coffee-recipes.title", "Coffee Recipes")}
      {...props}
    >
      <Container>
        {recipes.map((recipe, i) => {
          return (
            <Page key={i} active={pagination.currentPage === i}>
              {recipe}
            </Page>
          );
        })}

        <NavButtonsCustom
          isFirst={pagination.atFirstPage}
          isLast={pagination.atLastPage}
          onPrev={pagination.prevPage}
          onNext={pagination.nextPage}
        />
      </Container>
    </BaseOverlay>
  );
};
