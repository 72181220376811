import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import {
  Overlay,
  OverlayContent,
  CloseButton,
  Title,
} from "../../overlay/index";
import type { OverlayProps } from "../../overlay";
import { MenuButtonContainer } from "../../menu-container";

import { Settings } from "./settings";
import { Footer } from "./footer";

export interface MenuOverlayProps extends OverlayProps {
  onClose: () => void;
  onHelp: () => void;
}

const CustomOverlayContent = styled(OverlayContent)`
  flex: 0 1;
  margin: auto;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const MenuOverlay = ({
  onClose,
  onHelp,
  ...props
}: MenuOverlayProps) => {
  const { t } = useTranslation();

  return (
    <Overlay {...props}>
      <Title>{t("tour.menu.title", "Menu")}</Title>

      <MenuButtonContainer side="right">
        <CloseButton onClick={onClose} />
      </MenuButtonContainer>

      <CustomOverlayContent>
        <Content>
          <Settings onHelp={onHelp} />
          <Footer />
        </Content>
      </CustomOverlayContent>
    </Overlay>
  );
};
