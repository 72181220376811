import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Page } from "./page";
import { ArtworkBox } from "./artwork-box";

import PageRight from "../imgs/artwork/02.jpg";
import Art01 from "../imgs/artwork/arts/01.png";
import Art02 from "../imgs/artwork/arts/02.png";
import Art03 from "../imgs/artwork/arts/03.png";
import Art04 from "../imgs/artwork/arts/04.png";
import Art05 from "../imgs/artwork/arts/05.png";

const ArtworkContainer = styled.div<ArtworkProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transpartent;

  .content {
    display: flex;
    position: relative;
    flex-direction: column;
    width: calc(100% - 50px);
    height: calc(630px - 85px);
    padding: 50px 25px 35px 25px;

    .art-box {
      position: absolute;
      transition: transform 0.33s ease;

      &-1 {
        left: 34px;
        top: 213.89px;
        transform: rotate(-2.85deg);

        &:hover {
          /* transform: rotate(-5.85deg) scale(1.1); */
        }

        img {
          transform: rotate(0.15deg);
        }
      }

      &-2 {
        right: 33px;
        bottom: 44.33px;
        transform: rotate(4deg);

        &:hover {
          /* transform: rotate(7deg) scale(1.1); */
        }

        img {
          transform: rotate(0);
        }
      }

      &-3 {
        top: 35px;
        left: 41px;
        transform: rotate(3deg);

        &:hover {
          /* transform: rotate(6deg) scale(1.1); */
        }

        img {
          transform: rotate(0);
        }
      }

      &-4 {
        top: 217px;
        left: 160px;
        transform: rotate(-2deg);

        &:hover {
          /* transform: rotate(-5deg) scale(1.1); */
        }

        img {
          transform: rotate(-0.7deg);
        }
      }

      &-5 {
        left: 34px;
        top: 387px;
        z-index: -1;
        transform: rotate(1deg);

        &:hover {
          /* transform: rotate(4deg) scale(1.1); */
        }

        img {
          transform: rotate(0.5deg);
        }
      }
    }
  }

  .content-left {
    background-image: ${({ unlocked, language }) =>
      unlocked
        ? `url("/field-journal/${language}/artwork/01.jpg")`
        : `url("/field-journal/${language}/locked.png"),
    url("/field-journal/${language}/artwork/01.jpg")`};
    background-size: contain;
  }

  .content-right {
    background-image: ${({ unlocked, language }) =>
      unlocked
        ? `url(${PageRight})`
        : `url("/field-journal/${language}/locked.png"),
    url(${PageRight})`};
    z-index: 0;
    background-size: contain;
  }
`;

interface ArtworkProps {
  language?: string;
  unlocked?: boolean;
}

type ArtworkType = React.FC<ArtworkProps>;

export const Artwork: ArtworkType = ({ unlocked }) => {
  const { t } = useTranslation();
  const language = t("language-name");

  return (
    <ArtworkContainer unlocked={unlocked} language={language}>
      <Page>
        <div className="content content-left">
          {unlocked ? (
            <ArtworkBox
              className="art-box art-box-1"
              title={t(
                "tour.artworks.history-of-coffee.title",
                "History of Coffee"
              )}
              artist="Sergio Guillen"
              link="https://www.google.com"
            >
              <img
                src={Art01}
                alt={t(
                  "tour.artworks.history-of-coffee.title",
                  "History of Coffee"
                )}
              />
            </ArtworkBox>
          ) : null}

          {unlocked ? (
            <ArtworkBox
              className="art-box art-box-2"
              title={`Introspección (${t(
                "tour.artworks.introspection.title",
                "Introspection"
              )})`}
              artist="Jade Riviera"
              link="https://www.google.com"
            >
              <img
                src={Art02}
                alt={`Introspección (${t(
                  "tour.artworks.introspection.title",
                  "Introspection"
                )})`}
              />
            </ArtworkBox>
          ) : null}
        </div>
      </Page>
      <Page>
        <div className="content content-right">
          {unlocked ? (
            <ArtworkBox
              className="art-box art-box-3"
              title={t("tour.artworks.tommy-taylor.title", "Mural")}
              artist="Tommy Taylor"
              link="https://www.google.com"
            >
              <img src={Art03} alt="Mural" />
            </ArtworkBox>
          ) : null}

          {unlocked ? (
            <ArtworkBox
              className="art-box art-box-4"
              title={`El Recolector (${t(
                "tour.artworks.the-collector.title",
                "El Recolector"
              )})`}
              artist="Jade Riviera"
              link="https://www.google.com"
            >
              <img src={Art04} alt="El Recolector (The Collector)" />
            </ArtworkBox>
          ) : null}

          {unlocked ? (
            <ArtworkBox
              className="art-box art-box-5"
              title={t("tour.artworks.mata-ruda.title", "Mural")}
              artist="Mata Ruda"
              link="https://www.google.com"
            >
              <img src={Art05} alt="Mural" />
            </ArtworkBox>
          ) : null}
        </div>
      </Page>
    </ArtworkContainer>
  );
};
