import React from "react";
import { Vector3 } from "three";

import { ChapterTag, ChapterTagPublicProps } from "../../interactive-tags";

export const position = new Vector3(
  -29.28294320681392,
  1.2733489106094973,
  -37.35485560043449
);

export const sweep = "7fb41b649fc84e629098d54f3d1ca2a5";

export const DryingTag = (props: ChapterTagPublicProps) => {
  return (
    <ChapterTag
      name="drying"
      position={position}
      joshVideo="502311643"
      meliVideo="502309535"
      sweep={sweep}
      {...props}
    />
  );
};
