import { useCallback } from "react";
import { useMatterportSDK } from "src/hooks";
import { TRANSITION } from "src/types/sdk";

export const useSweepWalk = (sweep?: string) => {
  const sdk = useMatterportSDK();
  const callback = useCallback(
    (walkSweep?: string) => {
      const target = walkSweep || sweep;

      if (target)
        return sdk?.Sweep.moveTo(target, { transition: TRANSITION.FLY });
    },
    [sdk, sweep]
  );

  return callback;
};
