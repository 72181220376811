import styled, { css, keyframes, Keyframes } from "styled-components";

import { breakpoint } from "src/style";

export const FadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const FadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

interface BaseStyleProps {
  keep?: boolean;
}

interface ShownStyleProps extends BaseStyleProps {
  appearingAnimation?: Keyframes;
}

const ShownStyle = css<ShownStyleProps>`
  display: block;

  > * {
    animation: ${(props) => props.appearingAnimation || FadeIn} 0.5s ease-in-out;
  }

  @media screen and (min-width: ${breakpoint("xs")}) {
    margin-top: -30px;
    transform: scale(0.65);
  }

  @media screen and (min-width: ${breakpoint("sm")}) {
    margin-top: -30px;
    transform: scale(0.75);
  }

  @media screen and (min-width: ${breakpoint("md")}) {
    margin-top: 0;
    margin-bottom: 0;
    transform: scale(1);
  }
`;

interface HiddenStyleProps extends BaseStyleProps {
  disappearingAnimation?: Keyframes;
}

const HiddenStyle = css<HiddenStyleProps>`
  > * {
    animation: ${(props) => props.disappearingAnimation || FadeOut} 0.5s
      ease-in-out;
  }

  ${({ keep }) => !keep && "display: none;"}
`;

type InheritedProps = ShownStyleProps & HiddenStyleProps;

export interface PageProps extends InheritedProps {
  active?: boolean;
}

export const Page = styled.div<PageProps>`
  ${({ active }) => (active ? ShownStyle : HiddenStyle)}
`;
