import { DefaultTheme, ThemeProps } from "styled-components";

import i18n from "../i18n";

export const theme: DefaultTheme = {
  variant: "light",
  fonts: {
    main:
      "'SoDo Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    emphasis: "'Pike', Menlo, Monaco, Consolas, 'Courier New', monospace",
    mono: "source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace",
  },
  breakpoints: {
    xxs: "320px",
    xs: "425px",
    sm: "768px",
    md: "1024px",
    lg: "1440px",
    xlg: "1920px",
    xxlg: "2250px",
  },
  brand: {
    white: "#FFF",
    black: "#000",
    neutral: "#F2F0EB",
    calmNeutral: "#F9F9F9",
    accentGreen: "#00754A",
    houseGreen: "#1E3932",
    starbucksGreen: "#006241",
    lightGreen: "#D4E9E2",
  },
  denotive: {
    attention: "#F9DC5C",
    error: "#D2175A",
    inactive: "#F0F0F0",
    info: "#B7E4F3",
    success: "#00AC91",
  },
  expressive: {
    coffeeRed: "#D2175A",
    yellow: "#F9DC5C",
    tealish: "#2CBBB2",
  },
  get primary() {
    return this.brand.accentGreen;
  },
  get secondary() {
    return this.expressive.coffeeRed;
  },
  get lng() {
    return i18n.language;
  },
};

export const breakpoint = (size: keyof DefaultTheme["breakpoints"]) => (
  props: ThemeProps<DefaultTheme>
) => props.theme.breakpoints[size];
