import { createContext, useCallback, useEffect, useRef, useState } from "react";

import { pop, tagClick, click, ambient } from "./media";

export interface SfxProviderValue {
  pop: () => void;
  click: () => void;
  tagClick: () => void;
  startAmbient: () => void;
  pauseAmbient: () => void;
  isAmbientPaused: () => boolean;
  birds: () => void;
  mute: () => void;
  unmute: () => void;
  isMuted: () => boolean;
}

const noop = () => {};
const truthy = () => true;

const noopControls = {
  birds: noop,
  click: noop,
  isMuted: truthy,
  isAmbientPaused: truthy,
  mute: noop,
  pauseAmbient: noop,
  pop: noop,
  startAmbient: noop,
  tagClick: noop,
  unmute: noop,
};

export const SfxContext = createContext<SfxProviderValue>(noopControls);

interface SfxProviderProps {
  children: React.ReactNode;
}

interface SFXs {
  ambientSound: HTMLAudioElement;
  popSfx: HTMLAudioElement;
  clickSfx: HTMLAudioElement;
  tagClickSfx: HTMLAudioElement;
}

export const SfxProvider = ({ children }: SfxProviderProps) => {
  const sfx = useRef<SFXs>();
  const [muted, setMute] = useState(true);
  const [ambientPaused, setAmbientPaused] = useState(true);

  useEffect(() => {
    const ambientSound = new Audio(
      process.env.REACT_APP_AUDIO_AMBIENT || ambient
    );
    const popSfx = new Audio(process.env.REACT_APP_AUDIO_POP || pop);
    const clickSfx = new Audio(process.env.REACT_APP_AUDIO_CLICK || click);
    const tagClickSfx = new Audio(
      process.env.REACT_APP_AUDIO_TAG_CLICK || tagClick
    );
    ambientSound.loop = true;

    ambientSound.volume = parseFloat(
      process.env.REACT_APP_AUDIO_AMBIENT_VOLUME || "1"
    );
    popSfx.volume = parseFloat(
      process.env.REACT_APP_AUDIO_POP_VOLUME ||
        process.env.REACT_APP_AUDIO_SFX_VOLUME ||
        "1"
    );
    clickSfx.volume = parseFloat(
      process.env.REACT_APP_AUDIO_CLICK_VOLUME ||
        process.env.REACT_APP_AUDIO_SFX_VOLUME ||
        "1"
    );
    tagClickSfx.volume = parseFloat(
      process.env.REACT_APP_AUDIO_TAG_CLICK_VOLUME ||
        process.env.REACT_APP_AUDIO_SFX_VOLUME ||
        "1"
    );

    sfx.current = {
      ambientSound,
      popSfx,
      clickSfx,
      tagClickSfx,
    };
  }, []);

  useEffect(() => {
    if (!sfx.current) return;

    sfx.current.ambientSound.muted = muted;
    sfx.current.popSfx.muted = muted;
    sfx.current.clickSfx.muted = muted;
    sfx.current.tagClickSfx.muted = muted;
  }, [muted]);

  const popSfx = useCallback(() => {
    if (!sfx.current) return;
    sfx.current.popSfx.currentTime = 0;
    sfx.current.popSfx.play().catch(noop);
  }, []);

  const clickSfx = useCallback(() => {
    if (!sfx.current) return;
    sfx.current.clickSfx.currentTime = 0;
    sfx.current.clickSfx.play().catch(noop);
  }, []);

  const tagClickSfx = useCallback(() => {
    if (!sfx.current) return;
    sfx.current.tagClickSfx.currentTime = 0;
    sfx.current.tagClickSfx.play().catch(noop);
  }, []);

  const startAmbientSfx = useCallback(() => {
    setAmbientPaused(false);
    sfx.current?.ambientSound.play().catch(noop);
  }, []);
  const pauseAmbientSfx = useCallback(() => {
    setAmbientPaused(true);
    sfx.current?.ambientSound.pause();
  }, []);
  const isAmbientPaused = useCallback(() => ambientPaused, [ambientPaused]);

  const mute = useCallback(() => setMute(true), []);
  const unmute = useCallback(() => setMute(false), []);
  const isMuted = useCallback(() => muted, [muted]);

  const controls = {
    pop: popSfx,
    click: clickSfx,
    tagClick: tagClickSfx,

    birds: startAmbientSfx,
    startAmbient: startAmbientSfx,
    pauseAmbient: pauseAmbientSfx,
    isAmbientPaused,

    mute,
    unmute,
    isMuted,
  };

  return <SfxContext.Provider value={controls}>{children}</SfxContext.Provider>;
};
