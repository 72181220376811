import React, { useCallback } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { saveAs } from "file-saver";

import { Icon } from "src/components";
import { breakpoint } from "src/style";

import { UIButton } from "../../../ui";
import { BaseOverlay } from "../../../ui/overlay";
import { Card } from "./card";
import { BaseOverlayProps } from "../../../ui/overlay/base";

import * as thumbnails from "./thumbnails";
import * as backgrounds from "./backgrounds";
import { StyledButton } from "../../../ui/button";

import { ai } from "src/telemetryService";
import useAnalytics from "../../../../../../hooks/use-analytics";

const DownloadsContent = styled.div`
  margin: auto;
  overflow-y: auto;
  overflow-x: hidden;

  @media screen and (min-width: ${breakpoint("xs")}) {
    height: 70vh;
  }

  @media screen and (min-width: ${breakpoint("md")}) {
    height: auto;
    max-width: 80vw;
  }
`;

const DownloadsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  @media screen and (min-width: ${breakpoint("xs")}) {
    padding: 15px;

    ${StyledButton} {
      margin: 10px 0;
    }
  }
`;

export type DownloadsOverlayProps = Pick<BaseOverlayProps, "shown" | "onClose">;

export const DownloadsOverlay = (props: DownloadsOverlayProps) => {
  const { t } = useTranslation();
  const { sendEvent } = useAnalytics();
  const handleDownloadAll = useCallback(() => {
    ai.trackEvent({ name: "Download wallpaper-all" });
    sendEvent("Experience", `Download all wallpapers - click`, null, {});//GTM-download all wallpaper
    saveAs(backgrounds.all, "Hacienda Alsacia Backgrounds.zip");
  }, []);

  return (
    <BaseOverlay title={t("tour.downloads.title", "Downloads")} {...props}>
      <DownloadsContent>
        <DownloadsGrid>
          <Card
            title={t("tour.downloads.farm-view.title", "Farm View")}
            image={thumbnails.farmView}
            downloadUrl={backgrounds.farmView}
            description={t(
              "tour.downloads.farm-view.description",
              "Wallpaper 1920x1080"
            )}
          />
          <Card
            title={t("tour.downloads.living-logo.title", "Living Logo")}
            image={thumbnails.livingLogo}
            downloadUrl={backgrounds.livingLogo}
            description={t(
              "tour.downloads.living-logo.description",
              "Wallpaper 1920x1080"
            )}
          />
          <Card
            title={t("tour.downloads.world-of-coffee.title", "World of Coffee")}
            image={thumbnails.worldOfCoffee}
            downloadUrl={backgrounds.worldOfCoffee}
            description={t(
              "tour.downloads.world-of-coffee.description",
              "Wallpaper 1920x1080"
            )}
          />

          <Card
            title={t("tour.downloads.cafe-view.title", "Café View")}
            image={thumbnails.cafeView}
            downloadUrl={backgrounds.cafeView}
            description={t(
              "tour.downloads.cafe-view.description",
              "Wallpaper 1920x1080"
            )}
          />
          <Card
            title={t("tour.downloads.entrance.title", "Entrance")}
            description={t(
              "tour.downloads.entrance.description",
              "Wallpaper 1920x1080"
            )}
            image={thumbnails.entrance}
            downloadUrl={backgrounds.entrance}
          />
          <Card
            title={t("tour.downloads.waterfall.title", "Waterfall")}
            description={t(
              "tour.downloads.waterfall.description",
              "Wallpaper 1920x1080"
            )}
            image={thumbnails.waterfall}
            downloadUrl={backgrounds.waterfall}
          />
        </DownloadsGrid>

        <UIButton onClick={handleDownloadAll}>
          <Icon name="download" />
          {t("tour.downloads.action-all", "Download All")}
        </UIButton>
      </DownloadsContent>
    </BaseOverlay>
  );
};
