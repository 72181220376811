import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import haciendaLogo from "./hacienda-logo.svg";
import matterportLogo from "./matterport-logo.svg";

import { Section, DividedSection, SubSection } from "./internal";
import { breakpoint } from "src/style";

const LinksSection = styled(Section)`
  @media screen and (orientation: landscape) and (max-width: ${breakpoint(
      "md"
    )}) {
    order: 0;
  }

  @media screen and (min-width: ${breakpoint("md")}) {
    flex: 1 0 100%;
  }
`;

const CopyAndLinksSection = styled(Section)`
  padding-right: 0.5em;
  margin: 0;

  @media screen and (orientation: landscape) and (max-width: ${breakpoint(
      "md"
    )}) {
    order: 1;
    flex-direction: column;
    text-align: right;
    font-size: 0.6em;
  }

  @media screen and (min-width: ${breakpoint("md")}) {
    padding-right: 0em;
    flex: 1 0 100%;
  }
`;

const AttribuitionSection = styled(DividedSection)`
  margin: 0;
  flex: 1 1 50%;
  align-items: center;

  img {
    max-height: 40px;
  }

  @media screen and (min-width: ${breakpoint("md")}) {
    flex: 1 0 100%;
    justify-content: center;
  }
`;

const CopylineSection = styled(Section)`
  margin: 0;

  @media screen and (orientation: landscape) and (max-width: ${breakpoint(
      "md"
    )}) {
    order: 1;
  }

  @media screen and (min-width: ${breakpoint("md")}) {
    flex: 1 0 100%;
  }
`;

const FooterSection = styled(SubSection)`
  flex: 0 1;
  width: 100%;
  margin: 0;

  @media screen and (min-width: ${breakpoint("sm")}) {
    margin: 2em 0 0;
  }
`;

const LinkList = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;

  > li {
    display: inline-block;
    margin-top: 0.6em;
    padding: 0 1em;

    &:last-of-type {
      padding-right: 0;
    }

    @media screen and (min-width: ${breakpoint("md")}) {
      border-left: 1px solid rgba(196, 196, 196, 0.3);

      &:first-of-type {
        border-left: 0;
      }
    }
  }

  a {
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.6em;
    letter-spacing: 0.11em;
    line-height: 120%;

    &:active,
    &:visited {
      color: white;
    }
  }
`;

const Separator = styled.hr`
  border-color: white;
  opacity: 0.4;
  flex: 1 0 100%;
  height: 0px;
  max-width: 800px;
`;

const VerticalSeparator = styled.div`
  border: 1px inset white;
  width: 0px;
  height: 2em;
  opacity: 0.4;

  @media screen and (min-width: ${breakpoint("md")}) {
    margin: 0.5em 2em 0;
  }
`;

const Copyline = styled.p`
  opacity: 0.5;
  line-height: 170%;
  font-size: 0.685em;
  margin: 2em 0;
`;

export const Footer = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  const farm = process.env.REACT_APP_STARBUCKS_FARM_LINK;
  const privacy = process.env.REACT_APP_STARBUCKS_PRIVACY_LINK;
  const terms = process.env.REACT_APP_STARBUCKS_TERMS_LINK;

  return (
    <FooterSection>
      <Separator />
      <CopyAndLinksSection>
        <LinksSection>
          <LinkList>
            <li>
              <a target="_blank" rel="noreferrer" href={privacy}>
                {t("privacyPolicy", "Privacy Policy")}
              </a>
            </li>
            <li>
              <a target="_blank" rel="noreferrer" href={terms}>
                {t("termsAndConditions", "Terms &amp; Conditions")}
              </a>
            </li>
            <li>
              <a target="_blank" rel="noreferrer" href={farm}>
                {t("hacienda", "Hacienda Alsacia")}
              </a>
            </li>
          </LinkList>
        </LinksSection>
        <CopylineSection>
          <Copyline>
            &copy; {currentYear} Starbucks Corporation.{" "}
            {t("rights", "All rights reserved.")}
          </Copyline>
        </CopylineSection>
      </CopyAndLinksSection>

      <AttribuitionSection>
        <img src={haciendaLogo} alt="Hacienda Alsacia logo" />
        <VerticalSeparator />
        <img src={matterportLogo} alt="Matterport Logo" />
      </AttribuitionSection>
    </FooterSection>
  );
};
