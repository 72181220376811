import React, { useCallback, useState } from "react";

import { ExitMenu } from "./button";
import { ExitPromptOverlay } from "./overlay";

export const Exit = () => {
  const [promptVisible, setPromptVisible] = useState(false);

  const togglePrompt = useCallback(() => {
    setPromptVisible((prev) => !prev);
  }, []);

  const closePrompt = useCallback(() => {
    setPromptVisible(false);
  }, []);

  return (
    <>
      <ExitMenu onClick={togglePrompt} />
      <ExitPromptOverlay shown={promptVisible} onCancel={closePrompt} />
    </>
  );
};
