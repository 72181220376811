import React from "react";
import { useTranslation, Trans } from "react-i18next";
import styled, { css } from "styled-components";

import { ChapterLinksProps, ChapterLinksType } from ".";

const ChapterLinkContainer = styled.div<ChapterLinksProps>`
  opacity: 0.5;
  padding: 5px;
  display: flex;
  cursor: not-allowed;
  align-items: center;
  margin-bottom: 10px;
  flex-direction: column;
  width: calc(25% - 10px);
  height: calc(95px - 10px);
  border-radius: 3px !important;
  background-color: transparent;
  transition: 0.33s background-color ease-in-out;

  .row {
    width: 100%;
    height: 45px;
    display: flex;
    flex-direction: column;
  }

  .row-one {
    justify-content: flex-start;

    .number {
      margin: 0;
      color: #666666;
      font-size: 20px;
      line-height: 34px;
      text-align: center;
      font-style: normal;
      font-weight: normal;
      font-family: "Lander Grande";
    }

    .line {
      width: 16px;
      height: 2px;
      margin: 0 auto 5px auto;
      background-color: #666666;
      transition: 0.4s width ease-in-out;
    }
  }

  .row-two {
    justify-content: center;

    .card-title {
      margin: 0;
      font-size: 13px;
      color: #666666;
      line-height: 18px;
      font-style: normal;
      text-align: center;
      font-weight: normal;
      font-family: "SoDo Sans";

      ${({ language }) =>
        (language === "french" || language === "italian") &&
        css`
          font-size: 12px;
          word-break: break-word;
        `}
    }
  }

  ${({ unlocked }) =>
    unlocked &&
    css`
      opacity: 1;
      cursor: pointer;

      .row-one {
        .number {
          color: #000000;
        }

        .line {
          background-color: #00754a;
        }
      }

      .row-two {
        .card-title {
          color: #000000;
        }
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.085);

        .row-one {
          .line {
            width: 32px;
            background-color: #d2175a;
          }
        }
      }
    `}
`;

export const ChapterLink: ChapterLinksType = ({
  number,
  onClick,
  unlocked,
}) => {
  const { t } = useTranslation();
  const language = t("language-name");

  return (
    <ChapterLinkContainer
      onClick={onClick}
      unlocked={unlocked}
      language={language}
    >
      <div className="row row-one">
        <p className="number">{number}</p>

        <div className="line"></div>
      </div>

      <div className="row row-two">
        <h3 className="card-title">
          <Trans i18nKey={`tour.field-journal.index.${number}`}>
            <br />
          </Trans>
        </h3>
      </div>
    </ChapterLinkContainer>
  );
};
