import { useEffect, useRef, useState } from "react";
import { Vector3 } from "three";

import { Node } from "src/types/sdk";

import { useMatterportSDK } from "src/hooks";
import { MpVideo, MpVideoInputs } from "./object";

export interface MatterportVideoProps {
  position: Vector3;
  muted?: boolean;
  paused?: boolean;
  inputs: MpVideoInputs;
}

export const MatterportComponentSpecs = {
  name: "mp.video",
  factory: () => {
    return new MpVideo();
  },
};

let id = 0;

export const MatterportVideo = ({
  muted,
  paused,
  position,
  inputs,
}: MatterportVideoProps) => {
  const sdk = useMatterportSDK();
  const [node, setNode] = useState<Node>();
  const video = useRef<MpVideo>();

  useEffect(() => {
    if (!sdk) return;

    const myComponentName = `${MatterportComponentSpecs.name}-${++id}`;

    sdk.Scene.register(myComponentName, MatterportComponentSpecs.factory);

    async function create() {
      const newNode = await sdk!.Scene.createNode();

      video.current = newNode.addComponent<MpVideo, MpVideoInputs>(
        myComponentName,
        inputs
      );
      newNode.start();

      setNode(newNode);

      return newNode;
    }

    const nodePromise = create();

    return () => {
      nodePromise.then((node) => node.stop());
    };
  }, [sdk, inputs]);

  useEffect(() => {
    node?.position.copy(position);
  }, [node, position]);

  useEffect(() => {
    video.current?.setMute(muted);
  }, [muted]);

  useEffect(() => {
    video.current?.setPaused(paused);
  }, [paused]);

  return null;
};
