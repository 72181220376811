import * as THREE from "three";

import { SceneObject, SDK } from "../../../../types/sdk";

const uniforms = {
  u_time: { type: "f", value: 1.0 } as THREE.IUniform,
  u_resolution: { type: "v2", value: new THREE.Vector2() } as THREE.IUniform,
};

setInterval(() => {
  uniforms.u_time.value += 0.1;
}, 100);

//VertexShader changes the position - calcuate the positon of a certex in the certex shader
//GragmentShader does not change the positon (A L G B)
const shaderMaterial = new THREE.ShaderMaterial({
  uniforms,
  vertexShader: `
        varying vec2 v_Position;

        void main() {
            gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
            v_Position = position.xy;
        }`,
  fragmentShader: `
        #ifdef GL_ES
        precision mediump float;
        #endif

        uniform vec2 u_resolution;
        uniform float u_time;

        varying vec2 v_Position;

        void main() {
            vec2 coord = v_Position;
            coord = coord + vec2(0.0001);
            vec3 color = vec3(1.0, 0.7, 0.7);

            gl_FragColor = vec4(color, 0.8);
            gl_FragColor.a -= abs(length(coord) + abs(sin(u_time * 0.9 / 0.7)));
            if (gl_FragColor.a < 0.2) {
                gl_FragColor.a = 0.0;
            }

            if (gl_FragColor.a >= 0.5) {
                gl_FragColor.a = 0.5;
            }
        }
    `,
});

export class Pulse extends SceneObject {
  constructor(private sdk: SDK, private size: { w: number; h: number }) {
    super();
    this.sdk.on("sweep.enter", () => this.faceTo());
    this.faceTo();
  }

  faceTo() {
    if (this.root != null) {
      this.sdk.Camera.getPose().then((c: any) => {
        const { x, y, z } = c.position;
        (this as any).root.lookAt(x, y, z);
      });
    }
  }

  onInit() {
    const THREE = this.context!.three;
    const root = new THREE.Object3D();
    this.outputs!.objectRoot = root;
    this.outputs!.collider = root;
    const circle = new THREE.CircleBufferGeometry(this.size.w * 2, 32);

    shaderMaterial.side = THREE.DoubleSide;
    shaderMaterial.transparent = true;
    shaderMaterial.depthTest = false;
    shaderMaterial.depthWrite = false;

    const mesh = new THREE.Mesh(circle, shaderMaterial);
    mesh.renderOrder = 999;
    mesh.onBeforeRender = function (renderer) {
      renderer.clearDepth();
    };

    root.add(mesh);
    root.renderOrder = 9999;

    this.root = root;
    this.faceTo();
  }

  setVisible(visible: boolean) {
    if (this.root) {
      this.root.visible = visible;
    }
  }

  // onTick() {
  //   if (this.root != null) {
  //     this.sdk.Camera.getPose().then((c: any) => {
  //       const { x, y, z } = c.position;
  //       (this as any).root.lookAt(x, y, z);
  //     });
  //   }
  // }
}
