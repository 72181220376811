import { keyframes } from "styled-components";

/**
 * Page Flips animations are the flips themselves.
 * Had to use one animation to each direction, instead of controlling
 * the animation play direction in css due to some animation replay
 * problems when the same page is animated twice
 */

export const PageFlipNext = keyframes`
  0% {
    transform: rotateY(0) translateX(0);
    transform-origin: left center;
  }

  25% {
    transform: rotateY(90deg) skewY(-10deg) translateX(0);
    transform-origin: left left center;
  }

  50% {
    transform: rotateY(180deg) translateX(0);
    transform-origin: left center;
  }

  100% {
    transform: rotateY(180deg) translateX(-100%);
    transform-origin: left center;
  }
`;

export const PageFlipPrev = keyframes`
  0% {
    transform: rotateY(180deg) translateX(-100%);
    transform-origin: left center;
  }

  25% {
    transform: rotateY(180deg) translateX(0);
    transform-origin: left center;
  }

  50% {
    transform: rotateY(90deg) skewY(-10deg) translateX(0);
    transform-origin: left left center;
  }

  100% {
    transform: rotateY(0) translateX(0);
    transform-origin: left center;
  }
`;

/**
 * Back Position control animations are related to ordering
 * the verse of the pages during animations
 */

export const BackPositionControlNext = keyframes`
  0% { z-index: -1; }
  35% { z-index: 1; }
  100% { z-index: 1; }
`;

export const BackPositionControlPrev = keyframes`
  0% { z-index: 1; }
  49% { z-index: 1; }
  50% { z-index: -1; }
  100% { z-index: -1; }
`;

/**
 * Position control animations are related to ordering the pages
 * during animations, usualy applied in the page container
 */

export const PositionControlNext = keyframes`
  0% { z-index: 7; }
  49% { z-index: 7; }
  50% { z-index: 5; }
  100% { z-index: 5; }
`;

export const PositionControlPrev = keyframes`
  0% { z-index: 5; }
  49% { z-index: 5; }
  50% { z-index: 6; }
  100% { z-index: 6; }
`;
