import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { NavButtons, usePages } from "../../../nav-paginator";
import { BaseOverlay } from "../../../ui/overlay";
import { BaseOverlayProps } from "../../../ui/overlay/base";

import { pages } from "./pages";
import * as anim from "./animations";

export type CafeMenuOverlayProps = Pick<BaseOverlayProps, "shown" | "onClose">;

const Container = styled.div`
  margin: auto;
`;

/**
 * Container must be inline-block (span) to mantain the aspect of
 * the img. Since we cannot have pseudo-elements in replaced tags,
 * such the img, this container was created to handle the back of
 * the page
 */
const CafeMenuPage = styled.span`
  display: inline-block;
  position: relative;
  line-height: 0;

  &::before {
    content: " ";
    background-color: white;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: -1;
  }

  img {
    max-height: 70vh;
    max-width: 100%;
  }
`;

interface PageProps {
  // Direction of flip animation
  flipDirection?: "prev" | "next";
  // This page has flipping animation
  flipping?: boolean;
  // This page is the current one
  active?: boolean;
  // This page will be static and not animated,
  // but still must have a special z-index
  isBg?: boolean;
}

const Page = styled.div<PageProps>`
  animation-name: ${(props) =>
    props.flipping &&
    (props.flipDirection === "next"
      ? anim.PositionControlNext
      : anim.PositionControlPrev)};
  animation-duration: 1s;
  z-index: ${(props) =>
    props.active || props.flipping ? 6 : props.isBg ? 5 : 4};

  ${CafeMenuPage} {
    animation-name: ${(props) =>
      props.flipping &&
      (props.flipDirection === "next" ? anim.PageFlipNext : anim.PageFlipPrev)};
    animation-timing-function: ease-in-out;
    animation-duration: 1s;

    &::before {
      animation-name: ${(props) =>
        props.flipping &&
        (props.flipDirection === "next"
          ? anim.BackPositionControlNext
          : anim.BackPositionControlPrev)};
      animation-timing-function: ease-in-out;
      animation-duration: 1s;
    }
  }
`;

export const PageKeeper = styled.div`
  position: relative;
  height: 70vh;
  width: 90vw;

  ${Page} {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
  }
`;

export const CafeMenuOverlay = (props: CafeMenuOverlayProps) => {
  const { t } = useTranslation();
  const pagination = usePages({ totalPages: pages.length });

  return (
    <BaseOverlay
      color="dark"
      title={t("tour.cafe-menu.title", "Café Menu")}
      {...props}
    >
      <Container>
        <PageKeeper>
          {pages.map((page, i) => (
            <Page
              key={i}
              flipDirection={pagination.direction}
              active={pagination.currentPage === i}
              isBg={
                pagination.direction === "next"
                  ? pagination.currentPage === i
                  : pagination.currentPage + 1 === i
              }
              flipping={
                pagination.direction === "next"
                  ? pagination.currentPage - 1 === i
                  : pagination.currentPage === i
              }
            >
              <CafeMenuPage>
                <img src={page} alt={`${i} page`} />
              </CafeMenuPage>
            </Page>
          ))}
        </PageKeeper>

        <NavButtons
          isFirst={pagination.atFirstPage}
          isLast={pagination.atLastPage}
          onPrev={pagination.prevPage}
          onNext={pagination.nextPage}
        />
      </Container>
    </BaseOverlay>
  );
};
