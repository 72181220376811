import React from "react";
import { Vector3 } from "three";

import { ChapterTag, ChapterTagPublicProps } from "../../interactive-tags";

export const position = new Vector3(
  -73.07973071469682,
  2.693821706070956,
  -50.47245040697615
);
export const sweep = "732f3a62555041cb9815d36f1419fd7d";

export const WorldOfCoffeeTag = (props: ChapterTagPublicProps) => {
  return (
    <ChapterTag
      name="world-of-coffee"
      position={position}
      joshVideo="503102653"
      meliVideo="503103417"
      sweep={sweep}
      {...props}
    />
  );
};
