import React, { useState, useCallback } from "react";
import { Vector3 } from "three";

import { CustomTag } from "../../../tag";
import { CoffeeRecipesOverlay } from "./overlay";
import useAnalytics from "../../../../../../hooks/use-analytics";
export const position = new Vector3(
  -41.43205321210494,
  1.563284744787842,
  -53.29161581209593
);

const sweep = "cba9b1dea3fe445b84162041f0cca39f";

export interface CoffeeRecipesTagProps {
  isVisible?: boolean;
}

export const CoffeeRecipesTag = ({
  isVisible = false,
}: CoffeeRecipesTagProps) => {
  const { sendEvent } = useAnalytics();
  const [isOverlayOpen, setIsOverlayOpen] = useState<boolean>(false);

  const handleOverlayClose = useCallback(() => {
    setIsOverlayOpen(false);
  }, []);

  const handleOverlayOpen = useCallback(() => {
    sendEvent("Experience", `Coffee Recipes Tag - click`, null, {});//GTM-Green tag, COFFEE RECIPES
    setIsOverlayOpen(true);
  }, []);

  return (
    <>
      <CustomTag
        src="/imgs/extra-tag.png"
        isVisible={isVisible}
        name="coffee-recipes"
        location={position}
        onOpen={handleOverlayOpen}
        size={{ width: 0.2, height: 0.2 }}
        sweepIdToGo={sweep}
      />

      <CoffeeRecipesOverlay
        shown={isOverlayOpen}
        onClose={handleOverlayClose}
      />
    </>
  );
};
