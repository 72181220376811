import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import React, { useState, useCallback } from "react";

import { Marker } from "./marker";
import Map from "./imgs/farm-map.jpg";
import { breakpoint } from "src/style";
import { chapters } from "../../../story";
import { GoToTagOptions, useGoToTag } from "src/hooks";
import { MenuButtonContainer } from "../../menu-container";
import { Title, BaseOverlay, CloseButton, OverlayContent } from "../../overlay";
import useAnalytics from "../../../../../../hooks/use-analytics";
import {
  TagMap,
  DryingChapter,
  EndingChapter,
  HarvestChapter,
  NurseryChapter,
  ResearchChapter,
  RoastingChapter,
  VarietalsChapter,
  ProcessingChapter,
  WatterfallChapter,
  WorldOfCoffeeChapter,
  CentralAmericaChapter,
} from "src/pages/tour/state";

const hoverEffect = css`
  opacity: 1;
  transform: scale(1.1);
  border-left: 2px solid #d2175a;
`;

const MapOverlayContainer = styled.div`
  top: 50%;
  left: 50%;
  width: 100%;
  position: absolute;
  transform: translate(-50%, -50%);

  .map-overlay {
    display: flex;
    align-items: center;

    @media screen and (min-width: ${breakpoint("xs")}) {
      transform: scale(0.5);

      .section-left {
        top: -15px;
        left: 30px;
        position: absolute;
      }

      .section-right {
        top: -15px;
        right: -65px;
        position: absolute;
        transform: scale(0.9);
        padding-left: 0 !important;
      }
    }

    @media screen and (min-width: ${breakpoint("sm")}) {
      transform: scale(0.65);

      .section-left {
        top: 0;
        left: 30px;
        position: absolute;
      }

      .section-right {
        top: 0;
        right: -45px;
        position: absolute;
        transform: scale(0.9);
        padding-left: 0 !important;
      }
    }

    @media screen and (min-width: ${breakpoint("md")}) {
      transform: scale(0.7);

      .section-left {
        left: 20px;
        position: absolute;
      }

      .section-right {
        right: -50px;
        position: absolute;
        transform: scale(0.9);
        padding-left: 0 !important;
      }
    }

    @media screen and (min-width: ${breakpoint("lg")}) {
      transform: scale(0.85);

      .section-left {
        left: 10px;
        position: absolute;
      }

      .section-right {
        right: -25px;
        position: absolute;
        transform: scale(0.9);
        padding-left: 0 !important;
      }
    }

    @media screen and (min-width: ${breakpoint("xlg")}) {
      transform: scale(1.1);

      .section-left {
        left: -200px;
        position: absolute;
      }

      .section-right {
        right: 185px;
        position: absolute;
        transform: scale(0.9);
        padding-left: 0 !important;
      }
    }

    .section {
      height: fit-content;

      &-left {
        width: 60%;
        display: flex;
        position: relative;
        align-items: center;
        justify-content: flex-end;

        img {
          width: 554px;
          display: block;
          user-select: none;
          border-radius: 2.17824px;
        }

        .marker {
          &-01 {
            right: 90px;
            bottom: 121px;
          }

          &-02 {
            right: 48px;
            bottom: 155px;
          }

          &-03 {
            right: 218px;
            bottom: 199px;
          }

          &-04 {
            right: 155px;
            bottom: 209px;
          }

          &-05 {
            right: 178px;
            bottom: 235px;
          }

          &-06 {
            right: 315px;
            bottom: 286px;
          }

          &-07 {
            right: 259px;
            bottom: 203px;
          }

          &-08 {
            right: 475px;
            bottom: 187px;
          }

          &-09 {
            right: 326px;
            bottom: 163px;
          }

          &-10 {
            right: 356px;
            bottom: 122px;
          }

          &-11 {
            right: 17px;
            bottom: 101px;
          }
        }
      }

      &-right {
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .content {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;

          &-title {
            font-size: 12px;
            color: #ffffff;
            text-align: left;
            line-height: 20px;
            user-select: none;
            font-style: normal;
            letter-spacing: 3px;
            font-family: "Pike";
            font-weight: normal;
            margin-bottom: 25px;
            text-transform: uppercase;
          }

          &-links {
            margin: 0;
            padding: 0;
            display: flex;
            text-align: left;
            flex-direction: column;
            justify-content: flex-start;

            li {
              width: 260px;
              opacity: 0.5;
              height: 20px;
              display: flex;
              list-style: none;
              user-select: none;
              font-style: normal;
              align-items: center;
              font-weight: normal;
              margin-bottom: 14px;
              transition: all 0.33s ease-in-out;

              .number {
                font-size: 18px;
                line-height: 25px;
                font-family: "Pike";
              }

              .link {
                font-size: 14px;
                line-height: 24px;
                font-family: "SoDo Sans";
              }

              &.locked {
                border-left: 0;
                cursor: initial;
                color: #666666;

                .number {
                  margin: 0 17px;
                }

                &:hover {
                  cursor: pointer;
                  color: #ffffff;
                }
              }

              &.unlocked {
                cursor: pointer;
                color: #ffffff;
                border-left: 2px solid #00754a;

                .number {
                  margin: 0 15px;
                }

                &.hover-effect {
                  ${hoverEffect}
                }

                &:hover {
                  ${hoverEffect}
                }
              }
            }
          }
        }
      }
    }
  }
`;

interface MapOverlayProps {
  shown: boolean;
  progress: TagMap;
  onClose: () => void;
}

type MapOverlayType = React.FC<MapOverlayProps>;

export const MapOverlay: MapOverlayType = ({ shown, progress, onClose }) => {
  const { t } = useTranslation();
  const { sendEvent } = useAnalytics();
  const goTo = useGoToTag();
  const handleClick = useCallback(
    (target: GoToTagOptions) => () => {
      sendEvent("Farm Map", `Go To ${target.name} Link - click`, null, {});//GTM-Interacting with the map to jump to a location
      onClose?.();
      goTo(target);
    },
    [goTo, onClose]
  );

  const [hoverState, setHoverState] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const setHoverStateByNumber = useCallback(
    (id: number, newFlag: boolean) => {
      const newHoverState = hoverState.map((flag, index) => {
        return id - 1 === index ? newFlag : flag;
      });

      setHoverState(newHoverState);
    },
    [hoverState]
  );

  return (
    <BaseOverlay shown={shown} color="dark">
      <Title>{t("tour.map.title", "Farm Map")}</Title>

      <MenuButtonContainer side="right">
        <CloseButton onClick={onClose} />
      </MenuButtonContainer>

      <OverlayContent>
        <MapOverlayContainer>
          <div className="map-overlay">
            <div className="section section-left">
              <img src={Map} alt="Map" />

              <Marker
                number="01"
                className="marker-01"
                isLinkActive={hoverState[0]}
                onClick={handleClick({
                  sweep: chapters.worldOfCoffeeSweep,
                  position: chapters.worldOfCoffeePosition,
                })}
                isUnlocked={progress[WorldOfCoffeeChapter]}
                setHoverStateByNumber={setHoverStateByNumber}
              />

              <Marker
                number="02"
                className="marker-02"
                isLinkActive={hoverState[1]}
                onClick={handleClick({
                  sweep: chapters.centralAmericaSweep,
                  position: chapters.centralAmericaPosition,
                })}
                isUnlocked={progress[CentralAmericaChapter]}
                setHoverStateByNumber={setHoverStateByNumber}
              />

              <Marker
                number="03"
                className="marker-03"
                isLinkActive={hoverState[2]}
                onClick={handleClick({
                  sweep: chapters.nurserySweep,
                  position: chapters.nurseryPosition,
                })}
                isUnlocked={progress[NurseryChapter]}
                setHoverStateByNumber={setHoverStateByNumber}
              />

              <Marker
                number="04"
                className="marker-04"
                isLinkActive={hoverState[3]}
                onClick={handleClick({
                  sweep: chapters.varietalsSweep,
                  position: chapters.varietalsPosition,
                })}
                isUnlocked={progress[VarietalsChapter]}
                setHoverStateByNumber={setHoverStateByNumber}
              />

              <Marker
                number="05"
                className="marker-05"
                isLinkActive={hoverState[4]}
                onClick={handleClick({
                  sweep: chapters.harvestSweep,
                  position: chapters.harvestPosition,
                })}
                isUnlocked={progress[HarvestChapter]}
                setHoverStateByNumber={setHoverStateByNumber}
              />

              <Marker
                number="06"
                className="marker-06"
                isLinkActive={hoverState[5]}
                onClick={handleClick({
                  sweep: chapters.processingSweep,
                  position: chapters.processingPosition,
                })}
                isUnlocked={progress[ProcessingChapter]}
                setHoverStateByNumber={setHoverStateByNumber}
              />

              <Marker
                number="07"
                className="marker-07"
                isLinkActive={hoverState[6]}
                onClick={handleClick({
                  sweep: chapters.dryingSweep,
                  position: chapters.dryingPosition,
                })}
                isUnlocked={progress[DryingChapter]}
                setHoverStateByNumber={setHoverStateByNumber}
              />

              <Marker
                number="08"
                className="marker-08"
                isLinkActive={hoverState[7]}
                onClick={handleClick({
                  sweep: chapters.researchSweep,
                  position: chapters.researchPosition,
                })}
                isUnlocked={progress[ResearchChapter]}
                setHoverStateByNumber={setHoverStateByNumber}
              />

              <Marker
                number="09"
                className="marker-09"
                isLinkActive={hoverState[8]}
                onClick={handleClick({
                  sweep: chapters.roastingSweep,
                  position: chapters.roastingPosition,
                })}
                isUnlocked={progress[RoastingChapter]}
                setHoverStateByNumber={setHoverStateByNumber}
              />

              <Marker
                number="10"
                className="marker-10"
                isLinkActive={hoverState[9]}
                onClick={handleClick({
                  sweep: chapters.waterfallSweep,
                  position: chapters.waterfallPosition,
                })}
                isUnlocked={progress[WatterfallChapter]}
                setHoverStateByNumber={setHoverStateByNumber}
              />

              <Marker
                number="11"
                className="marker-11"
                isLinkActive={hoverState[10]}
                onClick={handleClick({
                  sweep: chapters.endingSweep,
                  position: chapters.endingPosition,
                })}
                isUnlocked={progress[EndingChapter]}
                setHoverStateByNumber={setHoverStateByNumber}
              />
            </div>

            <div className="section section-right">
              <div className="content">
                <h3 className="content-title">
                  {t("tour.map.content.title", "Click to jump to a stop")}
                </h3>

                <ul className="content-links">
                  <li
                    className={
                      progress[WorldOfCoffeeChapter]
                        ? hoverState[0]
                          ? "unlocked hover-effect"
                          : "unlocked"
                        : "locked"
                    }
                    onClick={handleClick({
                      sweep: chapters.worldOfCoffeeSweep,
                      position: chapters.worldOfCoffeePosition,
                      name: t("tour.map.content.links.01"),//GTM-Click individual link on the map
                    })}
                    onMouseOver={() => setHoverStateByNumber?.(1, true)}
                    onMouseOut={() => setHoverStateByNumber?.(1, false)}
                  >
                    <span className="number">01</span>
                    <span className="link">
                      {t("tour.map.content.links.01")}
                    </span>
                  </li>

                  <li
                    className={
                      progress[CentralAmericaChapter]
                        ? hoverState[1]
                          ? "unlocked hover-effect"
                          : "unlocked"
                        : "locked"
                    }
                    onClick={handleClick({
                      sweep: chapters.centralAmericaSweep,
                      position: chapters.centralAmericaPosition,
                      name: t("tour.map.content.links.02"),
                    })}
                    onMouseOver={() => setHoverStateByNumber?.(2, true)}
                    onMouseOut={() => setHoverStateByNumber?.(2, false)}
                  >
                    <span className="number">02</span>
                    <span className="link">
                      {t("tour.map.content.links.02")}
                    </span>
                  </li>

                  <li
                    className={
                      progress[NurseryChapter]
                        ? hoverState[2]
                          ? "unlocked hover-effect"
                          : "unlocked"
                        : "locked"
                    }
                    onClick={handleClick({
                      sweep: chapters.nurserySweep,
                      position: chapters.nurseryPosition,
                      name: t("tour.map.content.links.03"),
                    })}
                    onMouseOver={() => setHoverStateByNumber?.(3, true)}
                    onMouseOut={() => setHoverStateByNumber?.(3, false)}
                  >
                    <span className="number">03</span>
                    <span className="link">
                      {t("tour.map.content.links.03")}
                    </span>
                  </li>

                  <li
                    className={
                      progress[VarietalsChapter]
                        ? hoverState[3]
                          ? "unlocked hover-effect"
                          : "unlocked"
                        : "locked"
                    }
                    onClick={handleClick({
                      sweep: chapters.varietalsSweep,
                      position: chapters.varietalsPosition,
                      name: t("tour.map.content.links.04"),
                    })}
                    onMouseOver={() => setHoverStateByNumber?.(4, true)}
                    onMouseOut={() => setHoverStateByNumber?.(4, false)}
                  >
                    <span className="number">04</span>
                    <span className="link">
                      {t("tour.map.content.links.04")}
                    </span>
                  </li>

                  <li
                    className={
                      progress[HarvestChapter]
                        ? hoverState[4]
                          ? "unlocked hover-effect"
                          : "unlocked"
                        : "locked"
                    }
                    onClick={handleClick({
                      sweep: chapters.harvestSweep,
                      position: chapters.harvestPosition,
                      name: t("tour.map.content.links.05"),
                    })}
                    onMouseOver={() => setHoverStateByNumber?.(5, true)}
                    onMouseOut={() => setHoverStateByNumber?.(5, false)}
                  >
                    <span className="number">05</span>
                    <span className="link">
                      {t("tour.map.content.links.05")}
                    </span>
                  </li>

                  <li
                    className={
                      progress[ProcessingChapter]
                        ? hoverState[5]
                          ? "unlocked hover-effect"
                          : "unlocked"
                        : "locked"
                    }
                    onClick={handleClick({
                      sweep: chapters.processingSweep,
                      position: chapters.processingPosition,
                      name: t("tour.map.content.links.06"),
                    })}
                    onMouseOver={() => setHoverStateByNumber?.(6, true)}
                    onMouseOut={() => setHoverStateByNumber?.(6, false)}
                  >
                    <span className="number">06</span>
                    <span className="link">
                      {t("tour.map.content.links.06")}
                    </span>
                  </li>

                  <li
                    className={
                      progress[DryingChapter]
                        ? hoverState[6]
                          ? "unlocked hover-effect"
                          : "unlocked"
                        : "locked"
                    }
                    onClick={handleClick({
                      sweep: chapters.dryingSweep,
                      position: chapters.dryingPosition,
                      name: t("tour.map.content.links.07"),
                    })}
                    onMouseOver={() => setHoverStateByNumber?.(7, true)}
                    onMouseOut={() => setHoverStateByNumber?.(7, false)}
                  >
                    <span className="number">07</span>
                    <span className="link">
                      {t("tour.map.content.links.07")}
                    </span>
                  </li>

                  <li
                    className={
                      progress[ResearchChapter]
                        ? hoverState[7]
                          ? "unlocked hover-effect"
                          : "unlocked"
                        : "locked"
                    }
                    onClick={handleClick({
                      sweep: chapters.researchSweep,
                      position: chapters.researchPosition,
                      name: t("tour.map.content.links.08")
                    })}
                    onMouseOver={() => setHoverStateByNumber?.(8, true)}
                    onMouseOut={() => setHoverStateByNumber?.(8, false)}
                  >
                    <span className="number">08</span>
                    <span className="link">
                      {t("tour.map.content.links.08")}
                    </span>
                  </li>

                  <li
                    className={
                      progress[RoastingChapter]
                        ? hoverState[8]
                          ? "unlocked hover-effect"
                          : "unlocked"
                        : "locked"
                    }
                    onClick={handleClick({
                      sweep: chapters.roastingSweep,
                      position: chapters.roastingPosition,
                      name: t("tour.map.content.links.09"),
                    })}
                    onMouseOver={() => setHoverStateByNumber?.(9, true)}
                    onMouseOut={() => setHoverStateByNumber?.(9, false)}
                  >
                    <span className="number">09</span>
                    <span className="link">
                      {t("tour.map.content.links.09")}
                    </span>
                  </li>

                  <li
                    className={
                      progress[WatterfallChapter]
                        ? hoverState[9]
                          ? "unlocked hover-effect"
                          : "unlocked"
                        : "locked"
                    }
                    onClick={handleClick({
                      sweep: chapters.waterfallSweep,
                      position: chapters.waterfallPosition,
                      name: t("tour.map.content.links.10"),
                    })}
                    onMouseOver={() => setHoverStateByNumber?.(10, true)}
                    onMouseOut={() => setHoverStateByNumber?.(10, false)}
                  >
                    <span className="number">10</span>
                    <span className="link">
                      {t("tour.map.content.links.10")}
                    </span>
                  </li>

                  <li
                    className={
                      progress[EndingChapter]
                        ? hoverState[10]
                          ? "unlocked hover-effect"
                          : "unlocked"
                        : "locked"
                    }
                    onClick={handleClick({
                      sweep: chapters.endingSweep,
                      position: chapters.endingPosition,
                      name: t("tour.map.content.links.11"),
                    })}
                    onMouseOver={() => setHoverStateByNumber?.(11, true)}
                    onMouseOut={() => setHoverStateByNumber?.(11, false)}
                  >
                    <span className="number">11</span>
                    <span className="link">
                      {t("tour.map.content.links.11")}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </MapOverlayContainer>
      </OverlayContent>
    </BaseOverlay>
  );
};
