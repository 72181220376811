import React, { AudioHTMLAttributes, forwardRef } from "react";
import styled from "styled-components";

const Audio = styled.audio`
  display: hidden;
`;

export const AudioComponent = forwardRef<
  HTMLAudioElement | null,
  AudioHTMLAttributes<HTMLAudioElement>
>(({ ...props }: AudioHTMLAttributes<HTMLAudioElement>, ref) => {
  return <Audio ref={ref} {...props} />;
});
