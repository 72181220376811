import React, { useCallback, useState } from "react";
import { Vector3 } from "three";

import { CustomTag } from "../../../tag";

import { MoreWaysToLearnOverlay } from "./overlay";

import useAnalytics from "../../../../../../hooks/use-analytics";

export const position = new Vector3(
  -27.809358681317192,
  1.8623220421578344,
  -54.45187025994196
);

const sweep = "6bfc04ef2ec646f891907a545b1a5e17";

export interface MoreWaysToLearnTagProps {
  isVisible: boolean;
}

export const MoreWaysToLearnTag = ({ isVisible }: MoreWaysToLearnTagProps) => {
  const { sendEvent } = useAnalytics();
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  const handleOverlayClose = useCallback(() => {
    setIsOverlayOpen(false);
  }, []);

  const handleOverlayOpen = useCallback(() => {   
    sendEvent("Experience", `More Ways to Learn Tag - click`, null, {});//GTM-Green tag, More Ways to Learn
    setIsOverlayOpen(true);
  }, []);

  return (
    <>
      <CustomTag
        src="/imgs/extra-tag.png"
        isVisible={isVisible}
        name="more-ways-to-learn"
        location={position}
        onOpen={handleOverlayOpen}
        sweepIdToGo={sweep}
      />

      <MoreWaysToLearnOverlay
        shown={isOverlayOpen}
        onClose={handleOverlayClose}
      />
    </>
  );
};
