import { useEffect, useRef, useState } from "react";
import { Vector3 } from "three";

import { Node } from "src/types/sdk";

import { useMatterportSDK } from "src/hooks";
import { browser } from "src/helpers";

import { MpAudio, MpAudioInputs } from "./object";

export interface MatterportAudioProps {
  position: Vector3;
  muted: boolean;
  paused: boolean;
  inputs: MpAudioInputs;
}

export const MatterportComponentSpecs = {
  name: "mp.audio",
  factory: () => {
    return new MpAudio();
  },
};

let id = 0;

export const MatterportAudio = ({
  muted,
  paused,
  position,
  inputs,
}: MatterportAudioProps) => {
  const sdk = useMatterportSDK();
  const [node, setNode] = useState<Node>();
  const audio = useRef<MpAudio>();

  useEffect(() => {
    if (!sdk || browser.isIOS) return;

    const myComponentName = `${MatterportComponentSpecs.name}-${++id}`;

    sdk.Scene.register(myComponentName, MatterportComponentSpecs.factory);

    async function create() {
      const newNode = await sdk!.Scene.createNode();

      audio.current = newNode.addComponent<MpAudio, MpAudioInputs>(
        myComponentName,
        inputs
      );
      newNode.start();

      setNode(newNode);

      return newNode;
    }

    const nodePromise = create();

    return () => {
      nodePromise.then((node) => node.stop());
    };
  }, [sdk, inputs]);

  useEffect(() => {
    node?.position.copy(position);
  }, [node, position]);

  useEffect(() => {
    audio.current?.setMuted(muted);
  }, [muted]);

  useEffect(() => {
    audio.current?.setPlaying(!paused);
  }, [paused]);

  return null;
};
