import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Icon } from "src/components";

import { IconButton } from "../../../../ui/button";
import { OverlayVideo, OverlayVideoProps, Title } from "../../../../ui/overlay";

const BackButton = styled(IconButton)`
  font-size: 2em;
`;

export interface ChosenProps
  extends Pick<OverlayVideoProps, "videoId" | "onFinish" | "play"> {
  nameSelected: string;
  videoId: string;
  onFinish: () => void;
  onBack: () => void;
}

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Chosen = ({
  nameSelected,
  videoId,
  play,
  onFinish,
  onBack,
}: ChosenProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Title>
        {t("tour.guide.selected", "{{name}} will be your guide", {
          replace: {
            name: nameSelected,
          },
        })}
      </Title>

      <ContentContainer>
        <div>
          <OverlayVideo
            skipEventName={`${nameSelected} video intro skip`}
            autoplay
            play={play}
            videoId={videoId}
            onFinish={onFinish}
          />

          <BackButton onClick={onBack}>
            <Icon name="arrow-left" />
          </BackButton>
        </div>
      </ContentContainer>
    </>
  );
};
