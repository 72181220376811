import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useAudio } from "../../../audio";
import { BaseOverlay } from "../../overlay";
import { BaseOverlayProps } from "../../overlay/base";
import { HelpContent, HelpContentProps } from "./content";

export { HelpContent };
export * from "./button";

export type HelpOverlayProps = HelpContentProps & BaseOverlayProps;

export const HelpOverlay = ({
  onAction,
  onClose = onAction,
  ...props
}: HelpOverlayProps) => {
  const { startAmbient, unmute } = useAudio();
  const { t } = useTranslation();

  const handleExperienceStart = useCallback(() => {
    unmute();
    startAmbient();
  }, [startAmbient, unmute]);

  const handleClose = useCallback(() => {
    handleExperienceStart();
    onClose?.();
  }, [handleExperienceStart, onClose]);

  const handleAction = useCallback(() => {
    handleExperienceStart();
    onAction?.();
  }, [handleExperienceStart, onAction]);

  return (
    <BaseOverlay
      title={t("tour.help.title", "Help")}
      {...props}
      onClose={handleClose}
    >
      <HelpContent onAction={handleAction} />
    </BaseOverlay>
  );
};
