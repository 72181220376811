import React, { useCallback, useState } from "react";
import { Vector3 } from "three";

import { CustomTag } from "../../../tag";

import { DownloadsOverlay } from "./overlay";

import useAnalytics from "../../../../../../hooks/use-analytics";

export const position = new Vector3(
  -32.828382474012905,
  1.4108922525950198,
  -46.99897605385722
);

export const sweep = "9d8bbf5762924afc9e7f2730d671f475";

export interface DownloadsTagProps {
  isVisible: boolean;
}

export const DownloadsTag = ({ isVisible }: DownloadsTagProps) => {
  const { sendEvent } = useAnalytics();
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  const handleOverlayClose = useCallback(() => {
    setIsOverlayOpen(false);
  }, []);

  const handleOverlayOpen = useCallback(() => {
    sendEvent("Experience", `Downloads Tag - click`, null, {});//GTM-Green tag, Downloads
    setIsOverlayOpen(true);
  }, []);

  return (
    <>
      <CustomTag
        src="/imgs/extra-tag.png"
        isVisible={isVisible}
        name="downloads"
        location={position}
        onOpen={handleOverlayOpen}
        sweepIdToGo={sweep}
      />

      <DownloadsOverlay shown={isOverlayOpen} onClose={handleOverlayClose} />
    </>
  );
};
