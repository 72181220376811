import React from "react";
import { useTranslation } from "react-i18next";

import { BaseOverlay, OverlayArticle } from "../../ui/overlay";
import { OverlayArticleProps } from "../../ui/overlay/content/article";
import { BaseOverlayProps } from "../../ui/overlay/base";

type InheritedProps = BaseOverlayProps & OverlayArticleProps;

export interface ArtworkOverlayProps extends InheritedProps {}

export const ArtworkOverlay = ({
  children,
  image,
  title,
  onClose,
  author,
  shown,
}: ArtworkOverlayProps) => {
  const { t } = useTranslation();

  return (
    <BaseOverlay
      color="dark"
      shown={shown}
      title={t("tour.artworks.title", "Artwork")}
      onClose={onClose}
    >
      <OverlayArticle image={image} author={author} title={title}>
        {children}
      </OverlayArticle>
    </BaseOverlay>
  );
};
