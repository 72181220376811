import React, { useCallback } from "react";
import styled from "styled-components";

import { GuideSelector, IdleVideo, HoverVideo, Josh, Meli, Guide } from ".";
import { useAudio } from "../audio";
import { useGuide } from "./hook";

import meliCover from "./meli.jpg";
import joshCover from "./josh.jpg";

import useAnalytics from "../../../../hooks/use-analytics";

export interface GuideChooserProps
  extends React.HtmlHTMLAttributes<HTMLButtonElement> {
  onChoose?: (guide: Guide) => void;
  expand?: boolean;
}

// Pairing Josh and Meli heights inside the video

const JoshIdleVideo = styled(IdleVideo)`
  object-position: 50% 10%;
`;

const MeliIdleVideo = styled(IdleVideo)`
  object-position: 50% 20%;
`;

export const GuideChooser = ({ onChoose, ...props }: GuideChooserProps) => {
  const sfx = useAudio();
  const [guide, setGuide] = useGuide();
  const { sendEvent } = useAnalytics();
  
  const handleJoshSelection = useCallback(() => {
    sendEvent("Experience", `Tour Guide Jose Card - click`, null, {});//GTM-tour guide selection in settings/menu
    sfx.click();
    setGuide(Josh);
    onChoose?.(Josh);
  }, [onChoose, setGuide, sfx]);

  const handleMeliSelection = useCallback(() => {
    sendEvent("Experience", `Tour Guide Meli Card - click`, null, {});//GTM-tour guide selection in settings/menu
    sfx.click();
    setGuide(Meli);
    onChoose?.(Meli);
  }, [onChoose, setGuide, sfx]);

  return (
    <>
      <GuideSelector
        {...props}
        active={guide === Josh}
        guideName="Josh"
        onClick={handleJoshSelection}
      >
        <JoshIdleVideo poster={joshCover}>
          <source src="/guide-selection/josh-idle.webm" />
          <source src="/guide-selection/josh-idle.mp4" />
        </JoshIdleVideo>
        <HoverVideo poster={joshCover}>
          <source src="/guide-selection/josh-hover.webm" />
          <source src="/guide-selection/josh-hover.mp4" />
        </HoverVideo>
      </GuideSelector>

      <GuideSelector
        {...props}
        active={guide === Meli}
        guideName="Meli"
        onClick={handleMeliSelection}
      >
        <MeliIdleVideo poster={meliCover}>
          <source src="/guide-selection/meli-idle.webm" />
          <source src="/guide-selection/meli-idle.mp4" />
        </MeliIdleVideo>
        <HoverVideo poster={meliCover}>
          <source src="/guide-selection/meli-hover.webm" />
          <source src="/guide-selection/meli-hover.mp4" />
        </HoverVideo>
      </GuideSelector>
    </>
  );
};
