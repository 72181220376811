import { SDK, MattertagData } from "src/types/sdk";
import i18next from "i18next";

enum Types {
  ID = "id",
  EQUAL = "=",
  STRING = "str",
}

export class TranslateMatterportTag {
  private tags: {
    [key: string]: { description: { [key: string]: string }; label: string };
  } = {};
  constructor(private sdk: SDK) {
    sdk.Mattertag.getData().then((tags) => this.parseTags(tags));
  }

  parseTags(tagsData: MattertagData[]) {
    tagsData
      .filter((t) => t.enabled)
      .forEach((tag) => {
        this.tags[tag.sid] = {
          description: this.getObject(this.parseTokens(tag.description)),
          label: tag.label,
        };
      });
    this.updateAllTags();
  }

  updateMatterTag(sid: string) {
    if (this.tags[sid]) {
      const descObj = this.tags[sid].description;
      this.sdk.Mattertag.editBillboard(sid, {
        label: i18next.t(this.tags[sid].label),
        description:
          (descObj &&
            descObj["tag"] &&
            descObj["link"] &&
            `[${i18next.t(descObj["tag"])}](https://my.matterport.com${
              descObj["link"]
            })`) ||
          " ",
      }).catch(() => {
        if (process.env.NODE_ENV !== "production") {
          console.error(`failed to update tagId = ${sid}`);
        }
      });
    }
  }

  updateAllTags() {
    Object.keys(this.tags).forEach((tag) => this.updateMatterTag(tag));
  }

  parseTokens(text: string): { type: Types; value: string }[] {
    const tokens: { type: Types; value: string }[] = [];
    let index = 0;
    while (index <= text.length) {
      const p = text[index];
      if (p === " " || p === "\n") {
        index++;
        continue;
      }
      if (p === "=") {
        tokens.push({ type: Types.EQUAL, value: "=" });
        index++;
        continue;
      }

      if (p === '"') {
        index++;
        const start = index;
        for (const i of text.slice(index).split("")) {
          if (i === '"') {
            tokens.push({
              type: Types.STRING,
              value: text.slice(start, index),
            });
            index++;
            break;
          }
          index++;
        }
        continue;
      }

      const start = index++;
      for (const i of text.slice(index).split("")) {
        if (i.match(/\W/)) {
          tokens.push({ type: Types.ID, value: text.slice(start, index) });
          break;
        }
        index++;
      }
      index++;
    }

    return tokens;
  }

  getObject(
    tokens: { type: Types; value: string }[]
  ): { [key: string]: string } {
    const obj: { [key: string]: string } = {};
    let index = 0;
    const getToken = () => {
      if (index < tokens.length) return tokens[index++];
      else return tokens[index];
    };
    while (index < tokens.length) {
      const t = getToken();
      if (t.type === Types.ID) {
        if (getToken().type === Types.EQUAL) {
          const v = getToken();
          if (v.type === Types.STRING) {
            obj[t.value] = v.value;
          } else {
            console.error("Syntax error on matterport tag description");
          }
        } else {
          console.error("Syntax error on matterport tag description");
        }
      }
    }
    return obj;
  }
}
