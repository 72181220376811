import { useCallback, useEffect, useRef } from "react";
import { useMatterportSDK } from "src/hooks";
import { Vector3 } from "three";
import { CustomMatterportTag } from "./sdkTag";
import { Pulse } from "./pulse";
import { useAudio } from "../audio";

export interface TagType {
  start: () => void;
  stop: () => void;
}

interface PulseProps {
  location: Vector3;
  name: string;
  isVisible: boolean;
}

interface CustomTagsProps extends PulseProps {
  src: string;
  onClick?: () => void;
  onOpen?: () => void;
  getRef?: (tag: TagType) => void;
  size?: { width: number; height: number };
  sweepIdToGo?: string;
}

//red video circle or green circle, by passing different images to CustomTag
export const CustomTag = ({
  location,
  src,
  name,
  onClick,
  onOpen,
  getRef,
  isVisible,
  size = { width: 0.3, height: 0.3 },
  sweepIdToGo,
}: CustomTagsProps) => {
  const sdk = useMatterportSDK();
  const ref = useRef<CustomMatterportTag | null>(null);
  const sfx = useAudio();

  const handleClick = useCallback(() => {
    sfx.tagClick();
    onClick?.();
  }, [onClick, sfx]);

  useEffect(() => {
    if (sdk != null) {
      const p = async () => {
        const node = await sdk.Scene.createNode();
        const mt = new CustomMatterportTag(
          sdk,
          src,
          {
            w: size.width,
            h: size.height,
          },
          { onClick: handleClick, onOpen },
          sweepIdToGo
        );
        await sdk.Scene.register(`tag-${name}`, () => mt);
        node.addComponent(`tag-${name}`);
        node.position.copy(location);
        await node.start();
        mt.setVisible(isVisible);
        ref.current = mt;
        getRef?.((node as unknown) as TagType);
      };
      p();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sdk, onClick]);

  useEffect(() => {
    ref.current?.setVisible(isVisible);
  }, [isVisible]);

  return null;
};

export const PulseTag = ({ location, name, isVisible }: PulseProps) => {
  const ref = useRef<Pulse | null>(null);
  const sdk = useMatterportSDK();

  useEffect(() => {
    if (sdk != null) {
      const p = async () => {
        const node = await sdk.Scene.createNode();
        const p = new Pulse(sdk, { w: 0.3, h: 0.3 });
        await sdk.Scene.register(`pulse-${name}`, () => p);
        node.addComponent(`pulse-${name}`);
        node.position.copy(location);
        node.start();
        ref.current = p;
        p.setVisible(isVisible);
      };
      p();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sdk, isVisible]);

  useEffect(() => {
    ref.current?.setVisible(isVisible);
  }, [isVisible]);

  return null;
};
