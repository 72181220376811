import styled from "styled-components";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";

import { PageNavType } from ".";
import { Icon } from "src/components";
import { IconButton } from "../../ui";
import { breakpoint } from "src/style";

const PageNavContainer = styled.div`
  left: 50%;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: row;
  background: transparent;
  justify-content: center;

  @media screen and (min-width: ${breakpoint("xs")}) {
    bottom: -110px;
    transform: translate(-50%, -50%) scale(1);
  }

  @media screen and (min-width: ${breakpoint("sm")}) {
    bottom: -110px;
    transform: translate(-50%, -50%) scale(1);
  }

  @media screen and (min-width: ${breakpoint("md")}) {
    bottom: -95px;
    transform: translate(-50%, -50%) scale(0.7);
  }

  @media screen and (min-width: ${breakpoint("lg")}) {
    bottom: -100px;
    transform: translate(-50%, -50%) scale(0.7);
  }

  @media screen and (min-width: ${breakpoint("xlg")}) {
    bottom: -110px;
    transform: translate(-50%, -50%) scale(0.7);
  }

  @media screen and (min-width: ${breakpoint("xlg")}) {
    bottom: -110px;
    transform: translate(-50%, -50%) scale(0.7);
  }

  .box {
    width: 65px;
    margin: 0 8px;
    display: flex;
    align-items: center;

    &:first-of-type {
      width: 350px;
      justify-content: flex-end;

      .texts {
        margin: 0 18px 0 0;

        .label {
          margin-right: -6px;
        }

        .label,
        .title {
          text-align: right;
        }
      }
    }

    &:last-of-type {
      width: 350px;
      justify-content: flex-start;

      .texts {
        margin: 0 0 0 18px;

        .label {
          margin-left: 1.5px;
        }

        .label,
        .title {
          text-align: left;
        }
      }
    }

    &.hidden {
      visibility: hidden;
    }

    .texts {
      width: 250px;
      display: flex;
      color: #ffffff;
      user-select: none;
      align-items: center;
      flex-direction: column;

      .label {
        width: 100%;
        opacity: 0.6;
        display: block;
        font-size: 11px;
        line-height: 17px;
        font-weight: bold;
        font-style: normal;
        font-family: "Pike";
        letter-spacing: 3px;
        text-transform: uppercase;
      }

      .title {
        width: 100%;
        display: block;
        font-size: 15px;
        line-height: 24px;
        font-style: normal;
        font-weight: normal;
        align-items: center;
        font-family: "SoDo Sans";
      }
    }

    ${IconButton} {
      width: 64px;
      height: 64px;

      ${Icon} {
        font-size: 24.67px;
      }
    }
  }
`;

export const PageNav: PageNavType = ({
  page = 0,
  goToPage,
  gotoNextPage,
  gotoPreviousPage,
}) => {
  const { t } = useTranslation();
  const [prevTitle, setPrevTitle] = useState("");
  const [nextTitle, setNextTitle] = useState("");

  useEffect(() => {
    setPrevTitle(
      page === 0 ? "" : t(`tour.field-journal.nav.pageTitles.${[page - 1]}`)
    );
  }, [t, page]);

  useEffect(() => {
    //Length must be update if the number of pages changes
    setNextTitle(
      page === 17 ? "" : t(`tour.field-journal.nav.pageTitles.${[page + 1]}`)
    );
  }, [t, page]);

  return (
    <PageNavContainer>
      <div
        className={page > 0 ? "box prev-box" : "box prev-box hidden"}
        onClick={() => {
          gotoPreviousPage();
        }}
      >
        <div className="texts">
          <span className="label">{t(`tour.field-journal.nav.prev`)}</span>
          <span className="title">{prevTitle}</span>
        </div>

        <IconButton>
          <Icon name="arrow-left" />
        </IconButton>
      </div>

      <div
        className="box home-box"
        onClick={() => {
          goToPage(0);
        }}
      >
        <IconButton>
          <Icon name="home" />
        </IconButton>
      </div>

      <div
        className={page <= 16 ? "box next-box" : "box next-box hidden"}
        onClick={() => {
          gotoNextPage();
        }}
      >
        <IconButton>
          <Icon name="arrow-right" />
        </IconButton>

        <div className="texts">
          <span className="label">{t(`tour.field-journal.nav.next`)}</span>
          <span className="title">{nextTitle}</span>
        </div>
      </div>
    </PageNavContainer>
  );
};
