import React, { useState, ReactNode } from "react";
import { GuideContext } from ".";

interface GuideProviderProps {
  children?: ReactNode;
}

export const GuideProvider = ({ children }: GuideProviderProps) => {
  const selector = useState<symbol | null>(null);

  return (
    <GuideContext.Provider value={selector}>{children}</GuideContext.Provider>
  );
};
