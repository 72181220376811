import styled from "styled-components";
import React, { useState, useCallback } from "react";

import {
  Icon,
  Book,
  BookOverlay,
  FieldJournalProgressType,
} from "./components";
import { breakpoint } from "src/style/theme";
import useAnalytics from "../../../../hooks/use-analytics";

const FieldJournalContainer = styled.div`
  background: transparent;

  @media screen and (max-width: ${breakpoint("xs")}) {
    display: none;
  }
`;

export const FieldJournal: FieldJournalProgressType = ({
  progress,
  isVisible,
}) => {
  const [isFieldJournalOverlayOpen, setIsFieldJournalOverlayOpen] = useState(
    false
  );

  const { sendEvent } = useAnalytics();

  const handleBtnOpen = useCallback(
    () => {
      sendEvent("Experience", ` Journal Link - click`, null, {});//GTM-journey link at right bottom corner
      setIsFieldJournalOverlayOpen(true)},
    []
  );
  const handleBtnClose = useCallback(
    () => setIsFieldJournalOverlayOpen(false),
    []
  );

  return (
    <FieldJournalContainer>
      <Icon
        isFieldJournalOverlayOpen={isFieldJournalOverlayOpen || !isVisible}
        onClick={handleBtnOpen}
      />

      <BookOverlay
        shown={isVisible && isFieldJournalOverlayOpen}
        onClose={handleBtnClose}
      >
        <Book progress={progress} />
      </BookOverlay>
    </FieldJournalContainer>
  );
};
