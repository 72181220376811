import { Vector3 } from "three";
import React, { useState, useCallback } from "react";

import { CustomTag } from "../../tag";
import { ArtworkOverlay, ArtworkOverlayProps } from "./overlay";
import useAnalytics from "../../../../../hooks/use-analytics";

export interface ArtwortTagProps extends ArtworkOverlayProps {
  name: string;
  position: Vector3;
  sweep?: string;
  isVisible?: boolean;
  onComplete?: () => void;
}

export type ArtworkTagPublicProps = Pick<ArtwortTagProps, "isVisible">;

export const ArtworkTag = ({
  name,
  position,
  isVisible = false,
  sweep,
  ...props
}: ArtwortTagProps) => {
  const [isOverlayOpen, setIsOverlayOpen] = useState<boolean>(false);
  const { sendEvent } = useAnalytics();

  const handleOverlayClose = useCallback(() => {
    setIsOverlayOpen(false);
  }, []);

  const handleOverlayOpen = useCallback(() => {
    sendEvent("Experience", `${props.title} Tag - click`, null, {});//GTM-Green color tag for 2: History of Coffee and Introspección (Introspection) ;
    setIsOverlayOpen(true);
  }, []);

  return (
    <>
      <CustomTag
        src="/imgs/extra-tag.png"
        isVisible={isVisible}
        name={name}
        location={position}
        onOpen={handleOverlayOpen}
        sweepIdToGo={sweep}
        size={{ width: 0.2, height: 0.2 }}
      />

      <ArtworkOverlay
        shown={isOverlayOpen}
        onClose={handleOverlayClose}
        {...props}
      />
    </>
  );
};
