import { createGlobalStyle } from "styled-components";

import PikeRegularWoff from "./Pike-Regular.woff";
import PikeRegularWoff2 from "./Pike-Regular.woff2";
import SodoSansRegularWoff from "./SoDoSans-Regular.woff";
import SodoSansRegularWoff2 from "./SoDoSans-Regular.woff2";
import SodoSansSemiBoldWoff from "./SoDoSans-SemiBold.woff";
import SodoSansSemiBoldWoff2 from "./SoDoSans-SemiBold.woff2";
import LanderGrandeRegularWoff from "./LanderGrande-Regular.woff";
import LanderGrandeRegularWoff2 from "./LanderGrande-Regular.woff2";
import LanderGrandeSemiBoldWoff from "./LanderGrande-SemiBold.woff";
import LanderGrandeSemiBoldWoff2 from "./LanderGrande-SemiBold.woff2";

export const Fonts = createGlobalStyle`
@font-face {
    font-family: 'Pike';
    font-style: normal;
    font-display: swap;
    src: url(${PikeRegularWoff}) format('woff'), url(${PikeRegularWoff2}) format('woff2');
  }

  @font-face {
    font-family: 'SoDo Sans';
    font-style: normal;
    font-display: swap;
    src: url(${SodoSansRegularWoff}) format('woff'), url(${SodoSansRegularWoff2}) format('woff2');
  }

  @font-face {
    font-family: 'SoDo Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(${SodoSansSemiBoldWoff}) format('woff'), url(${SodoSansSemiBoldWoff2}) format('woff2');
  }

  @font-face {
    font-family: 'Lander Grande';
    font-style: normal;
    font-display: swap;
    src: url(${LanderGrandeRegularWoff}) format('woff'), url(${LanderGrandeRegularWoff2}) format('woff2');
  }

  @font-face {
    font-family: 'Lander Grande';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(${LanderGrandeSemiBoldWoff}) format('woff'), url(${LanderGrandeSemiBoldWoff2}) format('woff2');
  }
`;
