import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAudio } from "src/pages/tour/components/audio";

import {
  BaseOverlay,
  OverlayProps,
  OverlayVideo,
  Title,
} from "../../../../ui/overlay";

import { GuideSelectorContent } from "./content";
import { GuideSelectorProps } from "./content";

export type CheckinOverlayInheritedProps = Pick<OverlayProps, "shown"> &
  Pick<GuideSelectorProps, "onGuideSelect">;

export interface CheckinOverlayProps extends CheckinOverlayInheritedProps {
  onClose?: () => void;
  isActive?: boolean;
  isVisible?: boolean;
}

export const CheckinOverlay = ({
  shown,
  onGuideSelect,
}: CheckinOverlayProps) => {
  const { t } = useTranslation();
  const sfx = useAudio();

  useEffect(() => {
    if (shown) {
      sfx.pauseAmbient();
    }
  }, [shown, sfx]);

  const [isIntroPlayed, setIntroPlayed] = useState(false);

  const handleSelect = useCallback(
    (guide) => {
      sfx.startAmbient();
      onGuideSelect?.(guide);
    },
    [sfx, onGuideSelect]
  );

  return (
    <BaseOverlay shown={shown}>
      {isIntroPlayed ? (
        <GuideSelectorContent onGuideSelect={handleSelect} />
      ) : (
        <>
          <Title>
            {t("tour.checkin.title", "Welcome to Hascienda Alsacia")}
          </Title>
          <OverlayVideo
            skipEventName="Checkin intro video skip"
            play={shown}
            onFinish={() => setIntroPlayed(true)}
            videoId="502306365"
          />
        </>
      )}
    </BaseOverlay>
  );
};
