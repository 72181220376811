export const CheckInChapter = Symbol.for("check-in");
export const WorldOfCoffeeChapter = Symbol.for("world-of-coffee");
export const CentralAmericaChapter = Symbol.for("central-america");
export const NurseryChapter = Symbol.for("nursery");
export const VarietalsChapter = Symbol.for("varietals");
export const HarvestChapter = Symbol.for("harvest");
export const ProcessingChapter = Symbol.for("processing");
export const DryingChapter = Symbol.for("drying");
export const ResearchChapter = Symbol.for("research");
export const RoastingChapter = Symbol.for("roasting");
export const WatterfallChapter = Symbol.for("watterfall");
export const EndingChapter = Symbol.for("ending");

export type Chapter =
  | typeof CheckInChapter
  | typeof WorldOfCoffeeChapter
  | typeof CentralAmericaChapter
  | typeof NurseryChapter
  | typeof VarietalsChapter
  | typeof HarvestChapter
  | typeof ProcessingChapter
  | typeof DryingChapter
  | typeof ResearchChapter
  | typeof RoastingChapter
  | typeof WatterfallChapter
  | typeof EndingChapter;

export interface TagMap {
  [CheckInChapter]: boolean;
  [WorldOfCoffeeChapter]: boolean;
  [CentralAmericaChapter]: boolean;
  [NurseryChapter]: boolean;
  [VarietalsChapter]: boolean;
  [HarvestChapter]: boolean;
  [ProcessingChapter]: boolean;
  [DryingChapter]: boolean;
  [ResearchChapter]: boolean;
  [RoastingChapter]: boolean;
  [WatterfallChapter]: boolean;
  [EndingChapter]: boolean;
}
