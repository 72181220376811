import React from "react";
import { Vector3 } from "three";

import { ChapterTag, ChapterTagPublicProps } from "../../interactive-tags";

export const position = new Vector3(
  2.791409226441643,
  1.15840281910633695,
  -51.41436132669736
);

export const sweep = "1f3904ff584b4931807847cb957c4f41";

export const ResearchTag = (props: ChapterTagPublicProps) => {
  return (
    <ChapterTag
      name="research"
      position={position}
      joshVideo="502309054"
      meliVideo="502308628"
      sweep={sweep}
      {...props}
    />
  );
};
