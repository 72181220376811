import { Vector3 } from "three";
import React, { useState, useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";

import { ai } from "src/telemetryService";
import { VideoOverlay, VideoOverlayProps } from "./overlay";
import { CustomTag, PulseTag } from "../../tag";
import { useCameraLookAt } from "../../../../../hooks/look-at";
import { Alert } from "../../alerts";
import { NavigateButton } from "../../navigate-button";
import useAnalytics from "../../../../../hooks/use-analytics";

export interface ChapterTagProps
  extends Pick<VideoOverlayProps, "title" | "joshVideo" | "meliVideo"> {
  name: string;
  position: Vector3;
  sweep?: string;
  nextStop?: Vector3;
  isActive?: boolean;
  isVisible?: boolean;
  onComplete?: () => void;
  eventName?: string;
}

export type ChapterTagPublicProps = Pick<
  ChapterTagProps,
  "isActive" | "isVisible" | "onComplete" | "nextStop" | "eventName"
>;

export const ChapterTag = ({
  name,
  title,
  position,
  joshVideo,
  meliVideo,
  isVisible = false,
  isActive = isVisible,
  onComplete,
  nextStop,
  sweep,
  eventName,
}: ChapterTagProps) => {
  const { t } = useTranslation();
  const { sendEvent } = useAnalytics();
  const [isOverlayOpen, setIsOverlayOpen] = useState<boolean>(false);
  const turn = useCameraLookAt();
  const isCurrent = isVisible && isActive;
  const chapterTitle = t(`tour.${name}.title`, title);

  const handleTagComplete = useCallback(() => {
    setIsOverlayOpen(false);
    onComplete?.();

    if (nextStop) {
      turn(nextStop);
    }
  }, [nextStop, onComplete, turn]);

  const handleOverlayOpen = useCallback(() => {
    setIsOverlayOpen(true);
  }, []);

  const handleGoToBtnClick  = () => {
    sendEvent(
      "Experience",
      `Go To ${name} Link - click`,// GTM-bottom navigation button click
      null,
      {}
    );
  }

  return (
    <>
      <CustomTag
        src="/imgs/tag.png"
        isVisible={isVisible}
        name={name}
        location={position}
        onOpen={() => {
          if (eventName) {
            ai.trackEvent({ name: eventName });
            sendEvent(
              "Experience",
              `${eventName} Tag - click`,//GTM-Red color tag for videos
              null,
              {}
            );
          }
          handleOverlayOpen();
        }}
        sweepIdToGo={sweep}
      />

      <PulseTag location={position} isVisible={isCurrent} name={name} />

      <VideoOverlay
        title={chapterTitle}
        joshVideo={joshVideo}
        meliVideo={meliVideo}
        shown={isOverlayOpen}
        onFinish={handleTagComplete}
        skipEventName={`Skip ${eventName}`}
      />

      <Alert
        icon={<img src="/imgs/tag.png" alt="Main chapter tag" />}
        // disable tag if overlay is open or tag is not active
        shown={isOverlayOpen || !isCurrent ? false : undefined}
        delay={1000}
      >
        <Trans i18nKey={`tour.${name}.alert`} t={t}>
          Heads up to the{" "}
          <NavigateButton to={{ sweep, position }} onClick={handleGoToBtnClick}>
            {chapterTitle}
          </NavigateButton>
          !
        </Trans>
      </Alert>
    </>
  );
};
