import React from "react";
import { useTranslation } from "react-i18next";

import { Icon } from "src/components";
import { MenuUIButton } from "../button";

export const HelpButton = (
  props: React.HtmlHTMLAttributes<HTMLButtonElement>
) => {
  const { t } = useTranslation();

  return (
    <MenuUIButton {...props}>
      <Icon name="help" />
      {t("tour.menu.help-button", "How to explore")}
    </MenuUIButton>
  );
};
