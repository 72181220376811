import React, { forwardRef, HtmlHTMLAttributes } from "react";
import styled from "styled-components";

export interface CustomProps extends HtmlHTMLAttributes<HTMLIFrameElement> {
  model: string;
  autoPlay?: boolean;
  quickStart?: boolean;
  showTitle?: boolean;
  log?: boolean;
  applicationKey: string;
}

const ModelFrame = styled.iframe`
  width: 100vw;
  height: 100vh;
`;

export const MatterportModel = forwardRef<
  HTMLIFrameElement | null,
  CustomProps
>(
  (
    {
      model,
      autoPlay = true,
      quickStart = true,
      title,
      children,
      showTitle = false,
      log = false,
      applicationKey,
      ...props
    },
    ref
  ) => {
    const boolToQueryString = (t: boolean): string => (t ? "1" : "0");

    return (
      <ModelFrame
        ref={ref}
        className="matterportView"
        title="Virtual Space Showcase"
        src={`/bundle/matterport.html?m=${model}&play=${boolToQueryString(
          autoPlay
        )}&qs=${boolToQueryString(quickStart)}&log=0&title=${boolToQueryString(
          showTitle
        )}&log=${boolToQueryString(log)}&applicationKey=${applicationKey}`}
        frameBorder="0"
        allowFullScreen={false}
        allow="allow-scripts"
        {...props}
      />
    );
  }
);
