import THREE, { Object3D, Quaternion, Vector2, Vector3 } from "three";

export interface Node {
  name: string;
  quaternion: Quaternion;
  scale: Vector3;
  position: Vector3;
  addComponent: <T = any, I = Object>(name: string, obj?: I) => T;
  start: () => void;
  stop: () => void;
}

interface CameraPose {
  position: Vector3;
  projection: Float32Array;
  rotation: Vector2;
}

type CallbackFunction = (...data: any[]) => void;
type ObserverCallback<T> = (data: T) => void;

export interface ISubscription {
  cancel: () => void;
}

export interface IObservable<T> {
  subscribe(observer: ObserverCallback<T>): ISubscription;
}

/**
 * This structure is not complete
 *
 * @see https://matterport.github.io/showcase-sdk/docs/sdkbundle/reference/current/modules/app.html#state
 */
export interface AppState {
  phase: APP_PHASE;
}

export interface SDK {
  App: {
    state: IObservable<AppState>;
  };
  Sweep: {
    moveTo: (
      sweep: string,
      options: {
        rotation?: Vector2;
        transition?: TRANSITION;
        transitionTime?: number;
      }
    ) => Promise<string>;
  };
  Mode: {
    moveTo: (
      mode: MODE,
      options: {
        position?: Vector3;
        rotation?: Vector3;
        transition?: TRANSITION;
        zoom?: number;
      }
    ) => Promise<MODE>;
  };
  Pointer: {
    intersection: {
      subscribe: (param: (data: { object: string }) => void) => void;
    };
  };
  Camera: {
    pose: IObservable<CameraPose>;
    getPose: () => Promise<CameraPose>;
    rotate: (h: number, v?: number) => Promise<void>;
  };
  Scene: {
    createNode: () => Promise<Node>;
    register: <T = any>(name: string, factory: () => T) => void;
  };
  Mattertag: {
    getData: { (): Promise<Array<MattertagData>> };
    data: {
      subscribe: {
        (s: {
          onAdded: {
            (
              index: number,
              tag: MattertagData,
              collection: Array<MattertagData>
            ): void;
          };
          onRemoved: {
            (
              index: number,
              tag: MattertagData,
              collection: Array<MattertagData>
            ): void;
          };
          onUpdated: {
            (
              index: number,
              tag: MattertagData,
              collection: Array<MattertagData>
            ): void;
          };
        }): ISubscription;
      };
    };
    editBillboard: {
      (
        tagSid: string,
        properties: {
          description: string;
          label: string;
          media?: {
            src: string;
            type: MediaType;
          };
        }
      ): Promise<void>;
    };
  };

  on: (event: string, cb: CallbackFunction) => void;
  off: (event: string, cb: CallbackFunction) => void;
}

interface Context {
  three: typeof THREE;
  camera: THREE.PerspectiveCamera;
}

interface Output {
  collider: Object3D;
  objectRoot: Object3D;
}

export abstract class SceneObject {
  root?: Object3D;
  context?: Context;
  outputs?: Output;
}

export enum INTERACTION {
  CLICK = "INTERACTION.CLICK",
  POINTER_MOVE = "INTERACTION.POINTER_MOVE",
}

export enum APPLICATION_EVENTS {
  PHASE_CHANGE = "application.phasechange",
}

export enum APP_PHASE {
  ERROR = "appphase.error",
  LOADING = "appphase.loading",
  PLAYING = "appphase.playing",
  STARTING = "appphase.starting",
  UNINITIALIZED = "appphase.uninitialized",
  WAITING = "appphase.waiting",
}

export enum MODE {
  DOLLHOUSE = "mode.dollhouse",
  FLOORPLAN = "mode.floorplan",
  INSIDE = "mode.inside",
  OUTSIDE = "mode.outside",
  TRANSITIONING = "mode.transitioning",
}

export enum TRANSITION {
  FADEOUT = "transition.fadeout",
  FLY = "transition.fly",
  INSTANT = "transition.instant",
}

export interface MattertagData {
  anchorPosition: Vector3;
  color: Color;
  description: string;
  floorInfo: {
    id: string;
    sequence: number;
  };
  label: string;
  media: {
    src: string;
    type: MediaType;
  };
  sid: string;
  stemVector: Vector3;
  enabled: boolean;
}

export enum MediaType {
  NONE = "mattertag.media.none",
  PHOTO = "mattertag.media.photo",
  VIDEO = "mattertag.media.video",
  RICH = "mattertag.media.rich",
}

interface Color {
  r: string;
  g: string;
  b: string;
}
