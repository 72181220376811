import React, { useState, useCallback } from "react";

import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { PageTransitionProvider } from "../../hooks";

const TransitionsBackground = styled.div`
  background-color: black;
`;

interface Props {
  active: boolean;
}

const TransitionsContainer = styled.div<Props>`
  @keyframes fade-out {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  opacity: 1;
  animation: ${({ active }) => active && "0.5s ease-in fade-out"};
`;

export const PageTransitions = (
  props: React.HtmlHTMLAttributes<HTMLDivElement>
) => {
  const [to, setTarget] = useState("");
  const history = useHistory();
  const [isNavigating, setNavigation] = useState(false);

  const handleAnimationFinished = useCallback(() => {
    setNavigation(false);
    if (to) history.push(to);
  }, [history, to]);

  const navigate = useCallback((to: string) => {
    setTarget(to);
    setNavigation(true);
  }, []);

  return (
    <TransitionsBackground>
      <PageTransitionProvider value={{ to, navigate }}>
        <TransitionsContainer
          className="transition-container"
          onAnimationEnd={handleAnimationFinished}
          active={isNavigating}
          {...props}
        />
      </PageTransitionProvider>
    </TransitionsBackground>
  );
};
