import { breakpoint } from "src/style";
import styled, { css, keyframes } from "styled-components";

export const OverlayContent = styled.div`
  align-self: flex-start;
  flex: 1 0;
  width: 100%;
`;

const DarkOverlayFadeIn = keyframes`
  from {
    background-color: rgba(0, 0, 0, 0);
    opacity: 0;
  }

  to {
    background-color: #000;
    backdrop-filter: blur(12px);
    opacity: 1;
  }
`;

const OverlayDarkTheme = css`
  animation: ${DarkOverlayFadeIn} ease-in-out 1s;
  background-color: #000;
  backdrop-filter: none;
`;

const OverlayFadeOut = keyframes`
  0% {
    opacity: 1;
    z-index: 5;
    background-color: rgba(0, 0, 0, .8);
  }

  99% {
    opacity: 0;
  }

  100% {
    z-index: -1;
    background-color: rgba(0, 0, 0, 0);
  }
`;

const NaturalOverlayFadeIn = keyframes`
  0% {
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: blur(0px);
    opacity: 0;
  }

  30% {
    opacity: 0;
  }

  80% {
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(12px);
  }

  100% {
    opacity: 1;
  }
`;

const OverlayNaturalTheme = css`
  animation: ${NaturalOverlayFadeIn} ease-out 1s;
  background-color: rgba(0, 0, 0, 0.85);

  @supports (backdrop-filter: blur(12px)) {
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(12px);
  }

  @media screen and (min-width: ${breakpoint("md")}) {
    @supports (backdrop-filter: blur(12px)) {
      background-color: rgba(0, 0, 0, 0.6);
      backdrop-filter: blur(12px);
    }
  }
`;

const ContentOverlayFadeIn = keyframes`
  0% {
    transform: translateY(10%);
  }

  30% {
    transform: translateY(10%);
  }

  100% {
    transform: translateY(0%);
  }
`;

export type Themes = "natural" | "dark";
interface ThemeOptions {
  color?: Themes;
}

const overlayThemeSwitch = (options: ThemeOptions) =>
  options.color === "dark" ? OverlayDarkTheme : OverlayNaturalTheme;

export interface OverlayProps extends ThemeOptions {
  shown?: boolean;
}

export const Overlay = styled.div<OverlayProps>`
  animation: ${OverlayFadeOut} 0.5s ease-out;
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  color: ${(props) => props.theme.brand.white};

  ${({ shown }) =>
    shown &&
    css`
      z-index: 5;
      ${overlayThemeSwitch}

      ${OverlayContent} {
        animation: ${ContentOverlayFadeIn} 0.3s ease-out;
        transform: translateY(0%);
      }
    `}
`;
