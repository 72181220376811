import React from "react";
import { Vector3 } from "three";

import { ChapterTag, ChapterTagPublicProps } from "../../interactive-tags";

export const position = new Vector3(
  -27.748236672226,
  1.229544046442066,
  -56.79187859041971
);

export const sweep = "6bfc04ef2ec646f891907a545b1a5e17";

export const WaterfallTag = (props: ChapterTagPublicProps) => {
  return (
    <ChapterTag
      name="waterfall"
      position={position}
      joshVideo="503091253"
      meliVideo="503091253"
      sweep={sweep}
      {...props}
    />
  );
};
