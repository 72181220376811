import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Page } from "./page";
import { ChapterProps, ChapterType } from "./index";

const DefaultChapterContainer = styled.div<ChapterProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transpartent;

  .content {
    width: 100%;
    height: 630px;
  }

  .content-left {
    background-image: ${({ unlocked, language, pageLeft }) =>
      unlocked
        ? `url(${pageLeft})`
        : `url("/field-journal/${language}/locked.png"),
    url(${pageLeft})`};
    background-size: contain;
  }

  .content-right {
    background-image: ${({ unlocked, language, pageRight }) =>
      unlocked
        ? `url(${pageRight})`
        : `url("/field-journal/${language}/locked.png"),
    url(${pageRight})`};
    background-size: contain;
  }
`;

export const DefaultChapter: ChapterType = ({
  title,
  unlocked,
  pageLeft,
  pageRight,
}) => {
  const { t } = useTranslation();
  const language = t("language-name");

  return (
    <DefaultChapterContainer
      title={title}
      unlocked={unlocked}
      language={language}
      pageLeft={pageLeft}
      pageRight={pageRight}
    >
      <Page>
        <div className="content content-left"></div>
      </Page>
      <Page>
        <div className="content content-right"></div>
      </Page>
    </DefaultChapterContainer>
  );
};
