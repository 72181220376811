import React from "react";
import styled from "styled-components";

interface ChevronProps {
  opposite?: boolean;
}

const Chevron = styled.span<ChevronProps>`
  color: inherit;
  transition: transform 0.2s ease-in-out;
  transform: ${(props) => props.opposite && "rotate(-180deg)"};
`;

interface ContentContainerProps {
  open?: boolean;
}

const ContentContainer = styled.div<ContentContainerProps>`
  width: 100%;
  min-width: min-content;
  transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
  opacity: ${(props) => (props.open ? "1" : "0")};
  transform: ${(props) => (props.open ? "translateY(0)" : "translateY(10%)")};
  z-index: ${(props) => (props.open ? "1" : "-1")};
  pointer-events: ${(props) => (props.open ? "visiblePainted" : "none")};
  position: absolute;
  right: 0;
`;

const DropdownContainer = styled.div`
  display: inline-block;
  position: relative;
  z-index: 2;
`;

export interface DropdownProps
  extends React.HtmlHTMLAttributes<HTMLButtonElement> {
  message?: React.ReactNode;
  icon?: React.ReactNode;
  open?: boolean;
  onOpen?: CallableFunction;
  chevron?: React.ReactNode;
}

const ButtonContainer = styled.button`
  margin: 0;
  padding: 0;
  border: 0;
  color: inherit;
  text-transform: inherit;
  background-color: transparent;
  outline: 0;
  width: 100%;
`;

export const Dropdown = ({
  icon = null,
  message = "No option was selected",
  open: controledOpen,
  onClick,
  onOpen,
  chevron = "v",
  children,
  ...props
}: DropdownProps) => {
  const [isOpen, setOpen] = React.useState(controledOpen || false);

  const isControled = typeof controledOpen === "boolean";
  const open = isControled ? controledOpen : isOpen;
  const handleOpen = React.useCallback(
    (ev) => {
      onClick?.(ev);
      isControled ? onOpen?.() : setOpen(!isOpen);
    },
    [isOpen, isControled, setOpen, onOpen, onClick]
  );

  return (
    <DropdownContainer>
      <ButtonContainer {...props} onClick={handleOpen}>
        {icon}

        <span>{message}</span>

        <Chevron className="button" opposite={open}>
          {chevron}
        </Chevron>
      </ButtonContainer>
      <ContentContainer open={open}>{children}</ContentContainer>
    </DropdownContainer>
  );
};
