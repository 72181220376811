import React from "react";
import { breakpoint } from "src/style";
import styled, { css } from "styled-components";

import { Separator } from "../../../../components";

const Sep = styled(Separator)`
  max-width: 40px;
`;

const SelectorVideo = styled.video`
  transition: opacity 500ms;
  border-radius: 5.31482px;
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

SelectorVideo.defaultProps = {
  loop: true,
  muted: true,
  autoPlay: true,
  preload: "auto",
  playsInline: true,
};

export const IdleVideo = styled(SelectorVideo)`
  opacity: 1;
`;

export const HoverVideo = styled(SelectorVideo)`
  opacity: 0;
  position: absolute;
  top: 0;
  pointer-events: none;
  left: 0;
  right: 0;
  padding: 0.5em;
  box-sizing: border-box;
`;

const Name = styled.p`
  text-transform: uppercase;
  letter-spacing: 0.12em;
  line-height: 150%;
  font-size: 2em;
  margin-bottom: 0.5em;
  transition: font-size 0.5s;
  font-family: ${(props) => props.theme.fonts.main};
  -webkit-font-smoothing: subpixel-antialized;
  font-weight: 600;
`;

const Caption = styled.div`
  position: absolute;
  padding-bottom: 0.5em;
  font-size: 0.8em;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.sm}) {
    padding-bottom: 3em;
  }

  // After an parent transform, a little area could not be covered by the
  // Caption background, so we force two pixels beyond it should be to prevent
  // this uncovered area to appear
  left: -0.4px;
  right: -0.4px;
  bottom: -0.4px;
  border-bottom-left-radius: 5.31482px;
  border-bottom-right-radius: 5.31482px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(7, 5, 5, 0.738) 100%
  );
`;

const CaptionContainer = styled.div`
  position: relative;
  width: 100%;
`;

const selectorActive = css<Pick<SelectorProps, "expand">>`
  border: 1px solid #ffffff;
  opacity: 1;

  ${IdleVideo} {
    opacity: 1;
  }

  ${HoverVideo} {
    opacity: 0;
  }

  ${(props) =>
    props.expand &&
    css`
      transform: scale(1);

      ${Name} {
        @media screen and (min-width: ${(props) =>
            props.theme.breakpoints.md}) {
          font-size: 2.5em;
        }
      }
    `}
`;

interface SelectorProps {
  active?: boolean;
  expand?: boolean;
}

const Selector = styled.button<SelectorProps>`
  display: block;
  overflow: hidden;
  margin: 0 0.5em;
  outline: 0;
  color: ${(props) => props.theme.brand.white};
  text-align: center;
  background: transparent;
  border-radius: 8px;
  transition-property: background-color, border-radius, border-color, box-shadow,
    transform, opacity, padding;
  transition-duration: 500ms;
  box-sizing: content-box;
  cursor: pointer;
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  padding: 0.5em;
  font-size: 1em;

  ${(props) =>
    !props.expand &&
    css`
      opacity: 0.7;
      width: 8em;
      height: 8em;

      @media screen and (min-width: ${breakpoint("md")}) {
        width: 13em;
        height: 13em;
      }

      ${Name} {
        font-size: 1.25em;
      }

      ${Caption} {
        padding-bottom: 1em;
      }

      &:hover {
        opacity: 1;
      }
    `}

  ${(props) =>
    props.expand &&
    css`
      box-shadow: 0px 13.287px 24.8025px rgba(0, 0, 0, 0.45);
      width: auto;
      padding: 0;
      height: 100%;

      @media screen and (min-width: ${(props) => props.theme.breakpoints.sm}) {
        transform: scale(0.78);
        margin: 0 0.5em;
      }

      @media screen and (min-width: ${(props) => props.theme.breakpoints.md}) {
        width: 35%;
      }

      @media (hover: hover) {
        &:hover {
          border-radius: 12px;
          box-shadow: none;
          background-color: rgba(0, 0, 0, 0.4);
          transform: scale(1);
          opacity: 1;
          padding: 0.5em;
          // Here we are using a width slightly thinner than the one Figma
          // shows due to the scaling applied on hover
          /* border-width: 13px; */

          ${IdleVideo} {
            opacity: 0;
          }

          ${HoverVideo} {
            opacity: 1;
          }

          ${Name} {
            font-size: 2.5em;
          }
        }
      }
    `}

  &:active {
    ${selectorActive}
  }

  ${(props) => props.active && selectorActive}
`;

type InheritedProps = React.HtmlHTMLAttributes<HTMLButtonElement> &
  SelectorProps;

export interface GuideSelectorProps extends InheritedProps {
  guideName: string;
  expand?: boolean;
}

export const GuideSelector = ({
  guideName,
  children,
  ...props
}: GuideSelectorProps) => {
  return (
    <Selector {...props}>
      {children}

      <CaptionContainer>
        <Caption>
          <Name>{guideName}</Name>
          <Sep />
        </Caption>
      </CaptionContainer>
    </Selector>
  );
};
