import styled from "styled-components";

export const Loading = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  position: fixed;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  background: rgba(0, 0, 0);
`;
