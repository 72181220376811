import React, { lazy, Suspense } from "react";
import { ThemeProvider } from "styled-components";
import { MemoryRouter, BrowserRouter, Switch, Route } from "react-router-dom";

import TelemetryProvider from "./components/common/TelemetryProvider";
import "./i18n";

import { GlobalStyle, Fonts, theme } from "./style";
import { Loading, PageTransitions } from "./components";

let Router = MemoryRouter;
if (process.env.NODE_ENV !== "production") {
  Router = BrowserRouter;
}

const Tour = lazy(() => import("./pages/tour"));
const LandPage = lazy(() => import("./pages/land-page"));

export const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Fonts />
      <GlobalStyle />
      <Router>
        <TelemetryProvider>
          <PageTransitions>
            <Suspense fallback={<Loading />}>
              <Switch>
                <Route exact path="/" component={LandPage} />
                <Route exact path="/tour" component={Tour} />
                <Route
                  exact
                  path="/dev"
                  component={(props: any) => (
                    <Tour {...props} playIntro={false} />
                  )}
                />
              </Switch>
            </Suspense>
          </PageTransitions>
        </TelemetryProvider>
      </Router>
    </ThemeProvider>
  );
};
