import React from "react";
import { Vector3 } from "three";

import { ChapterTag, ChapterTagPublicProps } from "../../interactive-tags";

export const position = new Vector3(
  -29.089842309787507,
  1.3061361794516924,
  -50.99055823614774
);

export const sweep = "36ead21f9a5144fdb26cfa835edbcb5f";

export const RoastingTag = (props: ChapterTagPublicProps) => {
  return (
    <ChapterTag
      name="roasting"
      position={position}
      joshVideo="503091396"
      meliVideo="503093231"
      sweep={sweep}
      {...props}
    />
  );
};
