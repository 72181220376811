import { useCallback, useContext, useEffect, useState } from "react";
import { SDKContext } from "../pages/tour";
import { Observable } from "../pages/tour/observable";
import { SDK } from "../types/sdk";

export const useMatterportSDK = (): SDK | null => {
  const observable = useContext<Observable>(SDKContext);
  const [sdk, setSDK] = useState(null);
  const setSdk = useCallback((newSdk: any) => setSDK(newSdk), []);

  useEffect(() => {
    return observable?.subscribe(setSdk);
  }, [observable, setSdk]);

  return sdk;
};
