import React from "react";
import styled from "styled-components";
import { useTranslation, Trans } from "react-i18next";

import { OverlayArticle } from "../../../../../ui/overlay";
import { Title } from "src/pages/tour/components/ui/overlay/content/article";

import image from "./shakerato.jpg";

const RecipeTitle = styled(Title)`
  small {
    opacity: 1;
    font-size: 0.835em;
  }
`;

export const ShakeratoRecipe = () => {
  const { t } = useTranslation();

  return (
    <OverlayArticle
      image={image}
      title={t("tour.coffee-recipes.shakerato.title", "Shakerato")}
      subtitle={t("tour.coffee-recipes.shakerato.subtitle", "Serves 1")}
    >
      <RecipeTitle>
        <small>{t("tour.coffee-recipes.ingredients", "INGREDIENTS")}</small>
      </RecipeTitle>

      <strong>
        {t("tour.coffee-recipes.shakerato.shakerato.title", "For Shakerato")}
      </strong>
      <ul>
        <li>
          {t(
            "tour.coffee-recipes.shakerato.shakerato.ingredients.0",
            "2 shots of espresso"
          )}
        </li>
        <li>
          {t(
            "tour.coffee-recipes.shakerato.shakerato.ingredients.1",
            "½ tablespoon simple syrup"
          )}
        </li>
      </ul>

      <strong>
        {t(
          "tour.coffee-recipes.shakerato.sweet-cream.title",
          "For Sweet Cream"
        )}
      </strong>
      <ul>
        <li>
          {t(
            "tour.coffee-recipes.shakerato.sweet-cream.ingredients.0",
            "1 (14 oz) can sweetened condensed milk"
          )}
        </li>
        <li>
          {t(
            "tour.coffee-recipes.shakerato.sweet-cream.ingredients.1",
            "1 cup half &amp; half or whole milk"
          )}
        </li>
      </ul>

      <RecipeTitle>
        <small>{t("tour.coffee-recipes.directions", "DIRECTIONS")}</small>
      </RecipeTitle>
      <p>
        {t(
          "tour.coffee-recipes.shakerato.shakerato.directions.0",
          "Fill cocktail shaker with ice. Add espresso and syrup. Shake vigorously for 20-30 seconds. Strain into a glass. Top with foam. Add sweet cream if desired."
        )}
      </p>

      <br />

      <p>
        <strong>
          {t(
            "tour.coffee-recipes.shakerato.sweet-cream.title",
            "For Sweet Cream"
          )}
        </strong>
      </p>

      <p>
        <Trans i18nKey="tour.coffee-recipes.shakerato.sweet-cream.directions.0">
          <br />
          In a medium saucepan, add condensed milk and half &amp; half or whole
          milk). Over low heat, stir on and off for about 5-10 minutes until
          fully incorporated.
        </Trans>
      </p>

      <p>
        {t(
          "tour.coffee-recipes.shakerato.sweet-cream.directions.1",
          "Remove from heat and let cool. Store in an airtight container and keep refrigerated. Good for up to 2-3 weeks."
        )}
      </p>
    </OverlayArticle>
  );
};
