import React from "react";
import { Vector3 } from "three";
import { Trans, useTranslation } from "react-i18next";

import { ArtworkTag, ArtworkTagPublicProps } from "../../../interactive-tags";

import art from "./art.jpg";

const position = new Vector3(
  -14.602566601260138,
  5.161375233355849,
  -31.450397550584068
);

const sweep = "48bc7aae15fd4d2a83180cccffbc6b70";

export const TommyTaylorArtworkTag = (props: ArtworkTagPublicProps) => {
  const { t } = useTranslation();

  return (
    <ArtworkTag
      title={t("tour.artworks.tommy-taylor.title", "Untitled")}
      author="Tommy Taylor"
      name="tommy-taylor-artwork"
      position={position}
      image={art}
      sweep={sweep}
      {...props}
    >
      <Trans i18nKey="tour.artworks.tommy-taylor.content" t={t}>
        <p>
          This mural, which stretches two stories on the side of the mill
          elevator at the Visitor’s Center, depicts a young man dumping coffee
          cherries from his basket. The man in the mural was inspired by Jose,
          the real-life son of a laborer who works at Hacienda Alsacia. As the
          figure in the mural stands more than 20 feet tall, staff at the farm
          affectionately referred to it as “Giant Jose.”
        </p>
      </Trans>
    </ArtworkTag>
  );
};
