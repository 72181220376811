import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import screenfull from "screenfull";

import { WhiteOutlinedLinkButton } from "src/components";
import { BaseOverlay } from "src/pages/tour/components";

const FullscreenWraper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Text = styled.p`
  max-width: 200px;
  font-size: 16px;
  letter-spacing: 0.5px;
`;

const Btn = styled(WhiteOutlinedLinkButton)`
  margin-top: 40px;
  background-color: ${(props) => props.theme.brand.white};
  color: ${(props) => props.theme.brand.black};
`;

interface Props extends React.HTMLProps<HTMLElement> {
  fullscreenRequested: { (value: boolean): void };
  fullScreen: boolean;
}

export function FullScreen({
  children,
  fullscreenRequested,
  fullScreen,
}: Props) {
  const { t } = useTranslation();

  useEffect(() => {
    const media = "(min-width: 1024px)";
    if (!screenfull.isEnabled || window.matchMedia(media).matches) {
      fullscreenRequested(true);
    }
  }, [fullscreenRequested]);

  return (
    <>
      {children}
      <BaseOverlay
        title={t("landing.privacy.fullscren.title", "Allow fullscreen mode")}
        shown={!fullScreen}
        className="fullscreen"
      >
        {!fullScreen && (
          <FullscreenWraper>
            <Text>
              {t(
                "landing.privacy.fullscren.message",
                "In order to have the best experience you need to grant permission to the browser to go in fullscreen mode."
              )}
            </Text>
            <Btn
              onClick={() => {
                if (screenfull.isEnabled) {
                  screenfull
                    .request()
                    .then(() => {
                      fullscreenRequested(true);
                    })
                    .catch((err: Error) => {
                      console.error(err);
                      fullscreenRequested(false);
                    });
                }
              }}
            >
              {t("landing.privacy.button", "Grant Access")}
            </Btn>
          </FullscreenWraper>
        )}
      </BaseOverlay>
    </>
  );
}
