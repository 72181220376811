import React, { useCallback, useState } from "react";
import { Vector3 } from "three";
import { Trans, useTranslation } from "react-i18next";

import { useCameraLookAt } from "src/hooks";
import { ai } from "src/telemetryService";
import { CustomTag, PulseTag } from "../../../tag";
import { useGuide } from "../../../guide-selection";
import { Alert } from "../../../alerts";
import { NavigateButton } from "../../../navigate-button";

import { position as nextStop } from "../world-of-coffee";

import { CheckinOverlay } from "./overlay";
import useAnalytics from "../../../../../../hooks/use-analytics";

export const location = new Vector3(
  -71.80948193241369,
  2.753634763480665,
  -43.68609634854329
);
export const sweep = "9e74828fc05c4a48917c2a59aa3787a6";

interface CheckInTagProps {
  isVisible?: boolean;
  isActive?: boolean;
  onComplete?: () => void;
  eventName?: string;
}

export const CheckInTag = ({
  isVisible = false,
  isActive = isVisible,
  onComplete,
  eventName,
}: CheckInTagProps) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const [, setGuide] = useGuide();
  const turn = useCameraLookAt();
  const { sendEvent } = useAnalytics();

  const handleSelectionComplete = useCallback(
    async (guide) => {
      setGuide(guide);
      setOpen(false);
      onComplete?.();
      turn(nextStop.clone());
    },
    [setGuide, onComplete, turn]
  );

  const handleNavigateButtonClick = useCallback(() => {
    sendEvent("Experience", "Go To Reception Desk Link - click", null, {});
  }, []);  
  
  const handleTagClick = useCallback(() => setOpen(true), []);

  const handleTagClickSendGA = useCallback(() => {
    sendEvent("Experience", "Welcome to Hacieda Alsacia Tag - click", null, {});
  }, []);

  return (
    <>
      <CustomTag
        isVisible={isVisible}
        location={location}
        src="/imgs/tag.png"
        name="checkin"
        onOpen={() => {
          if (eventName) ai.trackEvent({ name: eventName });
          handleTagClick();
        }}
        onClick={handleTagClickSendGA}
        sweepIdToGo={sweep}
      />

      <PulseTag
        location={location}
        name="checkin"
        isVisible={isVisible && isActive}
      />

      <CheckinOverlay shown={isOpen} onGuideSelect={handleSelectionComplete} />

      <Alert
        icon={<img src="/imgs/tag.png" alt="Main chapter tag" />}
        shown={isOpen || !isActive ? false : undefined}
        delay={5000}
        muted
      >
        <Trans i18nKey="tour.checkin.alert" t={t}>
          Go to the{" "}
          <NavigateButton to={{ sweep, position: location }} onClick={handleNavigateButtonClick}>
            Reception Desk
          </NavigateButton>
          , just inside!
        </Trans>
      </Alert>
    </>
  );
};
