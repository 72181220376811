import React, { useCallback } from "react";
import styled from "styled-components";

import { useGoToTag, GoToTagOptions } from "src/hooks";
import { Button } from "src/components/button";

import { useAudio } from "../audio";

const BaseButton = styled(Button)`
  text-decoration: underline;
  color: inherit;
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
`;

export interface NavigateButtonProps {
  to?: GoToTagOptions;
  children: React.ReactNode;
  onClick?: () => void;
}
export const NavigateButton = ({
  to,
  children,
  onClick,
}: NavigateButtonProps) => {
  const sfx = useAudio();
  const move = useGoToTag();

  const handleClick = useCallback(() => {
    onClick?.();
    sfx.click();
    if (to) {
      move(to);
    }
  }, [move, to, sfx, onClick]);

  return to ? (
    <BaseButton onClick={handleClick}>{children}</BaseButton>
  ) : (
    <span>{children}</span>
  );
};
