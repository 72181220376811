import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { breakpoint } from "src/style";
// import FieldJournalImg from "../imgs/icon.png";
import { FieldJournalDefaultElementType } from "../components";

const IconContainer = styled.div`
  display: flex;
  border-radius: 8px;
  position: absolute;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(4px);
  background: rgba(0, 0, 0, 0.6);

  @media screen and (min-width: ${breakpoint("xs")}) {
    right: 0px;
    width: 50px;
    height: 70px;
    bottom: -30px;
  }

  @media screen and (min-width: ${breakpoint("sm")}) {
    right: 0px;
    width: 80px;
    height: 100px;
    bottom: -25px;
  }

  @media screen and (min-width: ${breakpoint("md")}) {
    right: 0px;
    width: 150px;
    bottom: -50px;
    height: 185px;
  }
`;

const IconImg = styled.img`
  cursor: pointer;
  transition: 0.33s all ease;

  @media screen and (min-width: ${breakpoint("xs")}) {
    width: 35px;
    height: 50px;
  }

  @media screen and (min-width: ${breakpoint("sm")}) {
    width: 55px;
    height: 70px;
  }

  @media screen and (min-width: ${breakpoint("md")}) {
    width: 101.55px;
    height: 142.87px;
  }

  &:hover,
  &:active {
    transform: scale(1.1) translateY(-35px) rotate(10deg);
    box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.25),
      0px 41.7776px 33.4221px rgba(0, 0, 0, 0.179714),
      0px 22.3363px 17.869px rgba(0, 0, 0, 0.149027),
      0px 12.5216px 10.0172px rgba(0, 0, 0, 0.125),
      0px 6.6501px 5.32008px rgba(0, 0, 0, 0.100973),
      0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0702864);
  }
`;

export const Icon: FieldJournalDefaultElementType = ({
  isFieldJournalOverlayOpen,
  onClick,
}) => {
  const { t } = useTranslation();
  const language = t("language-name");

  return isFieldJournalOverlayOpen ? null : (
    <IconContainer
      onClick={() => {
        onClick?.();
      }}
    >
      <IconImg
        alt="Field Journal Icon"
        src={`/field-journal/${language}/field-journal-mini.png`}
      />
    </IconContainer>
  );
};
