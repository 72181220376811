import { SceneObject } from "src/types/sdk";

export interface MpAudioInputs {
  source: string;
}

export class MpAudio extends SceneObject {
  inputs: MpAudioInputs = {
    source: "",
  };

  defaultVolume = 0.75;
  muted = true;
  audio?: THREE.PositionalAudio;
  listener?: THREE.AudioListener;
  object3D?: THREE.Object3D;

  onDestroy() {
    if (this.audio) {
      this.audio.stop();
      this.audio.disconnect();
      delete this.audio;
    }

    this.object3D?.clear();

    if (this.listener) this.context?.camera.remove(this.listener);
  }

  setMuted(isMuted = true) {
    this.muted = isMuted;
    this.audio?.setVolume(isMuted ? 0 : this.defaultVolume);
  }

  setPlaying(isPlaying: boolean) {
    if (isPlaying) this.audio?.play();
    else this.audio?.pause();
  }

  onInit() {
    const THREE = this.context!.three;

    this.listener = new THREE.AudioListener();
    this.context!.camera.add(this.listener);

    this.audio = new THREE.PositionalAudio(this.listener); //user can only hear audio within a certain distance
    this.audio?.setRefDistance(5);
    this.audio?.setMaxDistance(10);

    const loader = new THREE.AudioLoader();
    loader.load(this.inputs.source, (buffer) => {
      this.audio?.setBuffer(buffer);
      this.audio?.setLoop(true);
      this.setMuted(this.muted);
    });

    var source = this.listener.context.createBufferSource();
    source.connect(this.listener.context.destination);
    source.start();

    const object = new THREE.Object3D();
    object.add(this.audio);

    this.outputs!.objectRoot = this.root = object;
  }
}
