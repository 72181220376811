import React, { useCallback } from "react";
import styled from "styled-components";
import { useTranslation, Trans } from "react-i18next";

import { breakpoint } from "src/style";
import { WhiteOutlinedButton } from "src/components";

import { useAudio } from "../../../audio";

import { keys, tags, sweep, mouse, maintag, journal } from "./images";

const HelpContainer = styled.div`
  text-align: center;
  margin: auto;

  @media screen and (orientation: landscape) {
    font-size: 0.8em;
  }

  @media screen and (min-width: ${breakpoint("md")}) {
    font-size: 1em;
  }
`;

const HelpItemsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media screen and (orientation: landscape) {
    flex-direction: row;
    align-items: flex-start;
  }
`;

const HelpItem = styled.div`
  flex: 0 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  text-align: left;

  @media screen and (orientation: landscape) {
    padding: 0;
    flex-direction: column;
    flex: 1 0;
    text-align: center;
  }

  @media screen and (min-width: ${breakpoint(
      "sm"
    )}) and (orientation: portrait) {
    padding: 1em 1em;
  }

  @media screen and (min-width: ${breakpoint("md")}) {
    padding: 1em 2em;
    flex: 0 0 13em;
  }

  > img {
    flex: 0 0 25%;
    margin: 0 auto;
    max-width: 15vw;

    @media screen and (orientation: landscape) {
      max-height: 10vh;
    }

    @media screen and (min-width: ${breakpoint("md")}) {
      max-height: auto;
    }
  }

  > p {
    flex: 0 0 60%;
  }
`;

const SectionTitle = styled.h3`
  letter-spacing: 0.2em;
  font-weight: 600;
  font-size: 1.4em;
  text-transform: uppercase;
  margin-top: 0.75em;

  @media screen and (orientation: landscape) {
    margin-top: 0.25em;
  }

  @media screen and (min-width: ${breakpoint(
      "sm"
    )}) and (orientation: portrait) {
    margin-top: 1.5em;
  }
`;

const ActionButton = styled(WhiteOutlinedButton)`
  margin-top: 1em;

  @media screen and (orientation: landscape) {
    display: none;
  }

  @media screen and (min-width: ${breakpoint("md")}) {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5vh;
  }
`;

export interface HelpContentProps {
  onAction?: () => void;
}

export const HelpContent = ({ onAction }: HelpContentProps) => {
  const { t } = useTranslation();
  const sfx = useAudio();
  const handleAction = useCallback(() => {
    sfx.click();
    onAction?.();
  }, [sfx, onAction]);

  return (
    <HelpContainer>
      <SectionTitle>{t("tour.help.navigate.title", "Navigate")}</SectionTitle>

      <HelpItemsContainer>
        <HelpItem>
          <img alt="A computer mouse" src={mouse} />

          <p>
            <Trans i18nKey="tour.help.navigate.mouse">
              Click and drag to look around
              <br />
            </Trans>
          </p>
        </HelpItem>

        <HelpItem>
          <img alt="Placement indicators" src={sweep} />

          <p>
            <Trans i18nKey="tour.help.navigate.sweeps">
              Click on the rings to move to that spot
              <br />
            </Trans>
          </p>
        </HelpItem>

        <HelpItem>
          <img alt="Keyboard directional keys" src={keys} />

          <p>
            <Trans i18nKey="tour.help.navigate.keyboard">
              Use arrow keys to move through space
              <br />
            </Trans>
          </p>
        </HelpItem>
      </HelpItemsContainer>

      <SectionTitle>{t("tour.help.interact.title", "Interact")}</SectionTitle>

      <HelpItemsContainer>
        <HelpItem>
          <img alt="Main tour Mattertag" src={maintag} />

          <p>
            <Trans i18nKey="tour.help.interact.stops">
              Look for the pulsating circle for your next stop
              <br />
            </Trans>
          </p>
        </HelpItem>

        <HelpItem>
          <img alt="Mattertag interactions" src={tags} />

          <p>
            <Trans i18nKey="tour.help.interact.mattertags">
              Click on a tag to interact with it
              <br />
            </Trans>
          </p>
        </HelpItem>

        <HelpItem>
          <img alt="Field journal illustration" src={journal} />

          <p>
            <Trans i18nKey="tour.help.interact.journal">
              Use the Field Journal to learn even more
              <br />
            </Trans>
          </p>
        </HelpItem>
      </HelpItemsContainer>

      {onAction && (
        <ActionButton onClick={handleAction}>
          <Trans i18nKey="tour.help.button">
            Ok, got it!
            <br />
          </Trans>
        </ActionButton>
      )}
    </HelpContainer>
  );
};
