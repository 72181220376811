import React from "react";
import styled from "styled-components";

const borderRadius = "15px";

const PageContainer = styled.div`
  width: 50%;
  height: 630px;

  img {
    width: 100%;
    height: 630px;
    display: block;

    &.page-left {
      border-top-left-radius: ${borderRadius};
      border-bottom-left-radius: ${borderRadius};
    }

    &.page-right {
      border-top-right-radius: ${borderRadius};
      border-bottom-right-radius: ${borderRadius};
    }
  }
`;

export const Page: React.FC = ({ children }) => {
  return <PageContainer>{children}</PageContainer>;
};
