import {
  ApplicationInsights,
  SeverityLevel,
} from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { version } from "../package.json";

const aiConfig = {
  instrumentationKey: process.env.REACT_APP_INSIGHTS,
  maxBatchInterval: 5000,
  disableFetchTracking: false,
  autoTrackPageVisitTime: true,
};

export default class AI {
  reactPlugin = new ReactPlugin();

  constructor() {
    this.appInsights = new ApplicationInsights({
      config: {
        ...aiConfig,
        extensions: [this.reactPlugin],
      },
    });
  }

  initialize(history) {
    if (!this.initialized) {
      this.appInsights = new ApplicationInsights({
        config: {
          ...aiConfig,
          extensions: [this.reactPlugin],
          extensionConfig: {
            [this.reactPlugin.identifier]: {
              history,
            },
          },
        },
      });

      this.appInsights.loadAppInsights();
      this.appInsights.context.application.ver = version;

      this.initialized = true;
    }
  }

  debug(message, payload) {
    this.trackTrace({
      properties: payload ? payload : {},
      message,
      severityLevel: SeverityLevel.Verbose,
    });

    const prefix = payload ? `[${payload.class}:${payload.function}] ` : "";
    console.debug(`${prefix}${message}`, payload);
  }

  info(message, payload) {
    this.trackTrace({
      properties: payload ? payload : {},
      message,
      severityLevel: SeverityLevel.Information,
    });

    const prefix = payload ? `[${payload.class}:${payload.function}] ` : "";
    console.info(`${prefix}${message}`, payload);
  }

  error(message, payload) {
    this.trackTrace({
      properties: payload ? payload : {},
      message,
      severityLevel: SeverityLevel.Error,
    });

    const prefix = payload ? `[${payload.class}:${payload.function}] ` : "";
    console.error(`${prefix}${message}`, payload);
  }

  warn(message, payload) {
    this.trackTrace({
      properties: payload ? payload : {},
      message,
      severityLevel: SeverityLevel.Warning,
    });

    const prefix = payload ? `[${payload.file}:${payload.function}] ` : "";
    console.error(`${prefix}${message}`, payload);
  }

  trackEvent(event, customProperties) {
    this.initialize();
    this.appInsights.trackEvent(event, customProperties);
  }

  trackTrace(trace, customProperties) {
    this.initialize();
    this.appInsights.trackTrace(trace, customProperties);
  }

  trackException(exception) {
    this.initialize();
    this.appInsights.trackException(exception);
  }
}

export const ai = new AI();
