import React, { Component } from "react";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import AI, { ai } from "../../../telemetryService";
import { withRouter } from "react-router-dom";

/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */
class TelemetryProvider extends Component {
  state = {
    initialized: false,
  };

  componentDidMount() {
    const { history } = this.props;

    ai.initialize(history);
  }

  render() {
    const { children } = this.props;
    return <>{children}</>;
  }
}

export default withRouter(withAITracking(AI.reactPlugin, TelemetryProvider));
