import React, { useCallback } from "react";
import styled from "styled-components";

import { Button } from "src/components";

import { useAudio } from "../../audio";

import { MenuButtonContainer } from "../menu-container";

import { ReactComponent as StarbucksLogo } from "./logo.svg";

export const Logo = styled(StarbucksLogo)`
  width: 100%;
  height: 100%;
  filter: drop-shadow(0px 5px 12px rgba(0, 0, 0, 0.15));
`;

export const StickyButton = styled(Button)`
  padding: 0;
  margin: 0;
  border: 0;
  z-index: 8;
  position: relative;
  font-size: 1.175em;
  width: 2em;
  height: 2em;
`;

export interface ExitMenuProps {
  onClick: () => void;
}

export const ExitMenu = ({ onClick }: ExitMenuProps) => {
  const sfx = useAudio();
  const handleClick = useCallback(() => {
    onClick?.();
    sfx.click();
  }, [onClick, sfx]);

  return (
    <MenuButtonContainer side="left">
      <StickyButton onClick={handleClick}>
        <Logo />
      </StickyButton>
    </MenuButtonContainer>
  );
};
