import React from "react";
import { Vector3 } from "three";
import { Trans, useTranslation } from "react-i18next";

import { ArtworkTag, ArtworkTagPublicProps } from "../../../interactive-tags";

import art from "./art.jpg";

const position = new Vector3(
  -50.83897182995774,
  2.1229194106062788,
  -57.251674392675945
);

const sweep = "d2c33f921c1f4796ab52b766c517c113";

export const IntrospectionTag = (props: ArtworkTagPublicProps) => {
  const { t } = useTranslation();

  return (
    <ArtworkTag
      title={`Introspección (${t(
        "tour.artworks.introspection.title",
        "Introspection"
      )})`}
      author="Jade Rivera"
      name="introspection"
      position={position}
      sweep={sweep}
      image={art}
      {...props}
    >
      <Trans i18nKey="tour.artworks.introspection.content" t={t}>
        <p>
          Artist Jade Rivera spoke with and photographed workers on the farm to
          find inspiration for this mural. One of the workers told him it was a
          job that made him think a lot – that being in contact with nature and
          the countryside sometimes transported him back to where his family
          lives.
        </p>
        <p>
          The universe is depicted is in worker’s hat as well as inside the
          house. The house is a representation of a Starbucks existing in the
          middle of the fauna of the jungle of Costa Rica, and how they
          integrate and coexist in harmony. The coffee tree is an important
          presence symbolizing the way the entire farm project revolves around
          that plant. At harvest time, approximately 500 workers and their
          families arrive to stay on the farm. While parents work in the coffee
          fields of Hacienda Alsacia, the children learn to read and write.
          Seeing children learn while their parents work on the farm is a scene
          that stuck with the artist, who chose to represent that in the mural
          with a stack of books. The Rualdo bird in the mural is part of a
          mythological tale involving the nearby Poaz volcano. Since the
          artist’s work often explores the relationship between humans and
          nature, he decided to paint the bird to represent the strong presence
          of nature on and all around the farm. The artist said the masks in the
          mural have several purposes -- one is to partially hide the face of
          the subject so anyone who views the mural can see themselves in the
          scene, and the other is to represent the “animal side” in every human
          being that only occasionally becomes apparent.
        </p>
      </Trans>
    </ArtworkTag>
  );
};
