import React from "react";
import styled from "styled-components";

import { Icon } from "src/components";

import { IconButton, StyledButton, UIButton } from "../button";
import { MenuButtonContainer } from "../menu-container";

const MenuButton = styled(IconButton)<MenuToggleButtonProps>`
  font-size: 1em;

  &:first-of-type {
    margin-right: 8px;
  }
`;

export const MenuUIButtonStyle = styled(StyledButton)`
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: none;

  &:hover {
    color: black;
    background-color: white;
  }
`;

export const MenuUIButton = styled(UIButton)`
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: none;

  &:hover {
    background-color: white;
  }
`;

interface MenuToggleButtonProps {
  handleMap?: () => void;
  handleMenu?: () => void;
}

type MenuToggleButtonType = React.FC<MenuToggleButtonProps>;

export const MenuToggleButton: MenuToggleButtonType = ({
  handleMap,
  handleMenu,
}) => {
  return (
    <MenuButtonContainer side="right">
      <MenuButton onClick={handleMap}>
        <Icon name="map" />
      </MenuButton>

      <MenuButton onClick={handleMenu}>
        <Icon name="menu" />
      </MenuButton>
    </MenuButtonContainer>
  );
};
