import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import de from "./de.json";
import en from "./en.json";
import es from "./es.json";
import fr from "./fr.json";
import it from "./it.json";
import ja from "./ja.json";
import ko from "./ko.json";
import zh from "./zh.json";
import pt from "./pt.json";

const langs = {
  en,
  es,
  it,
  fr,
  de,
  zh,
  ja,
  ko,
  pt,
};

const resources = Object.entries(langs).reduce(
  (res, [lang, translation]) => Object.assign(res, { [lang]: { translation } }),
  {}
);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    load: "languageOnly",
    detection: {
      order: ["querystring", "navigator"],
    },
    fallbackLng: "en",
    debug: process.env.NODE_ENV !== "production",
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

const a11yKey = "a11y";
const enA11y = "english-a11y";
const frA11y = "franch-a11y";

export default i18n;
export { a11yKey, enA11y, frA11y };
