import React from "react";
import styled from "styled-components";

export const TitleHeading = styled.h2`
  font-size: 1.165em;
  text-transform: uppercase;
  width: 100%;
  margin: 0;
  line-height: 150%;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 1.325em;
    letter-spacing: 0.12em;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3em 5em 0;
  flex: 0 0 5em;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.sm}) {
    padding-top: 0.5em;
  }

  @media screen and (orientation: landscape) {
    padding-top: 0.5em;
  }
`;

export const Title = (props: React.HtmlHTMLAttributes<HTMLHeadingElement>) => {
  return (
    <TitleContainer>
      <TitleHeading {...props} />
    </TitleContainer>
  );
};
