import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { Vector3 } from "three";
import { Trans, useTranslation } from "react-i18next";

import { WhiteOutlinedButton } from "src/components";

import { BaseOverlay } from "../../../ui/overlay";
import { ChapterTag, ChapterTagPublicProps } from "../../../interactive-tags";
import { NavigateButton } from "../../../navigate-button";
import { useAudio } from "../../../audio";
import useAnalytics from "../../../../../../hooks/use-analytics";

import {
  sweep as downloadsSweep,
  position as downloadsPosition,
} from "../../extra/downloads";

import img from "./end-img.png";

export const position = new Vector3(
  -84.33123028784878,
  2.49672959640634,
  -50.147045039838065
);

export const sweep = "ec6446525c794a9aa9f6a473afd6e9dd";

const downloadTarget = {
  sweep: downloadsSweep,
  position: downloadsPosition,
};

const ActionButton = styled(WhiteOutlinedButton)`
  margin-top: 1em;
`;

const Title = styled.h3`
  font-size: 2.25em;
  line-height: 130%;
  margin-bottom: 0;
`;

const Message = styled.p`
  font-size: 1em;
  line-height: 190%;
  max-width: 29em;
  margin: 0.825em auto;
  text-align: center;
`;

const Img = styled.img`
  max-width: 29em;
  margin-bottom: 1.5em;
`;

const Container = styled.div`
  margin: auto;
`;

export const EndingTag = ({ onComplete, ...props }: ChapterTagPublicProps) => {
  const sfx = useAudio();
  const [videoCompleted, setVideoCompleted] = useState(false);
  const { t } = useTranslation();
  const { sendEvent } = useAnalytics();

  const handleVideoComplete = useCallback(() => {
    setVideoCompleted(true);
  }, []);

  const handleComplete = useCallback(() => {
    setVideoCompleted(false);
    onComplete?.();
  }, [onComplete]);

  const handleAction = useCallback(() => {
    sendEvent("Experience", "Ok Got It Completion Button - click", null, {});//GTM-Final got it button after the tour
    sfx.click();
    handleComplete();
  }, [handleComplete, sfx, sendEvent]);

  return (
    <>
      <ChapterTag
        name="ending"
        position={position}
        joshVideo="503104163"
        meliVideo="503104163"
        sweep={sweep}
        onComplete={handleVideoComplete}
        {...props}
      />

      <BaseOverlay shown={videoCompleted} onClose={handleComplete}>
        <Container>
          <Img src={img} alt="illustration" />

          <Title>
            {t("tour.ending.overlay.title", "Thanks for stopping by!")}
          </Title>

          <Message>
            <Trans i18nKey="tour.ending.overlay.message" t={t}>
              You have completed Hacienda Alsacia’s virtual tour.
              <br />
              Feel free to explore other areas and tags around the farm.
              <br />
              Hey, don’t forget to take a{" "}
              <NavigateButton onClick={handleComplete} to={downloadTarget}>
                souvenir
              </NavigateButton>
              !
            </Trans>
          </Message>

          <ActionButton onClick={handleAction}>
            {t("tour.ending.overlay.action", "Ok, got it!")}
          </ActionButton>
        </Container>
      </BaseOverlay>
    </>
  );
};
