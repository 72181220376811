import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import VimeoPlayer, {
  Options as VimeoOptions,
  EventCallback as VimeoEventCallback,
} from "@vimeo/player";

type ExtendedProps = React.HtmlHTMLAttributes<HTMLIFrameElement> &
  Pick<
    VimeoOptions,
    | "autoplay"
    | "autopause"
    | "background"
    | "loop"
    | "byline"
    | "muted"
    | "responsive"
    | "color"
    | "controls"
    | "portrait"
    | "texttrack"
  >;

export interface VimeoComponentProps extends ExtendedProps {
  videoId: string;
  videoTitle?: VimeoOptions["title"];
  borderWidth?: boolean;
  play?: boolean;
  onPlay?: VimeoEventCallback;
  onStop?: VimeoEventCallback;
  onEnded?: VimeoEventCallback;
  onPlayerLoad?: (sdk: VimeoPlayer) => void;
}

const boolToNum = (bool?: boolean): number => (bool ? 1 : 0);

export const VimeoVideo: React.FC<VimeoComponentProps> = ({
  videoId,
  background,
  autoplay,
  loop,
  byline,
  videoTitle: title,
  title: iframeTitle = `Vimeo Video ${videoId}`,
  muted,
  play,
  autopause,
  responsive,
  borderWidth,
  controls,
  texttrack,
  onPlayerLoad,
  onEnded,
  onPlay,
  onStop,
  portrait,
  ...props
}) => {
  const {
    i18n: { language: currentLanguage },
  } = useTranslation();
  const [url, setUrl] = useState<string>();
  const [isPlaying, setIsPlaying] = useState(false);
  const [iframeElement, setIframeElement] = React.useState<HTMLIFrameElement>();
  const [playerRef, setPlayerRef] = React.useState<VimeoPlayer>();
  const iframeRef = useCallback((element: HTMLIFrameElement) => {
    if (!element) {
      return;
    }
    setIframeElement(element);
  }, []);

  useEffect(() => {
    const videoParams = Object.entries({
      background,
      loop,
      byline,
      title,
      autopause,
      responsive,
      controls,
      borderWidth,
      portrait,
    }).reduce((params, [prop, val]) => {
      if (typeof val === "boolean") params.set(prop, boolToNum(val).toString());

      return params;
    }, new URLSearchParams());

    const cc = texttrack || currentLanguage;
    videoParams.set("texttrack", cc);

    setUrl(
      `https://player.vimeo.com/video/${videoId}?${videoParams.toString()}`
    );
  }, [
    videoId,
    background,
    autoplay,
    loop,
    byline,
    title,
    autopause,
    responsive,
    controls,
    borderWidth,
    portrait,
    texttrack,
    currentLanguage,
  ]);

  useEffect(() => {
    if (!url || !iframeElement) return;
    const player = new VimeoPlayer(iframeElement as HTMLIFrameElement);

    setPlayerRef(player);

    const playerLoaded = () => {
      if (play || autoplay || background) {
        player
          .play()
          .then(() => setIsPlaying(true))
          .catch(() => setIsPlaying(false));
      }
      onPlayerLoad?.(player);
    };
    player.on("load", playerLoaded);
  }, [onPlayerLoad, url, iframeElement, autoplay, background, play]);

  useEffect(() => {
    if (!iframeElement || !playerRef) return;

    playerRef.setMuted(!!muted);

    if (typeof play === "boolean") {
      if (play) playerRef.play();
      else playerRef.pause();
    }
  }, [muted, play, iframeElement, playerRef]);

  useEffect(() => {
    if (!iframeElement || !playerRef) return;

    const unregisterCallbacks: [string, VimeoEventCallback][] = [];
    const registerEvent = (name: string, callback?: VimeoEventCallback) => {
      if (callback) {
        playerRef!.on(name, callback);
        unregisterCallbacks.push([name, callback]);
      }
    };

    registerEvent("ended", onEnded);
    registerEvent("play", onPlay);
    registerEvent("stop", onStop);

    return () => {
      unregisterCallbacks.forEach(([ev, callback]) =>
        playerRef!.off(ev, callback)
      );
    };
  }, [onEnded, onPlay, onStop, iframeElement, playerRef]);

  useEffect(() => {
    if (!playerRef || !iframeElement) return;

    const fn = () => {
      if (!isPlaying) {
        if (play || autoplay || background) {
          playerRef
            .play()
            .then(() => setIsPlaying(true))
            .catch(() => setIsPlaying(false));
        }
      }
    };

    document.addEventListener("click", fn);
    document.addEventListener("touchstart", fn);
    return () => {
      document.removeEventListener("click", fn);
      document.addEventListener("touchstart", fn);
    };
  }, [playerRef, autoplay, background, iframeElement, isPlaying, play]);

  return (
    <iframe
      title={iframeTitle}
      ref={iframeRef}
      src={url}
      frameBorder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowFullScreen
      {...props}
    />
  );
};
