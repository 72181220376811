import React, { useCallback, useState } from "react";

import { HelpOverlay } from "./help";
import { MenuOverlay } from "./overlay";
import { MapOverlay } from "./map-overlay";
import { MenuToggleButton } from "./button";
import { TagMap } from "src/pages/tour/state";
import useAnalytics from "../../../../../hooks/use-analytics";
interface MenuProps {
  progress: TagMap;
  autoDisplayHelp?: boolean;
}

export const Menu = ({ progress, autoDisplayHelp = false }: MenuProps) => {
  const [isMapShown, showMap] = useState(false);
  const [isMenuShown, showMenu] = useState(false);
  const [isHelpShown, showHelp] = useState(autoDisplayHelp);

  const { sendEvent } = useAnalytics();

  const handleMapOpen = useCallback(() => {
    sendEvent("Experience", `Farm Map Link - click`, null, {});//GTM-Farm Map Link
    showMap(true)}, []);
  const handleMapClose = useCallback(() => showMap(false), []);
  const handleMenuOpen = useCallback(() => {
    sendEvent("Experience", `Settings Link - click`, null, {});//GTM-Settings Link
    showMenu(true)}, []);
  const handleMenuClose = useCallback(() => showMenu(false), []);
  const handleHelpOpen = useCallback(() => showHelp(true), []);
  const handleHelpClose = useCallback(() => showHelp(false), []);

  //ManuOverlay and MapOverlay - manu and map options at the top right corner
  //ManuOverlay : option to change language, sound
  return (
    <>
      <MenuToggleButton handleMap={handleMapOpen} handleMenu={handleMenuOpen} />

      <MenuOverlay
        shown={isMenuShown}
        onHelp={handleHelpOpen}
        onClose={handleMenuClose}
      />

      <MapOverlay
        shown={isMapShown}
        onClose={handleMapClose}
        progress={progress}
      />

      <HelpOverlay shown={isHelpShown} onAction={handleHelpClose} />
    </>
  );
};
