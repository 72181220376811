import { createContext } from "react";
export { GuideSelector, IdleVideo, HoverVideo } from "./selector";
export { useGuide } from "./hook";
export { GuideProvider } from "./provider";

export type GuideContextValue = [symbol | null, (guide: symbol) => void];

export const GuideContext = createContext<GuideContextValue>([null, () => {}]);

export const Josh = Symbol.for("Josh");
export const Meli = Symbol.for("Meli");

export type Guide = typeof Josh | typeof Meli;
