import React from "react";
import { Vector3 } from "three";

import { ChapterTag, ChapterTagPublicProps } from "../../interactive-tags";

export const position = new Vector3(
  -16.21899988600939,
  7.786251252629112,
  -22.614328931529606
);
export const sweep = "26312527251749de9542b1004fc6e5a5";

export const ProcessingTag = (props: ChapterTagPublicProps) => {
  return (
    <ChapterTag
      name="processing"
      position={position}
      joshVideo="502304378"
      meliVideo="502303386"
      sweep={sweep}
      {...props}
    />
  );
};
