import { useCallback, useEffect, useRef, useState } from "react";
import { INTERACTION, SDK, AppState, APP_PHASE } from "../types/sdk";

interface IframeWindow {
  MP_SDK?: {
    connect: (
      el: HTMLIFrameElement,
      key: string,
      version: string
    ) => Promise<SDK>;
  };
}

//connect to Matterport
export function useConnectMatterportSDK(): [
  SDK | undefined,
  (v: HTMLIFrameElement | null) => void
] {
  const [sdk, setSdk] = useState<SDK | undefined>(undefined);
  const iframeElementRef = useRef<HTMLElement>();
  const getIframeRef = useCallback((el: HTMLIFrameElement | null) => {
    el?.addEventListener("load", sdkConnect.current(el));
  }, []);
  const sdkConnect = useRef((el: HTMLIFrameElement) => async () => {
    try {
      const {
        REACT_APP_MATTERPORT_SDK_KEY = "invalid_key",
        REACT_APP_MATTERPORT_SDK_VERSION = "invalid_version",
      } = process.env;
      const sdkReference = await ((el.contentWindow as unknown) as IframeWindow).MP_SDK?.connect(
        el,
        REACT_APP_MATTERPORT_SDK_KEY,
        REACT_APP_MATTERPORT_SDK_VERSION
      );

      iframeElementRef.current = el.contentDocument?.documentElement;
      const removeMatteportDefaultUi = (
        iframeElment: HTMLElement | undefined
      ) => () => {
        if (iframeElment) {
          const bottomUi = iframeElment.querySelector("#bottom-ui");
          bottomUi?.remove();
        }

        setTimeout(removeMatteportDefaultUi(iframeElementRef.current), 500);
      };
      setTimeout(removeMatteportDefaultUi(iframeElementRef.current), 500);

      setSdk(sdkReference);
      (window as any).MPInstance = sdkReference;
    } catch (error) {
      console.error(error);
    }
  });

  useEffect(() => {
    const fn = async (): Promise<{ (): void } | undefined> => {
      if (sdk) {
        //Add light to the scene
        const lights = await sdk?.Scene.createNode();
        lights?.addComponent("mp.ambientLight", {
          color: {
            r: 1,
            g: 1,
            b: 1,
          },
          intensity: 1.3,
        });
        lights?.start();

        const subscription = sdk.App.state.subscribe(({ phase }: AppState) => {
          if (process.env.NODE_ENV !== "production") {
            console.info(`App phase = ${phase}`);
          }

          if (phase === APP_PHASE.PLAYING) {
            sdk?.Scene.createNode().then((input) => {
              const inputComponent = input?.addComponent("mp.input", {
                eventsEnabled: true,
              });
              inputComponent.spyOnEvent(new SpyOnClick());
              input?.start();
            });

            sdk?.on("sweep.enter", (tagId) => {
              if (process.env.NODE_ENV !== "production") {
                console.log(
                  `%c SWEEP-ID = ${tagId}`,
                  "background-color: #0f0; color: #000;"
                );
              }
            });

            sdk?.Pointer.intersection.subscribe(function ({ object }) {
              if (iframeElementRef.current) {
                if (object === "intersectedobject.unknown") {
                  iframeElementRef.current.style.cursor = "pointer";
                } else {
                  iframeElementRef.current.style.cursor = "initial";
                }
              }
            });
          }
        });

        return subscription.cancel;
      }
    };

    let unsubscribeState: { (): void } | undefined;
    fn().then((i) => {
      if (i != null) {
        unsubscribeState = i as { (): void };
      }
    });

    if (unsubscribeState != null) return unsubscribeState;
  }, [sdk]);

  return [sdk, getIframeRef];
}

class SpyOnClick {
  eventType = INTERACTION.CLICK;

  onEvent(ev: { position: { x: number; y: number; z: number } }) {
    if (process.env.NODE_ENV !== "production") {
      console.log(
        `%c CLICKED AT -- x: ${ev.position.x}, y: ${ev.position.y}`,
        "background-color: #000000; color: #ff0000;"
      );
      console.log(
        `%c EVENT -- ${JSON.stringify(ev)}`,
        "background-color: #ffff00; color: #000;"
      );
    }
  }
}
