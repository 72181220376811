import React from "react";
import styled from "styled-components";
import { useTranslation, Trans } from "react-i18next";

import { OverlayArticle } from "../../../../../ui/overlay";
import { Title } from "src/pages/tour/components/ui/overlay/content/article";

import image from "./americano.jpg";

const RecipeTitle = styled(Title)`
  small {
    opacity: 1;
    font-size: 0.835em;
  }
`;

export const AmericanoConCremaRecipe = () => {
  const { t } = useTranslation();

  return (
    <OverlayArticle
      image={image}
      title={t(
        "tour.coffee-recipes.americano-crema.title",
        "Americano con Crema"
      )}
      subtitle={t("tour.coffee-recipes.americano-crema.subtitle", "Serves 1")}
    >
      <RecipeTitle>
        <small>{t("tour.coffee-recipes.ingredients", "INGREDIENTS")}</small>
      </RecipeTitle>

      <strong>
        {t(
          "tour.coffee-recipes.americano-crema.orange-syrup.title",
          "For Orange Syrup"
        )}
      </strong>
      <ul>
        <li>
          {t(
            "tour.coffee-recipes.americano-crema.orange-syrup.ingredients.0",
            "½ cup water"
          )}
        </li>
        <li>
          {t(
            "tour.coffee-recipes.americano-crema.orange-syrup.ingredients.1",
            "½ cup sugar"
          )}
        </li>
        <li>
          {t(
            "tour.coffee-recipes.americano-crema.orange-syrup.ingredients.2",
            "Peel (or zest) from half an orange"
          )}
        </li>
      </ul>

      <strong>
        {t(
          "tour.coffee-recipes.americano-crema.americano-crema.title",
          "For Americano con Crema"
        )}
      </strong>
      <ul>
        <li>
          {t(
            "tour.coffee-recipes.americano-crema.americano-crema.ingredients.0",
            "½ cup 2% milk"
          )}
        </li>
        <li>
          {t(
            "tour.coffee-recipes.americano-crema.americano-crema.ingredients.1",
            "1 tablespoon orange syrup"
          )}
        </li>
        <li>
          {t(
            "tour.coffee-recipes.americano-crema.americano-crema.ingredients.2",
            "4oz espresso (we recommend Starbucks Expresso Roast)"
          )}
        </li>
        <li>
          {t(
            "tour.coffee-recipes.americano-crema.americano-crema.ingredients.3",
            "2oz hot water"
          )}
        </li>
        <li>
          {t(
            "tour.coffee-recipes.americano-crema.americano-crema.ingredients.4",
            "Demerara sugar - optional"
          )}
        </li>
      </ul>

      <RecipeTitle>
        <small>{t("tour.coffee-recipes.directions", "DIRECTIONS")}</small>
      </RecipeTitle>
      <p>
        {t(
          "tour.coffee-recipes.americano-crema.directions.0",
          "Make the orange syrup first. In a medium saucepan over medium-high heat, add water, sugar and orange peel. Stir occasionally for 5 minutes or until sugar has dissolved. Remove from heat and let cool. Strain into a container."
        )}
      </p>

      <p>
        <Trans i18nKey="tour.coffee-recipes.americano-crema.directions.1">
          <br />
          Next, make the Americano con Crema. Froth the milk in a blender on
          high for two minutes, then let it sit for a minute or two.
        </Trans>
      </p>

      <p>
        {t(
          "tour.coffee-recipes.americano-crema.directions.2",
          "Add the orange syrup, espresso, and 2 ounces of hot water to a mug and stir."
        )}
      </p>

      <p>
        {t(
          "tour.coffee-recipes.americano-crema.directions.3",
          "Top the beverage with a few spoonfuls of milk froth, sprinkle with sugar if desired and enjoy."
        )}
      </p>
    </OverlayArticle>
  );
};
