import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

  html, body, #root {
    overflow-x: hidden;
  }

  body {
    margin: 0;
    font-family: ${(props) => props.theme.fonts.main};
    font-size: 18px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @media screen and (max-width: 768px) {
      font-size: 14px
    }

    @media screen and (max-width: 480px) {
      font-size: 12px
    }
  }

  code {
    font-family: ${(props) => props.theme.fonts.mono};
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0 0 .45em;

    small {
      display: inline-block;
      width: 100%;
      margin-bottom: 1em;
      letter-spacing: 0.12em;
    }
  }

  h1 {
    font-size: 3.55em;

    small {
      font-size: .375em;
    }
  }

  h2 {
    font-size: 3em;
    font-weight: 600;

    small {
      font-size: .37em;
    }
  }

  h3 {
    font-size: 2.7em;
    font-weight: 400;
  }

  h4 {
    font-size: 1.8em;
    font-weight: 600;
  }

  h5 {
    font-size: 1.3em;
    font-weight: 600;
  }

  h6 {
    font-size: 1.1em;
    font-weight: 600;
  }

  small, em {
    font-family: ${(props) => props.theme.fonts.emphasis};
    text-transform: uppercase;
    font-size: 0.77em;
    letter-spacing: .3em;
    font-weight: 400;
  }

  caption {
    text-transform: uppercase;
    font-size: 12px;
  }

  @keyframes fourth {
    70% {
      transform: rotateX(90deg);
      opacity: 0;
    }
    75% {
      transform: rotateX(0deg);
      opacity: 1;
    }
    95% {
      transform: rotateX(0deg);
      opacity: 1;
    }
    100% {
      transform: rotateX(90deg);
      opacity: 0;
    }
  }

  @keyframes third {
    45% {
      transform: rotateX(90deg);
      opacity: 0;
    }
    50% {
      transform: rotateX(0deg);
      opacity: 1;
    }
    70% {
      transform: rotateX(0deg);
      opacity: 1;
    }
    75% {
      transform: rotateX(90deg);
      opacity: 0;
    }
  }

  @keyframes second {
    20% {
      transform: rotateX(90deg);
      opacity: 0;
    }
    25% {
      transform: rotateX(0deg);
      opacity: 1;
    }
    45% {
      transform: rotateX(0deg);
      opacity: 1;
    }
    50% {
      transform: rotateX(90deg);
      opacity: 0;
    }
  }

  @keyframes first {
    20% {
      transform: rotateX(0deg);
      opacity: 1;
    }
    25% {
      transform: rotateX(90deg);
      opacity: 0;
    }

    95% {
      transform: rotateX(90deg);
      opacity: 0;
    }
    100% {
      transform: rotateX(0deg);
      opacity: 1;
    }
  }

  .flip-page.page-14 {
    .art-box {
      &-1 {
        z-index: 1;
      }

      &-2 {
        z-index: 0;
      }
    }
  }
`;
