export declare type listnnerCallback = (sdk: any) => void

export class Observable {
    static currentId = 0
    listenners: Array<{ id: number, cb: listnnerCallback }> = []
    lastKnownValue: any = null

    subscribe(cb: listnnerCallback) {
        const id = Observable.currentId++
        this.listenners.push({ id, cb })
        cb(this.lastKnownValue)
        return this.remove(id)
    }

    remove(id: number) {
        return () => {
            this.listenners = this.listenners.filter(i => i.id !== id)
        }
    }

    update(sdk: any) {
        this.lastKnownValue = sdk
        this.listenners.forEach(i => {
            i.cb(sdk)
        })
    }
}