import styled from "styled-components";

export const Button = styled.button`
  padding: 1em 2em;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, opacity 0.2s;
  border-radius: 80px;
  font-weight: 400;
  font-size: 16px;
  border: 2px solid transparent;
  cursor: pointer;
  background-color: transparent;
  font-family: inherit;
  text-decoration: none;
  outline: 0;

  &:active {
    opacity: 0.8;
  }
`;

export const LinkButton = styled.a`
  padding: 1em 2em;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, opacity 0.2s;
  border-radius: 80px;
  font-weight: 400;
  font-size: 16px;
  border: 2px solid transparent;
  cursor: pointer;
  background-color: transparent;
  font-family: inherit;
  text-decoration: none;
  outline: 0;

  &:active {
    opacity: 0.8;
  }
`;

export const WhiteOutlinedLinkButton = styled(LinkButton)`
  background-color: transparent;
  color: ${(props) => props.theme.brand.white};
  border-color: ${(props) => props.theme.brand.white};

  &:hover {
    background-color: ${(props) => props.theme.brand.white};
    color: ${(props) => props.theme.brand.accentGreen};
  }
`;

export const WhiteOutlinedButton = styled(Button)`
  background-color: transparent;
  color: ${(props) => props.theme.brand.white};
  border-color: ${(props) => props.theme.brand.white};

  &:hover {
    background-color: ${(props) => props.theme.brand.white};
    color: ${(props) => props.theme.brand.accentGreen};
  }
`;

export const BlackOutlinedButton = styled(Button)`
  background-color: transparent;
  color: ${(props) => props.theme.brand.black};
  border-color: ${(props) => props.theme.brand.black};

  &:hover {
    background-color: ${(props) => props.theme.brand.black};
    color: ${(props) => props.theme.brand.white};
  }
`;

export const GreenOutlinedButton = styled(Button)`
  background-color: transparent;
  color: ${(props) => props.theme.brand.accentGreen};
  border-color: ${(props) => props.theme.brand.accentGreen};

  &:hover {
    background-color: ${(props) => props.theme.brand.accentGreen};
    color: ${(props) => props.theme.brand.white};
  }
`;

export const PrimarySolidButton = styled(Button)`
  background-color: ${(props) => props.theme.brand.white};
  color: ${(props) => props.theme.brand.accentGreen};
  border-color: ${(props) => props.theme.brand.white};

  &:hover {
    background-color: ${(props) => props.theme.brand.accentGreen};
    border-color: ${(props) => props.theme.brand.accentGreen};
    color: ${(props) => props.theme.brand.white};
  }
`;
