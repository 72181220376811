import styled from "styled-components";

export const Section = styled.div`
  margin: 1em 0;
  flex: 1 1 50%;
  box-sizing: border-box;

  @media screen and (orientation: landscape) {
    margin: 0;
    flex: 0 1 50%;
  }
`;

export const SubSection = styled(Section)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0;
  flex: 1 0;
`;

export const DividedSection = styled.div`
  display: flex;
  flex: 1 1 100%;
  justify-content: space-evenly;

  > ${Section} {
    width: 100%;
  }
`;
