import { Euler, Vector3 } from "three";

import { browser } from "src/helpers";
import { SceneObject } from "src/types/sdk";

export interface MpVideoInputs {
  source: string;
  rotation: Euler;
  opacity: number;
  geometry: {
    w: number;
    h: number;
  };
  audioThreshold?: {
    min: number;
    max: number;
    atenuation: number;
    // The effective position of audio to calculate distance
    position?: Vector3;
  };
}

export class MpVideo extends SceneObject {
  inputs: MpVideoInputs = {
    source: "",
    opacity: 1,
    rotation: new Euler(),
    geometry: { w: 1, h: 1 },
    audioThreshold: undefined,
  };

  private mute: boolean = false;
  private video?: HTMLVideoElement;

  private geometry?: THREE.PlaneGeometry;
  private texture?: THREE.Texture;
  private cameraPositionBuffer?: THREE.Vector3;

  async initVideo() {
    this.video = document.createElement("video");
    this.video.playsInline = true;
    this.video.src = this.inputs.source;
    this.video.autoplay = true;
    this.video.loop = true;
    this.video.volume = 0;
    this.video.muted = !(await browser.mediaVolumeIsSettable);
    this.video.play().catch(() => {});
  }

  onDestroy() {
    this.video?.pause();
    delete this.video;

    this.root?.clear();
    this.geometry?.dispose();
    this.texture?.dispose();
  }

  setMute(isMuted: boolean = true) {
    this.mute = isMuted;
  }

  setPaused(isPaused: boolean = true) {
    if (isPaused) {
      this.video?.pause();
    } else {
      this.video?.play().catch(() => {});
    }
  }

  async onInit() {
    await this.initVideo();

    const THREE = this.context!.three;

    this.cameraPositionBuffer = new THREE.Vector3();

    this.geometry = new THREE.PlaneGeometry(
      this.inputs.geometry.w,
      this.inputs.geometry.h
    );

    this.texture = new THREE.VideoTexture(this.video!);
    this.texture.format = THREE.RGBAFormat;

    const material = new THREE.MeshLambertMaterial({
      map: this.texture,
      transparent: true,
      opacity: this.inputs.opacity,
    });

    const mesh = new THREE.Mesh(this.geometry, material);

    const object = new THREE.Object3D();
    object.rotation.copy(this.inputs.rotation);
    object.add(mesh);

    this.outputs!.objectRoot = this.root = object;
  }

  onTick() {
    if (
      !this.context?.three ||
      !this.context?.camera ||
      !this.root ||
      !this.inputs.audioThreshold ||
      !this.video
    )
      return;

    if (this.mute && this.video.volume > 0) {
      this.video.volume = 0;
      return;
    }

    this.context!.camera.getWorldPosition(this.cameraPositionBuffer!);

    const objectPosition =
      this.inputs.audioThreshold?.position || this.root!.position;

    const distance = objectPosition.distanceTo(this.cameraPositionBuffer!);
    const atenuation = Math.min(
      Math.max(0, 1 - this.inputs.audioThreshold.atenuation),
      1
    );

    const volume =
      (distance - this.inputs.audioThreshold.max) /
      (this.inputs.audioThreshold.min - this.inputs.audioThreshold.max);

    const resolvedVolume = Math.max(Math.min(volume, 1), 0) * atenuation;

    this.video.volume = resolvedVolume;
  }
}
