import React from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import { ai } from "src/telemetryService";
import { Page } from "./page";
import MiniArt from "../imgs/mini-art.jpg";
import Blank02 from "../imgs/blank-02.jpg";
import { ChapterLink } from "./chapter-link";
import { FieldJournalProgressType } from ".";
import MiniDownload from "../imgs/mini-download.jpg";
import {
  DryingChapter,
  HarvestChapter,
  NurseryChapter,
  ResearchChapter,
  RoastingChapter,
  VarietalsChapter,
  ProcessingChapter,
  WorldOfCoffeeChapter,
  CentralAmericaChapter,
} from "src/pages/tour/state";
import useAnalytics from "../../../../../hooks/use-analytics";

interface JournalIndexProps {
  language?: string;
}

const JournalIndexContainer = styled.div<JournalIndexProps>`
  display: flex;
  align-items: center;
  background: transpartent;

  .journal-index-content {
    display: flex;
    flex-direction: column;
    width: calc(100% - 50px);
    height: calc(630px - 85px);
    padding: 50px 25px 35px 25px;
    background-image: url(${Blank02});
    background-size: contain;

    .intro {
      margin: 0;
      font-size: 14px;
      text-align: left;
      line-height: 170%;
      font-style: normal;
      font-weight: normal;
      font-family: "SoDo Sans";
    }

    .title {
      font-size: 24px;
      text-align: left;
      font-weight: 600;
      line-height: 110%;
      font-style: normal;
      margin: 15px 0 23px 0;
      font-family: "Lander Grande", "SoDo Sans";
    }

    .chapters-links {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: flex-start;
    }

    .other-links {
      display: flex;
      margin: 15px 0 0 0;
      align-items: center;
      justify-content: space-between;

      .box {
        opacity: 1;
        padding: 8px;
        display: flex;
        cursor: pointer;
        color: #000000;
        align-items: center;
        background: #ffffff;
        text-decoration: none;
        width: calc(184px - 10px);
        border-radius: 3px !important;
        justify-content: space-between;
        transition: 0.33s all ease-in-out;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);

        ${({ language }) =>
          language === "italian" &&
          css`
            height: 85px;
            padding: 0 8px;
          `}

        &:first-child {
          margin-right: 10px;
        }

        &:hover {
          img {
            transform: scale(1.2) rotate(-10deg) translateY(-15px);
          }

          .box-wrapper {
            .box-title {
              color: #d2175a;
            }
          }
        }

        img {
          width: 56px;
          height: 56px;
          margin-right: 12px;
          border-radius: 2px;
          transition: 0.5s all ease-in-out;

          ${({ language }) =>
            language === "italian" &&
            css`
              width: 64px;
              height: 64px;
              margin-right: 8px;
            `}
        }

        .box-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .box-title {
            font-size: 15px;
            text-align: left;
            line-height: 24px;
            margin: 0 0 5px 0;
            font-style: normal;
            font-weight: normal;
            font-family: "SoDo Sans";
            transition: 0.5s all ease-in-out;

            ${({ language }) =>
              language === "italian" &&
              css`
                font-size: 10px;
                margin: 0 0 7px 0;
                line-height: 16px;
              `}
          }

          p {
            margin: 0;
            font-size: 9px;
            text-align: left;
            color: #666666;
            line-height: 10px;
            font-style: normal;
            font-weight: normal;
            font-family: "SoDo Sans";
          }
        }
      }

      .locked-box {
        opacity: 0.5;
        cursor: not-allowed;

        img {
          filter: grayscale(100%);
        }

        .box-wrapper {
          .box-title {
            color: #666666;
          }
        }

        &:hover {
          img {
            transform: none;
          }

          .box-wrapper {
            .box-title {
              color: #666666;
            }
          }
        }
      }
    }
  }
`;

export const JournalIndex: FieldJournalProgressType = ({
  goToPage,
  progress,
}) => {
  const { t } = useTranslation();
  const language = t("language-name");
  const { sendEvent } = useAnalytics();
  
  const extraContentData = (
    <>
      <img src={MiniArt} alt={t(`tour.field-journal.link-boxes.extra.title`)} />

      <div className="box-wrapper">
        <h3 className="box-title">
          {t(`tour.field-journal.link-boxes.extra.title`)}
        </h3>
        <p>{t(`tour.field-journal.link-boxes.extra.description`)}</p>
      </div>
    </>
  );

  const downloadContentData = (
    <>
      <img
        src={MiniDownload}
        alt={t(`tour.field-journal.link-boxes.download.title`)}
      />

      <div className="box-wrapper">
        <h3 className="box-title">
          {t(`tour.field-journal.link-boxes.download.title`)}
        </h3>
        <p>{t(`tour.field-journal.link-boxes.download.description`)}</p>
      </div>
    </>
  );

  return (
    <JournalIndexContainer language={language}>
      <Page>
        <img
          alt="Hacienda"
          className="page-right"
          src={`/field-journal/${language}/00/01.jpg`}
        />
      </Page>

      <Page>
        <div className="journal-index-content">
          <p className="intro">{t(`tour.field-journal.index.intro`)}</p>

          <h2 className="title">{t(`tour.field-journal.index.title`)}</h2>

          <div className="chapters-links">
            <ChapterLink
              number="1"
              title="The World of Coffee"
              unlocked={progress?.[WorldOfCoffeeChapter]}
              onClick={() =>{
                sendEvent("Journey", `The World of Coffee Link - click`, null, {});//GTM-tag in journey
                goToPage?.(progress?.[WorldOfCoffeeChapter] ? 1 : 0)}
              }
            />

            <ChapterLink
              number="2"
              title="Coffee in Costa Rica"
              unlocked={progress?.[CentralAmericaChapter]}
              onClick={() =>{
                sendEvent("Journey", `Coffee in Costa Rica Link - click`, null, {});//GTM-tag in journey
                goToPage?.(progress?.[CentralAmericaChapter] ? 2 : 0)}
              }
            />

            <ChapterLink
              number="3"
              title="Nursery"
              unlocked={progress?.[NurseryChapter]}
              onClick={() => 
                {
                  sendEvent("Journey", `Nursery Link - click`, null, {});//GTM-tag in journey
                  goToPage?.(progress?.[NurseryChapter] ? 3 : 0)}
                }
            />

            <ChapterLink
              number="4"
              title="Coffee Varietals"
              unlocked={progress?.[VarietalsChapter]}
              onClick={() => 
                {
                  sendEvent("Journey", `Coffee Varietals Link - click`, null, {});//GTM-tag in journey
                  goToPage?.(progress?.[VarietalsChapter] ? 4 : 0)}
                }
            />

            <ChapterLink
              number="5"
              title="Harvesting"
              unlocked={progress?.[HarvestChapter]}
              onClick={() =>{
                sendEvent("Journey", `Harvesting Link - click`, null, {});//GTM-tag in journey
                goToPage?.(progress?.[WorldOfCoffeeChapter] ? 5 : 0)}
              }
            />

            <ChapterLink
              number="6"
              title="Processing"
              unlocked={progress?.[ProcessingChapter]}
              onClick={() => {
                sendEvent("Journey", `Processing Link - click`, null, {});//GTM-tag in journey
                goToPage?.(progress?.[ProcessingChapter] ? 6 : 0)}
              }
            />

            <ChapterLink
              number="7.1"
              title="Drying"
              unlocked={progress?.[DryingChapter]}
              onClick={() => {
                sendEvent("Journey", `Drying Link - click`, null, {});//GTM-tag in journey
                goToPage?.(progress?.[DryingChapter] ? 7 : 0)}
              }
            />

            <ChapterLink
              number="7.2"
              title="Quality Sorting"
              unlocked={progress?.[DryingChapter]}
              onClick={() => {
                sendEvent("Journey", `Quality Sorting Link - click`, null, {});//GTM-tag in journey
                goToPage?.(progress?.[DryingChapter] ? 8 : 0)}
              }
            />

            <ChapterLink
              number="8"
              title="Greenhouse"
              unlocked={progress?.[ResearchChapter]}
              onClick={() => {
                sendEvent("Journey", `Greenhouse Link - click`, null, {});//GTM-tag in journey
                goToPage?.(progress?.[ResearchChapter] ? 9 : 0)}
              }
            />

            <ChapterLink
              number="9.1"
              title="Roasting"
              unlocked={progress?.[RoastingChapter]}
              onClick={() => {
                sendEvent("Journey", `Roasting Link - click`, null, {});//GTM-tag in journey
                goToPage?.(progress?.[RoastingChapter] ? 10 : 0)}
              }
            />

            <ChapterLink
              number="9.2"
              title="Ethically Sourced"
              unlocked={progress?.[RoastingChapter]}
              onClick={() => {
                sendEvent("Journey", `Ethically Sourced Link - click`, null, {});//GTM-tag in journey
                goToPage?.(progress?.[RoastingChapter] ? 11 : 0)}
              }
            />

            <ChapterLink
              number="9.3"
              title="Sustainable Future"
              unlocked={progress?.[RoastingChapter]}
              onClick={() => {
                sendEvent("Journey", `Sustainable Future Link - click`, null, {});//GTM-tag in journey
                goToPage?.(progress?.[RoastingChapter] ? 12 : 0)}
              }
            />
          </div>

          <div className="other-links">
            {progress?.[RoastingChapter] ? (
              <div className="box extra-content" onClick={() => {
                sendEvent("Journey", `extra-content Link - click`, null, {});//GTM-tag in journey
                goToPage?.(13)}
              }
              >
                {extraContentData}
              </div>
            ) : (
              <div className="box extra-content locked-box">
                {extraContentData}
              </div>
            )}

            {progress?.[RoastingChapter] ? (
              <a
                download
                className="box download"
                href={`field-journal/${language}/field-journal.pdf`}
                onClick={() =>{
                  sendEvent("Journey", `Download-field journey Link - click`, null, {});//GTM-tag in journey
                  ai.trackEvent({ name: "Download-field journey" })}
                }
              >
                {downloadContentData}
              </a>
            ) : (
              <div className="box download locked-box">
                {downloadContentData}
              </div>
            )}
          </div>
        </div>
      </Page>
    </JournalIndexContainer>
  );
};
