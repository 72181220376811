import React from "react";
import styled from "styled-components";

import { Icon } from "src/components";

import { IconButton } from "../ui";

const Placeholder = styled.div``;

const NavButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  font-size: 1.5em;

  ${Placeholder} {
    flex: 0 0 3em;
    margin: 0.5em;
  }
`;

export interface NavButtonsProps {
  onPrev?: () => void;
  onNext?: () => void;
  onHome?: () => void;
  missingActionBehaviour?: "remove" | "placeholder";
  isFirst?: boolean;
  isLast?: boolean;
  className?: string;
}

export const NavButtons = ({
  onPrev,
  onHome,
  onNext,
  missingActionBehaviour = "remove",
  isFirst,
  isLast,
  className,
}: NavButtonsProps) => {
  const missing = missingActionBehaviour === "remove" ? null : <Placeholder />;

  return (
    <NavButtonsContainer className={className}>
      {onPrev ? (
        <Placeholder>
          {!isFirst && (
            <IconButton onClick={onPrev}>
              <Icon name="arrow-left" />
            </IconButton>
          )}
        </Placeholder>
      ) : (
        missing
      )}

      {onHome ? (
        <IconButton onClick={onHome}>
          <Icon name="home" />
        </IconButton>
      ) : (
        missing
      )}

      {onNext ? (
        <Placeholder>
          {!isLast && (
            <IconButton onClick={onNext}>
              <Icon name="arrow-right" />
            </IconButton>
          )}
        </Placeholder>
      ) : (
        missing
      )}
    </NavButtonsContainer>
  );
};
