import React, { useCallback } from "react";
import styled from "styled-components";
import { saveAs } from "file-saver";

import { Icon } from "src/components";
import { breakpoint } from "src/style";
import { ai } from "src/telemetryService";
import useAnalytics from "../../../../../../hooks/use-analytics";

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  margin: 1em 0;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 10.525px 10.525px rgba(0, 0, 0, 0.17);
  border-radius: 3px;
  overflow: hidden;

  @media screen and (min-width: ${breakpoint("xs")}) {
    flex: 0 0 49%;
  }

  @media screen and (min-width: ${breakpoint("sm")}) {
    flex: 0 0 30%;
  }
`;

const CardCaption = styled.div`
  color: ${(props) => props.theme.brand.white};
  background-color: ${(props) => props.theme.brand.black};
  font-size: 0.6em;
  padding: 1.3em 1.5em;
  flex: 0 1;
  position: relative;
`;

const CaptionContent = styled.div`
  border-left: 2px solid ${(props) => props.theme.expressive.coffeeRed};
  text-align: left;
  padding: 0 1em;
`;

const CardTitle = styled.h5`
  font-size: 1.3em;
  line-height: 1.4em;
  margin: 0;

  small {
    margin: 0;
    opacity: 0.5;
    font-size: 0.87em;
    font-family: "SoDo Sans";
    text-transform: initial;
  }
`;

const DownloadButton = styled.button`
  position: absolute;
  top: 1em;
  right: 1em;
  padding: 0.5em;
  display: inline-block;
  color: white;
  background-color: rgba(52, 56, 65, 0.81);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  border-radius: 50%;
  line-height: 100%;
  font-size: 1.5em;

  &:hover {
    cursor: pointer;
  }

  ${Icon} {
    margin: 0;
  }
`;

const ImgLink = styled.a`
  line-height: 0;
`;

const Img = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export interface CardProps {
  title: string;
  description: string;
  image: string;
  downloadUrl?: string;
  children?: React.ReactNode;
}

export const Card = ({
  title,
  image,
  description,
  downloadUrl = image,
}: CardProps) => {
  const { sendEvent } = useAnalytics();
  const handleDownload = useCallback(() => {
    ai.trackEvent({ name: `Download wallpaper-${title}` });
    sendEvent("Experience", `Download ${title} Wallpaper - click`, null, {});//GTM-Download individual wallpaper
    saveAs(downloadUrl, `Hacienda Alsacia - ${title}.jpg`);
  }, [downloadUrl, title]);

  return (
    <CardContainer>
      <ImgLink target="_blank" rel="noreferrer" href={downloadUrl}>
        <Img src={image} alt={title} />
      </ImgLink>

      <CardCaption>
        <CaptionContent>
          <CardTitle>
            {title}
            <small>{description}</small>
          </CardTitle>
        </CaptionContent>

        {downloadUrl && (
          <DownloadButton onClick={handleDownload}>
            <Icon name="download" />
          </DownloadButton>
        )}
      </CardCaption>
    </CardContainer>
  );
};
