import React from "react";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";

import { BaseOverlay, OverlayArticle } from "../../../ui/overlay";
import { HorizontalSeparator } from "../../../ui/overlay/content/article";
import { BaseOverlayProps } from "../../../ui/overlay/base";
import { ai } from "src/telemetryService";

import illustration from "./illustration.jpg";

export type MoreWaysToLearnOverlayProps = Pick<
  BaseOverlayProps,
  "onClose" | "shown"
>;

const Separator = styled(HorizontalSeparator)`
  margin-left: 0;
`;

const Content = styled.div`
  font-size: 1em;

  a {
    color: #00ac91;
    font-weight: 500;
    font-size: 1.05em;
    text-decoration: underline;
    border-bottom: 0px solid transparent;
    transition: color 0.25s, border-bottom 0.25s;

    &:hover {
      color: rgba(210, 23, 90, 1);
      border-bottom: 1px solid rgba(210, 23, 90, 1);
    }
  }

  ol {
    font-size: 1em;
    padding-left: 1.5em;

    &:last-child {
      margin-bottom: 0;
    }

    li {
      margin-bottom: 8.5px;
    }
  }
`;

export const MoreWaysToLearnOverlay = ({
  onClose,
  shown,
}: MoreWaysToLearnOverlayProps) => {
  const { t } = useTranslation();
  const {
    REACT_APP_STARBUCKS_ACADEMY_LINK: coffeeAcademyLink,
    REACT_APP_STARBUCKS_TRACEABILITY_LINK: traceabilityLink,
    REACT_APP_STARBUCKS_STORIES_LINK: storiesLink,
    REACT_APP_STARBUCKS_STORIES_IG: storiesInstagramLink,
    REACT_APP_STARBUCKS_INSTAGRAM_LINK: starbucksInstagramLink,
    REACT_APP_HACIENDA_INSTAGRAM_LINK: haciendaInstagramLink,
  } = process.env;

  return (
    <BaseOverlay
      color="dark"
      shown={shown}
      title={t("tour.more-ways-to-learn.title", "More Ways to Learn")}
      onClose={onClose}
    >
      <OverlayArticle image={illustration}>
        <Content>
          <p>
            <Trans i18nKey="tour.more-ways-to-learn.intro" t={t}>
              We hope you enjoyed your time at Hacienda Alsacia. Here are a few
              ways for you to continue your coffee education and to stay
              informed about how Starbucks is working to ensure the future of
              sustainable coffee for all:
            </Trans>
          </p>

          <Separator />

          <ol>
            <li>
              <Trans i18nKey="tour.more-ways-to-learn.coffee-academy" t={t}>
                Learn even more about coffee from the{" "}
                <a
                  href={coffeeAcademyLink}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() =>
                    ai.trackEvent({ name: "Library-Coffee Academy" })
                  }
                >
                  Starbucks Coffee Academy
                </a>
                .
              </Trans>
            </li>
            <li>
              <Trans
                i18nKey="tour.more-ways-to-learn.starbucks-traceability"
                t={t}
              >
                Use the{" "}
                <a
                  href={traceabilityLink}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() =>
                    ai.trackEvent({ name: "Library-Traceability" })
                  }
                >
                  Starbucks Traceability
                </a>{" "}
                tool to explore your coffee’s journey and get to know the people
                and places that make it possible.
              </Trans>
            </li>
            <li>
              <Trans i18nKey="tour.more-ways-to-learn.starbucks-stories" t={t}>
                Visit{" "}
                <a
                  href={storiesLink}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() =>
                    ai.trackEvent({ name: "Library-Starbucks Stories" })
                  }
                >
                  Starbucks Stories
                </a>{" "}
                to stay up-to-date on the latest coffee news and stories.
              </Trans>
            </li>
            <li>
              <Trans i18nKey="tour.more-ways-to-learn.social" t={t}>
                Follow{" "}
                <a
                  href={starbucksInstagramLink}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() =>
                    ai.trackEvent({ name: "Library-Ins Starbucks" })
                  }
                >
                  Starbucks
                </a>
                ,{" "}
                <a
                  href={haciendaInstagramLink}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() =>
                    ai.trackEvent({ name: "Library-Ins Hacienda Alsacia" })
                  }
                >
                  Hacienda Alsacia
                </a>{" "}
                and{" "}
                <a
                  href={storiesInstagramLink}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() =>
                    ai.trackEvent({ name: "Library-Ins Starbucks Stories" })
                  }
                >
                  Starbucks Stories
                </a>{" "}
                on social media.
              </Trans>
            </li>
          </ol>
        </Content>
      </OverlayArticle>
    </BaseOverlay>
  );
};
