import React from "react";
import styled from "styled-components";

import { BarContainer, Bar } from "./bar";

import bg from "./bg.png";

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
  playing: boolean;
}

const CenteredResponsive = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11;
`;

const FillUpImage = styled.img`
  height: calc((100vw * 9) / 16);
  width: 100vw;
`;

export const Loading = ({ playing, ...props }: Props) => {
  return (
    <>
      <CenteredResponsive {...props}>
        <BarContainer>
          <Bar style={{ animationPlayState: playing ? "running" : "paused" }} />
        </BarContainer>
      </CenteredResponsive>
      <CenteredResponsive {...props}>
        <FillUpImage alt="background" src={bg} className="loading-image" />
      </CenteredResponsive>
    </>
  );
};
