import type { TagMap, Chapter } from "./chapters";

import { useReducer } from "react";
import * as chapters from "./chapters";

export * as chapters from "./chapters";
export * from "./chapters";

export interface ActionDispatch<P = any, T = ActionTypes> {
  type: T;
  payload: P;
}

export enum ActionTypes {
  IntroPlayed = "intro.video.complete",
  LoadingProgress = "loading.progress",
  ChapterComplete = "chapter.complete",
}

export interface State {
  loading: number;
  introComplete: boolean;
  progress: TagMap;
}

export interface LoadingProgressPayload {
  by: number;
}

export type LoadingProgressAction = ActionDispatch<
  LoadingProgressPayload,
  ActionTypes.LoadingProgress
>;

export type IntroPlayedAction = ActionDispatch<
  undefined,
  ActionTypes.IntroPlayed
>;

export interface ChapterCompletePayload {
  chapter: Chapter;
}

export type ChapterCompleteAction = ActionDispatch<
  ChapterCompletePayload,
  ActionTypes.ChapterComplete
>;

export type Actions =
  | LoadingProgressAction
  | IntroPlayedAction
  | ChapterCompleteAction;

export function reducer(state: State, action: Actions): State {
  switch (action.type) {
    case ActionTypes.LoadingProgress:
      return {
        ...state,
        loading: state.loading + action.payload.by,
      };

    case ActionTypes.IntroPlayed:
      return {
        ...state,
        introComplete: true,
      };

    case ActionTypes.ChapterComplete:
      const { progress: prevProgress, ...prevState } = state;
      return {
        ...prevState,
        progress: {
          ...prevProgress,
          [action.payload.chapter]: true,
        },
      };

    default:
      const unknownAction: any = action;
      throw new Error(`unknown action type: ${unknownAction?.type}`);
  }
}

export function initializer(state: Partial<State>): State {
  return {
    loading: 0,
    introComplete: false,
    progress: {
      [chapters.CheckInChapter]: false,
      [chapters.WorldOfCoffeeChapter]: false,
      [chapters.CentralAmericaChapter]: false,
      [chapters.NurseryChapter]: false,
      [chapters.VarietalsChapter]: false,
      [chapters.HarvestChapter]: false,
      [chapters.ProcessingChapter]: false,
      [chapters.DryingChapter]: false,
      [chapters.ResearchChapter]: false,
      [chapters.RoastingChapter]: false,
      [chapters.WatterfallChapter]: false,
      [chapters.EndingChapter]: false,
    },
    ...state,
  };
}

export const actions = {
  introComplete: (): IntroPlayedAction => ({
    type: ActionTypes.IntroPlayed,
    payload: undefined,
  }),

  progressLoading: (by: number): LoadingProgressAction => ({
    type: ActionTypes.LoadingProgress,
    payload: { by },
  }),

  completeChapter: (chapter: Chapter): ChapterCompleteAction => ({
    type: ActionTypes.ChapterComplete,
    payload: { chapter },
  }),
};

const inDev = (state: Partial<State>) => ({
  progress: {
    [chapters.CheckInChapter]: true,
    [chapters.WorldOfCoffeeChapter]: true,
    [chapters.CentralAmericaChapter]: true,
    [chapters.NurseryChapter]: true,
    [chapters.VarietalsChapter]: true,
    [chapters.HarvestChapter]: true,
    [chapters.ProcessingChapter]: true,
    [chapters.DryingChapter]: true,
    [chapters.ResearchChapter]: true,
    [chapters.RoastingChapter]: true,
    [chapters.WatterfallChapter]: true,
    [chapters.EndingChapter]: true,
  },
  ...state,
});

export const useTourState = (initial: Partial<State> = {}, dev = false) => {
  return useReducer(reducer, dev ? inDev(initial) : initial, initializer);
};
