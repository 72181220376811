import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Vector3 } from "three";

import { CustomTag, BaseOverlay } from "src/pages/tour/components";

export interface ZenGardenProps {
  isVisible: boolean;
}

const position = new Vector3(
  -26.83099192969528,
  1.3630788652379567,
  -35.69701454142407
);

const Subtitle = styled.h6`
  display: block;
`;

const Iframe = styled.iframe`
  border: 0;
  width: 100%;
  height: 100%;
`;

export function ZenGarden({ isVisible }: ZenGardenProps) {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (isVisible) {
      const cacheName = "unity-cache";
      if (window.Cache) {
        window.caches.open(cacheName).then((cache) => {
          return cache.addAll([
            "/raking-experience/Build/UnityLoader.js",
            "/raking-experience/TemplateData/UnityProgress.js",
          ]);
        });
      }
    }
  }, [isVisible]);

  return (
    <>
      <CustomTag
        isVisible={isVisible}
        name="raking-experience"
        location={position}
        onOpen={() => setShow(true)}
        size={{ width: 0.4, height: 0.4 }}
        sweepIdToGo="83dd50e3bed242bcaf74cfad90f73a34"
        src="/imgs/extra-tag.png"
      />
      {isVisible ? (
        <BaseOverlay
          title={t("tour.zen-garden.title", "Now it’s your turn")}
          shown={show}
          onClose={() => setShow(false)}
        >
          {isVisible ? (
            <>
              <Subtitle>
                {t(
                  "tour.zen-garden.content",
                  "Click on the rake and move your mouse to dry the parchment"
                )}
              </Subtitle>

              <Iframe
                src="/raking-experience/index.html"
                allow="camera 'none'; microphone 'none'"
                loading="eager"
              />
            </>
          ) : null}
        </BaseOverlay>
      ) : null}
    </>
  );
}
