import { lookAt } from "src/hooks/look-at";
import { Vector3 } from "three";
import {
  SceneObject,
  SDK,
  INTERACTION,
  TRANSITION,
} from "../../../../types/sdk";

export class CustomMatterportTag extends SceneObject {
  events = {
    [INTERACTION.CLICK]: true,
    [INTERACTION.POINTER_MOVE]: true,
  };

  constructor(
    private sdk: SDK,
    private src: string,
    private size: { w: number; h: number },
    private reactEvents: {
      onClick?: () => void;
      onOpen?: () => void;
    },
    private sweepId?: string
  ) {
    super();
    this.sdk.on("sweep.enter", () => this.faceTo());
    this.faceTo();
  }

  faceTo() {
    if (this.root != null) {
      this.sdk.Camera.getPose().then((c: any) => {
        const { x, y, z } = c.position;
        (this as any).root.lookAt(x, y, z);
      });
    }
  }

  onInit() {
    const THREE = this.context!.three;
    const root = new THREE.Object3D();
    this.outputs!.objectRoot = root;
    this.outputs!.collider = root;
    const geometry = new THREE.PlaneGeometry(this.size.w, this.size.h);

    const material = new THREE.MeshLambertMaterial({
      map: new THREE.TextureLoader().load(this.src),
    });
    material.side = THREE.DoubleSide;
    material.transparent = true;
    root.add(new THREE.Mesh(geometry, material));

    this.root = root;
    this.faceTo();
  }

  bringCameraAttention() {
    const position = new Vector3();
    this.root!.getWorldPosition(position);
    return lookAt(this.sdk, position);
  }

  onEvent(eventType: string) {
    switch (eventType) {
      case INTERACTION.CLICK: {
        this.reactEvents.onClick?.();

        if (this.sweepId) {
          this.bringCameraAttention()
            .then(() =>
              this.sdk.Sweep.moveTo(this.sweepId!, {
                transition: TRANSITION.FLY,
              })
            )
            .then(() => this.bringCameraAttention())
            .then(() => {
              this.reactEvents.onOpen?.();
            });
        } else {
          this.reactEvents.onOpen?.();
        }

        break;
      }
    }
  }

  setVisible(visible: boolean) {
    if (this.root) {
      this.root.visible = visible;
    }
  }
}
