import React, { useCallback, useEffect } from "react";

import { OverlayVideo, BaseOverlay } from "../../ui/overlay";
import { Josh, useGuide } from "../../guide-selection";

import type { BaseOverlayProps } from "../../ui/overlay/base";
import { useAudio } from "../../audio";

export interface VideoOverlayProps extends BaseOverlayProps {
  onFinish?: () => void;
  joshVideo: string;
  meliVideo: string;
  skipEventName?: string;
}

export const VideoOverlay = ({
  shown,
  onClose,
  title,
  joshVideo,
  meliVideo,
  onFinish,
  skipEventName,
}: VideoOverlayProps) => {
  const sfx = useAudio();
  const [guide] = useGuide();

  useEffect(() => {
    if (shown) {
      sfx.pauseAmbient();
    }
  }, [shown, sfx]);

  const handleClose = useCallback(() => {
    sfx.startAmbient();
    onClose?.();
  }, [onClose, sfx]);

  const handleFinish = useCallback(() => {
    sfx.startAmbient();
    onFinish?.();
  }, [onFinish, sfx]);

  return (
    <BaseOverlay
      title={title}
      onClose={onClose ? handleClose : undefined}
      shown={shown}
    >
      <OverlayVideo
        play={shown}
        videoId={guide === Josh ? joshVideo : meliVideo}
        onFinish={handleFinish}
        skipEventName={skipEventName}
      />
    </BaseOverlay>
  );
};
