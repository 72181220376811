import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useMatterportTagTranslation } from "src/hooks";
import { Dropdown, Icon } from "src/components";
import { breakpoint } from "src/style";
import { ai } from "src/telemetryService";
import { a11yKey, enA11y, frA11y } from "src/i18n";
import useAnalytics from "../../hooks/use-analytics";

const LangIcon = styled(Icon)`
  height: 1.28em;
  width: 1.28em;
  vertical-align: text-bottom;
  margin: 0 0.25em;
  color: inherit;
  margin: 0;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: 0 0.5em;
  }
`;

const LanguageButton = styled.button`
  margin: 0;
  border: 0;
  outline: 0;
  padding: 0;
  width: 100%;
  padding: 0.5em 1em;
  cursor: pointer;
  background: none;
  font-size: 0.9em;
  line-height: 1.2em;
  font-family: ${(props) => props.theme.fonts.main};
  text-transform: capitalize;
  color: ${(props) => props.theme.brand.white};
  word-break: keep-all;
`;

const MargedDropdown = styled(Dropdown)`
  cursor: pointer;
  font-family: ${(props) => props.theme.fonts.main};
`;

const LanguageList = styled.div`
  background: rgba(0, 0, 0, 0.84);
  border: 1px solid rgba(255, 255, 255, 0.11);
  box-sizing: border-box;
  border-radius: 6px;
  margin-top: 0.5em;
  max-height: 9em;
  overflow: scroll;

  @media screen and (min-width: ${breakpoint("md")}) {
    overflow: auto;
  }
`;

interface LangSelectProps extends React.HtmlHTMLAttributes<HTMLButtonElement> {
  icon?: boolean;
}

export const LangSelect = ({ icon = true, ...props }: LangSelectProps) => {
  const langMatterport = useMatterportTagTranslation();
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const availableLanguages = Object.keys(
    i18n.options.resources as Record<string, any>
  );

  const { sendEvent } = useAnalytics();

  const handleOpen = useCallback(() => setOpen((open) => !open), []);
  const handleLanguageSelect = (lng: string) => () => {
    ai.trackEvent({ name: `Language-${lng}` });
    sendEvent("Homepage", `Language Selection ${lng} Button - click`, null,  {});
    i18n.changeLanguage(lng);
    langMatterport();
    sessionStorage.removeItem(a11yKey);
    setOpen(false);
  };

  const handleA11YLanguageSelect = (a11y: string) => () => {
    ai.trackEvent({
      name: a11y === enA11y ? "Language-english-a11y" : "Language-franch-a11y",
    });
    sendEvent("Homepage", `Language Selection ${a11y} Button - click`, null,  {});
    const lng = a11y === enA11y ? "en" : "fr";
    i18n.changeLanguage(lng);
    sessionStorage.setItem(a11yKey, a11y);
    setOpen(false);
  };

  const enAllyTitle = "English (description)";
  const frAllyTitle = "French-Canadian (description)";

  let menuMessage = t("language");
  if (!!sessionStorage.getItem(a11yKey)) {
    menuMessage =
      sessionStorage.getItem(a11yKey) === enA11y ? enAllyTitle : frAllyTitle;
  }
  const enA11yItem = (
    <LanguageButton
      key="english-a11y"
      onClick={handleA11YLanguageSelect(enA11y)}
    >
      {enAllyTitle}
    </LanguageButton>
  );
  const frA11yItem = (
    <LanguageButton key="fr-a11y" onClick={handleA11YLanguageSelect(frA11y)}>
      {frAllyTitle}
    </LanguageButton>
  );
  let [first, second, thrid, forth, ...items] = availableLanguages.map(
    (lng) => (
      <LanguageButton key={lng} onClick={handleLanguageSelect(lng)}>
        {t("language", { lng })}
      </LanguageButton>
    )
  );

  return (
    <MargedDropdown
      {...props}
      icon={icon ? <LangIcon name="globe" /> : null}
      open={open}
      message={menuMessage}
      chevron={<LangIcon name="chevron-down" />}
      onOpen={handleOpen}
    >
      <LanguageList>
        {[first, enA11yItem, second, thrid, forth, frA11yItem, ...items]}
      </LanguageList>
    </MargedDropdown>
  );
};
