import React from "react";
import { Vector3 } from "three";
import { Trans, useTranslation } from "react-i18next";

import { ArtworkTag, ArtworkTagPublicProps } from "../../../interactive-tags";

import art from "./art.jpg";

const position = new Vector3(
  -12.978210782875388,
  1.5690545584565363,
  -37.8323898051316
);

const sweep = "69f012cffe994ebcb4af134a3d9334ba";

export const MataRudaArtworkTag = (props: ArtworkTagPublicProps) => {
  const { t } = useTranslation();

  return (
    <ArtworkTag
      title={t("tour.artworks.mata-ruda.title", "Untitled")}
      author="Mata Ruda"
      name="mata-ruda-artwork"
      position={position}
      image={art}
      sweep={sweep}
      {...props}
    >
      <Trans i18nKey="tour.artworks.mata-ruda.content" t={t}>
        <p>
          This mural by Mata Ruda draws on the artist’s Costa Rican heritage,
          featuring an image of his grandmother and many references to coffee.
          The colorful ox-cart wheel behind her is a classic Costa Rican symbol.
          These colorful carts have been used to transport coffee since the
          1800s. At first, they were pulled by people, but as demand for coffee
          grew across the country and the world, they were pulled by oxen so
          they could be filled with more coffee. Though many farmers now use
          trucks to transport their coffee, at harvest time in Costa Rica you
          will still see the occasional ox cart full of ripe cherries on the way
          to be processed.
        </p>
      </Trans>
    </ArtworkTag>
  );
};
