import React from "react";
import styled from "styled-components";

import sprite from "./sprite.svg";

export interface IconProps extends React.HtmlHTMLAttributes<SVGSVGElement> {
  name:
    | "arrow-down"
    | "arrow-left"
    | "arrow-right"
    | "arrow-up"
    | "check"
    | "chevron-down"
    | "close"
    | "download"
    | "globe"
    | "guide-switch"
    | "help"
    | "home"
    | "index"
    | "instagram"
    | "lamp"
    | "map"
    | "marker"
    | "maximize"
    | "menu"
    | "mouse"
    | "path"
    | "play"
    | "share"
    | "speaker-off"
    | "speaker-on"
    | "twitter"
    | "youtube"
    | "facebook";
}

const IconContainer = styled.svg`
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  stroke: currentColor;
  vertical-align: text-bottom;
  margin: 0 0.5ch;
`;

const IconSprite = ({ name, ...props }: IconProps) => {
  const spritePath = `${sprite}#${name}`;

  return (
    <IconContainer viewBox="0 0 24 24" {...props}>
      <use href={spritePath} xlinkHref={spritePath} />
    </IconContainer>
  );
};

export const Icon = styled(IconSprite)``;
