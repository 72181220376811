import React, { useCallback } from "react";
import styled from "styled-components";
import { useTranslation, Trans } from "react-i18next";

import { LangSelect } from "src/components";
import { breakpoint } from "src/style";

import { SoundToggle } from "../sound-toggle";
import { HelpButton } from "../help";
import { useAudio } from "../../../audio";
import { useGuide } from "../../../guide-selection";
import { GuideChooser } from "../../../guide-selection/guide-chooser";

import { Section, SubSection } from "./internal";
import { MenuUIButtonStyle } from "../button";

const DropdownButton = styled(MenuUIButtonStyle)`
  width: auto;
  margin: 0;
  text-transform: capitalize;

  > *:last-child {
    margin-right: -1em;

    > * {
      width: 1em;
      height: 1em;
    }
  }

  @media screen and (orientation: landscape) {
    padding-left: 0.75em;
    padding-right: 0.75em;
  }

  @media screen and (min-width: ${breakpoint("md")}) {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
`;

const GuideContainer = styled.div`
  display: flex;
  flex: 1 1 50%;
  justify-content: center;
  align-items: center;
  padding: 0.5em 0;
`;

const GuideSection = styled.div`
  flex: 0 1 50%;
  margin-bottom: 1em;

  @media screen and (orientation: portrait), (min-width: ${breakpoint("md")}) {
    flex: 1 0 100%;
  }

  @media screen and (min-width: ${breakpoint("md")}) {
    margin-bottom: 2em;
  }
`;

const KnobSection = styled(Section)`
  @media screen and (min-width: ${breakpoint("md")}) {
    flex: 0 1 8em;
    margin: 0 1em;
  }
`;

const NeedHelpSection = styled(KnobSection)`
  @media screen and (orientation: landscape) and (max-width: ${breakpoint(
      "md"
    )}) {
    flex: 1 0 100%;
  }

  @media screen and (min-width: ${breakpoint("md")}) {
    flex: 0 0 13.5em;
  }
`;

export const SectionTitle = styled.h3`
  font-size: 1em;
  line-height: 120%;
  letter-spacing: 0.11em;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0.5em;

  @media screen and (min-width: ${breakpoint("sm")}) {
    margin-bottom: 1.35em;
  }
`;

const SettingsSection = styled(Section)`
  flex: 0.35 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
`;

const KnobsSubSection = styled(SubSection)`
  @media screen and (min-width: ${breakpoint("md")}) {
    flex-wrap: nowrap;
    justify-content: center;
  }
`;

interface SettingsProps {
  onHelp: () => void;
}

export const Settings = ({ onHelp }: SettingsProps) => {
  const { t } = useTranslation();
  const sfx = useAudio();
  const [guide] = useGuide();

  const toggleMute = useCallback(() => {
    sfx.isMuted() ? sfx.unmute() : sfx.mute();
  }, [sfx]);

  const handleDropdownClick = useCallback(() => sfx.click(), [sfx]);

  return (
    <SettingsSection>
      {!!guide && (
        <GuideSection>
          <SectionTitle>{t("tour.menu.your-guide", "Your guide")}</SectionTitle>

          <GuideContainer>
            <GuideChooser />
          </GuideContainer>
        </GuideSection>
      )}

      <KnobsSubSection>
        <KnobSection>
          <SectionTitle>{t("tour.menu.language", "Language")}</SectionTitle>
          <DropdownButton
            as={LangSelect}
            onClick={handleDropdownClick}
            icon={false}
          />
        </KnobSection>

        <KnobSection>
          <SectionTitle>
            <Trans i18nKey="tour.menu.sounds">
              <br />
              Music &amp; SFX
            </Trans>
          </SectionTitle>
          <SoundToggle muted={sfx.isMuted()} onClick={toggleMute} />
        </KnobSection>

        <NeedHelpSection>
          <SectionTitle>
            {t("tour.menu.help-section", "Need Help")}
          </SectionTitle>
          <HelpButton onClick={onHelp} />
        </NeedHelpSection>
      </KnobsSubSection>
    </SettingsSection>
  );
};
