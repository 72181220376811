import React from "react";
import styled from "styled-components";

import { ArtworkBoxType } from ".";

const ArtworkBoxContainer = styled.div`
  padding: 15px;
  display: flex;
  /* cursor: pointer; */
  align-items: center;
  flex-direction: column;
  width: calc(232px - 30px);
  height: calc(210px - 30px);
  border-radius: 3px !important;
  background: linear-gradient(139.87deg, #ffffff 0%, #f2f0eb 100%);
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.05),
    0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0359427),
    0px 22.3363px 17.869px rgba(0, 0, 0, 0.0298054),
    0px 12.5216px 10.0172px rgba(0, 0, 0, 0.025),
    0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0201946),
    0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0140573);

  img {
    margin: 0;
    width: 200px;
    height: 146px;
    display: block;
    border-radius: 2px;
  }

  .info {
    width: 89%;
    height: 25px;
    display: flex;
    padding: 0 7px;
    margin: 10px 0 0 0px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 0 !important;
    justify-content: space-between;
    border-left: 2px solid #d2175a;

    h3 {
      margin: 0;
      font-size: 11px;
      color: #000000;
      font-style: normal;
      font-weight: normal;
      font-family: "SoDo Sans";
      text-align: left;
    }

    p {
      margin: 0;
      font-size: 8px;
      color: #666666;
      font-style: normal;
      font-weight: normal;
      font-family: "SoDo Sans";
    }
  }
`;

export const ArtworkBox: ArtworkBoxType = ({
  link,
  title,
  artist,
  children,
  className,
}) => {
  return (
    <ArtworkBoxContainer className={className}>
      {children}

      <div className="info">
        <h3>{title}</h3>

        <p>{artist}</p>
      </div>
    </ArtworkBoxContainer>
  );
};
