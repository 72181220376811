import React from "react";
import { Vector3 } from "three";

import { ChapterTag, ChapterTagPublicProps } from "../../interactive-tags";

export const position = new Vector3(
  -51.65493800117522,
  4.76016908944826,
  -28.000294743668206
);
export const sweep = "8db483b945cc4401ba16d5181fe84df3";

export const HarvestTag = (props: ChapterTagPublicProps) => {
  return (
    <ChapterTag
      name="harvest"
      position={position}
      joshVideo="502308113"
      meliVideo="502307466"
      sweep={sweep}
      {...props}
    />
  );
};
