import React from "react";
import { Vector3 } from "three";

import { ChapterTag, ChapterTagPublicProps } from "../../interactive-tags";

export const position = new Vector3(
  -51.784080977275714,
  2.8141717599974108,
  -31.75240117949194
);
export const sweep = "9d09694126d6410ea20ecb505182526d";

export const VarietalsTag = (props: ChapterTagPublicProps) => {
  return (
    <ChapterTag
      name="varietals"
      position={position}
      joshVideo="503098524"
      meliVideo="503102043"
      sweep={sweep}
      {...props}
    />
  );
};
