import React from "react";

import { FieldJournalOverlayType } from "../components";
import { MenuButtonContainer } from "../../ui/menu-container";
import { CloseButton, Overlay, OverlayContent } from "../../ui/overlay";

export const BookOverlay: FieldJournalOverlayType = ({
  children,
  ...props
}) => {
  return (
    <Overlay {...props}>
      <MenuButtonContainer side="right">
        <CloseButton onClick={props.onClose} />
      </MenuButtonContainer>

      <OverlayContent>{children}</OverlayContent>
    </Overlay>
  );
};
