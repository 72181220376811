import React, { useCallback } from "react";
import styled, { css } from "styled-components";

import { Button, Icon } from "src/components";
import { useAudio } from "../audio";

const buttonActive = css`
  background-color: #ffffff;
  color: ${(props) => props.theme.brand.black};
`;

export interface StyledButtonProps {
  active?: boolean;
}

export const StyledButton = styled(Button)<StyledButtonProps>`
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(10px);
  padding: 0.5em 1.5em;
  font-size: 1.1em;
  display: inline-flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border: 0.075em solid rgba(255, 255, 255, 0.27);
  box-sizing: border-box;
  border-radius: 5em;
  color: ${(props) => props.theme.brand.white};
  transition-property: background-color, color;
  transition-duration: 0.5s;

  @media (hover: hover) {
    &:hover {
      ${buttonActive}
    }
  }

  &:active {
    ${buttonActive}
  }

  ${(props) => props.active && buttonActive}

  > ${Icon} {
    &:first-child {
      margin-right: -0.5em;
      margin-left: 0.5em;
    }

    &:last-child {
      margin-right: 0.5em;
      margin-left: -0.5em;
    }
  }
`;

export type UIButtonProps = StyledButtonProps &
  React.HtmlHTMLAttributes<HTMLButtonElement>;

export const UIButton = ({ onClick, ...props }: UIButtonProps) => {
  const sfx = useAudio();
  const handleClick = useCallback(
    (ev) => {
      onClick?.(ev);
      sfx.click();
    },
    [onClick, sfx]
  );

  return <StyledButton onClick={handleClick} {...props} />;
};

export const IconButton = styled(UIButton)`
  padding: 0.6em;
  font-size: 1em;
  border-radius: 50%;

  > ${Icon} {
    margin: 0 !important;
  }
`;
