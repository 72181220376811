import React, {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";

import { AppState, APP_PHASE } from "../../types/sdk";

import {
  Loading,
  IntroVideo,
  MatterportModel,
  Exit,
  Menu,
  story,
  useAudio,
  SfxProvider,
  ClackerboardVideo,
  CafeAudio,
  BaseOverlay,
  FullScreen,
} from "./components";
import { Observable } from "./observable";
import { GuideProvider } from "./components/guide-selection";
import { useConnectMatterportSDK } from "../../hooks";

import { actions, useTourState, Chapter, chapters } from "./state";
import { FieldJournal } from "./components/field-journal";

const TourContainer = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
`;

const Page = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: fixed;
  left: 0;
  top: 0;
`;

const Landscape = styled(Page)`
  visibility: hidden;
  pointer-events: none;

  @media only screen and (orientation: landscape) {
    visibility: visible;
    pointer-events: initial;
  }
`;

const Portrait = styled(Page)`
  background-color: #000;
  visibility: hidden;
  pointer-events: none;

  @media only screen and (orientation: portrait) {
    visibility: visible;
    pointer-events: initial;
  }
`;
const OrientationGifWraper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const GIF = styled.img`
  width: 100vw;
  max-width: 100vw;
  height: auto;
`;

const observable = new Observable();
export const SDKContext = createContext<any>(observable);
export interface TourProps {
  playIntro?: boolean;
}

const Tour = ({ playIntro = true }: TourProps) => {
  const sfx = useAudio();
  const [state, dispatch] = useTourState({ introComplete: false }, !playIntro);
  const [isInFullscreen, setIsInFullscreen] = useState(false);
  const [landscape, setLandscape] = useState(false);
  const [playerReady, setPlayerReady] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [introPlaying, setIntroPlaying] = useState<boolean>(false);

  const after = (chapter: Chapter) => state.progress[chapter];
  const notYetCompleted = (chapter: Chapter) => !state.progress[chapter];
  const setChapterComplete = (chapter: Chapter) => () => {
    dispatch(actions.completeChapter(chapter));
  };

  const observableRef = useRef(observable);
  const [sdk, setSdkRef] = useConnectMatterportSDK();

  const handleIntroVideoEnd = useCallback(() => {
    if (!state.introComplete) {
      setIntroPlaying(false);
      dispatch(actions.introComplete());
      sfx.startAmbient();
      sfx.unmute();
    }
  }, [dispatch, sfx, state.introComplete]);

  useEffect(() => {
    const fn = () => {
      const matches = window.matchMedia("(orientation:landscape)").matches;
      if (matches) {
        setLandscape(true);
      } else {
        setLandscape(false);
      }
    };
    fn();
    window.addEventListener("resize", fn);
    return () => window.removeEventListener("resize", fn);
  }, []);

  useEffect(() => {
    landscape &&
      setTimeout(() => {
        setIsLoading(false);
      }, 5000);
  }, [state, dispatch, landscape]);

  useEffect(() => {
    observableRef.current.update(sdk);

    if (state.introComplete && !sdk) {
      setIsLoading(true);
    }

    if (sdk) {
      const applicationReadyCallback = (mState: AppState) => {
        if (state.introComplete && isLoading) {
          setIsLoading(false);
        } else if (mState.phase !== APP_PHASE.PLAYING && state.introComplete) {
          setIsLoading(true);
        }
      };

      const sub = sdk.App.state.subscribe(applicationReadyCallback);

      return () => sub.cancel();
    }
  }, [sdk, dispatch, state, isLoading]);

  return (
    <SDKContext.Provider value={observableRef.current}>
      <FullScreen
        fullscreenRequested={(v) => setIsInFullscreen(v)}
        fullScreen={isInFullscreen}
      >
        <Landscape className="landscape">
          {(isLoading || introPlaying || !state.introComplete) &&
            playIntro &&
            isInFullscreen && (
              <>
                {(isLoading || !introPlaying) && (
                  <Loading playing={landscape} />
                )}
                <IntroVideo
                  play={landscape && !isLoading && playerReady}
                  onCanPlay={() => setPlayerReady(true)}
                  onEnded={handleIntroVideoEnd}
                  onPlay={() => !introPlaying && setIntroPlaying(true)}
                />
              </>
            )}

          <TourContainer>
            <MatterportModel
              ref={setSdkRef}
              model={process.env.REACT_APP_MATTERPORT_MODEL_ID!}
              applicationKey={process.env.REACT_APP_MATTERPORT_SDK_KEY!}
            />

            <ClackerboardVideo />
            <CafeAudio />

            <Exit />

            <GuideProvider>
              <Menu autoDisplayHelp progress={state.progress} />

              <story.chapters.CheckInTag
                eventName="Check-in Video Tag"
                isVisible={notYetCompleted(chapters.CheckInChapter)}
                onComplete={setChapterComplete(chapters.CheckInChapter)}
              />
              <story.chapters.WorldOfCoffeeTag
                eventName="World-of-Coffee Video Tag"
                isVisible={after(chapters.CheckInChapter)}
                isActive={notYetCompleted(chapters.WorldOfCoffeeChapter)}
                onComplete={setChapterComplete(chapters.WorldOfCoffeeChapter)}
                nextStop={story.chapters.centralAmericaPosition}
              />
              <story.chapters.CentralAmericaTag
                eventName="Central-America Video Tag"
                isVisible={after(chapters.WorldOfCoffeeChapter)}
                isActive={notYetCompleted(chapters.CentralAmericaChapter)}
                onComplete={setChapterComplete(chapters.CentralAmericaChapter)}
                nextStop={story.chapters.nurseryPosition}
              />
              <story.chapters.NurseryTag
                eventName="Nursery Video Tag"
                isVisible={after(chapters.CentralAmericaChapter)}
                isActive={notYetCompleted(chapters.NurseryChapter)}
                onComplete={setChapterComplete(chapters.NurseryChapter)}
                nextStop={story.chapters.varietalsPosition}
              />
              <story.chapters.VarietalsTag
                eventName="Varietals Video Tag"
                isVisible={after(chapters.NurseryChapter)}
                isActive={notYetCompleted(chapters.VarietalsChapter)}
                onComplete={setChapterComplete(chapters.VarietalsChapter)}
                nextStop={story.chapters.varietalsPosition}
              />
              <story.chapters.HarvestTag
                eventName="Harvest Video Tag"
                isVisible={after(chapters.VarietalsChapter)}
                isActive={notYetCompleted(chapters.HarvestChapter)}
                onComplete={setChapterComplete(chapters.HarvestChapter)}
                nextStop={story.chapters.processingPosition}
              />
              <story.chapters.ProcessingTag
                eventName="Processing Video Tag"
                isVisible={after(chapters.HarvestChapter)}
                isActive={notYetCompleted(chapters.ProcessingChapter)}
                onComplete={setChapterComplete(chapters.ProcessingChapter)}
                nextStop={story.chapters.dryingPosition}
              />
              <story.chapters.DryingTag
                eventName="Drying Video Tag"
                isVisible={after(chapters.ProcessingChapter)}
                isActive={notYetCompleted(chapters.DryingChapter)}
                onComplete={setChapterComplete(chapters.DryingChapter)}
                nextStop={story.chapters.researchPosition}
              />
              <story.chapters.ResearchTag
                eventName="Research Video Tag"
                isVisible={after(chapters.DryingChapter)}
                isActive={notYetCompleted(chapters.ResearchChapter)}
                onComplete={setChapterComplete(chapters.ResearchChapter)}
                nextStop={story.chapters.roastingPosition}
              />
              <story.chapters.RoastingTag
                eventName="Roasting Video Tag"
                isVisible={after(chapters.ResearchChapter)}
                isActive={notYetCompleted(chapters.RoastingChapter)}
                onComplete={setChapterComplete(chapters.RoastingChapter)}
                nextStop={story.chapters.waterfallPosition}
              />
              <story.chapters.WaterfallTag
                eventName="Waterfall Video Tag"
                isVisible={after(chapters.RoastingChapter)}
                isActive={notYetCompleted(chapters.WatterfallChapter)}
                onComplete={setChapterComplete(chapters.WatterfallChapter)}
                nextStop={story.chapters.endingPosition}
              />
              <story.chapters.EndingTag
                eventName="Ending Video Tag"
                isVisible={after(chapters.WatterfallChapter)}
                isActive={notYetCompleted(chapters.EndingChapter)}
                onComplete={setChapterComplete(chapters.EndingChapter)}
              />
            </GuideProvider>

            <story.artworks.HistoryOfCoffeeTag
              isVisible={after(chapters.CheckInChapter)}
            />
            <story.artworks.TheCollectorTag
              isVisible={after(chapters.CheckInChapter)}
            />
            <story.artworks.IntrospectionTag
              isVisible={after(chapters.CheckInChapter)}
            />
            <story.artworks.MataRudaArtworkTag
              isVisible={after(chapters.CheckInChapter)}
            />
            <story.artworks.TommyTaylorArtworkTag
              isVisible={after(chapters.CheckInChapter)}
            />
            <story.extra.DownloadsTag
              isVisible={after(chapters.CheckInChapter)}
            />
            {/* <story.extra.ZenGarden isVisible={after(chapters.CheckInChapter)} /> */}
            <story.extra.CoffeeRecipesTag
              isVisible={after(chapters.CheckInChapter)}
            />
            <story.extra.MoreWaysToLearnTag
              isVisible={after(chapters.CheckInChapter)}
            />
            <story.extra.CafeMenuTag
              isVisible={after(chapters.CheckInChapter)}
            />

            <FieldJournal
              progress={state.progress}
              isVisible={after(chapters.CheckInChapter)}
            />
          </TourContainer>
        </Landscape>
        <Portrait>
          <BaseOverlay shown>
            <OrientationGifWraper>
              <GIF
                src="/imgs/rotate.gif"
                alt="rotate cellphone to landscape gif"
              />
            </OrientationGifWraper>
          </BaseOverlay>
        </Portrait>
      </FullScreen>
    </SDKContext.Provider>
  );
};

//sound control in ManuOverlay
const TourWithSound = (props: TourProps) => {
  return (
    <SfxProvider>
      <Tour {...props} />
    </SfxProvider>
  );
};

export default TourWithSound;
