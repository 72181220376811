import { createContext, useContext } from "react";

interface PageTransitionsContextValue {
  navigate: { (to: string): void };
  to: string;
}

const PageTransitionsContext = createContext<PageTransitionsContextValue>({
  to: "",
  navigate: () => {},
});

export const PageTransitionProvider = PageTransitionsContext.Provider;

export const useTransitionNavigation = () => {
  const { navigate } = useContext(PageTransitionsContext);

  return navigate;
};
