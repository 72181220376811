import React from "react";
import { Vector3 } from "three";

import { ChapterTag, ChapterTagPublicProps } from "../../interactive-tags";

export const position = new Vector3(
  -44.23488980110534,
  1.1211591085512513,
  -37.50995376236348
);

export const sweep = "b059b53949dd4870868b5dbf02e01216";

export const NurseryTag = (props: ChapterTagPublicProps) => {
  return (
    <ChapterTag
      name="nursery"
      position={position}
      joshVideo="502305610"
      meliVideo="502305030"
      sweep={sweep}
      {...props}
    />
  );
};
