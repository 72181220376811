import { Vector3 } from "three";

import { useSweepWalk } from "./sweep-walk";
import { useCameraLookAt } from "./look-at";
import { useCallback } from "react";

export interface GoToTagOptions {
  sweep?: string; //sweep id of a location
  position?: Vector3;
  name?: string; // tag name for GTM
}

export const useGoToTag = (options?: GoToTagOptions) => {
  const walk = useSweepWalk();
  const lookAt = useCameraLookAt();

  return useCallback(
    async (to?: GoToTagOptions) => {
      const target = to || options;

      if (!target) return;

      const { position, sweep } = target;

      if (position) await lookAt(position);
      if (sweep) await walk(sweep);
      if (position) await lookAt(position);
    },
    [lookAt, options, walk]
  );
};
