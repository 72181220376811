import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useAudio } from "../audio";

const Container = styled.div`
  @keyframes alert-toast-appear {
    from {
      transform: translate(-50%, 100%);
      opacity: 0;
    }

    to {
      transform: translate(-50%, 0%);
      opacity: 1;
    }
  }

  animation: forwards normal alert-toast-appear 1s;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(12px);
  border-radius: 54px;
  display: flex;
  justify-content: center;
  position: absolute;
  color: #fff;
  font-size: 1.1em;
  font-weight: 400;
  line-height: 1.75em;
  padding: 0.4em 1.5em;
  margin: auto;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  overflow: hidden;
  box-sizing: border-box;
  margin: 2em 0;
  max-width: 95vw;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.sm}) {
    max-width: 85vw;
  }
`;

const ContentContainer = styled.div`
  @keyframes alert-toast-container-appear {
    0% {
      max-width: 0vw;
    }

    100% {
      max-width: 100vw;
    }
  }

  animation: forwards normal alert-toast-container-appear ease-in-out 2s;
  padding: 0.35em 0;
  white-space: nowrap;
`;

const IconContainer = styled.div`
  background-color: ${(props) => props.theme.brand.white};
  color: ${(props) => props.theme.brand.accentGreen};
  margin-left: -0.8em;
  margin-right: 0.65em;
  border-radius: 50%;
  font-size: 1.2em;
  line-height: 2.2em;
  height: 2em;
  width: 2em;
  text-align: center;
  flex-shrink: 0;

  > * {
    margin: 0;
    vertical-align: baseline;
    max-height: 100%;
    max-width: 100%;
  }
`;

export interface AlertProps {
  children: React.ReactNode;
  icon?: React.ReactNode;
  // Control visibility from outside. Disables "dismissAfter" and "delay".
  shown?: boolean;
  dismissAfter?: number;
  delay?: number;
  muted?: boolean;
}

export const Alert = ({
  icon,
  children,
  shown,
  dismissAfter,
  delay,
  muted,
}: AlertProps) => {
  const { pop: popSfx } = useAudio();
  const [internalVisible, setVisible] = useState(shown || !delay);
  const isControlled = typeof shown === "boolean";
  const isVisible = isControlled ? shown : internalVisible;

  useEffect(() => {
    if (!isControlled && delay && delay > 0) {
      setTimeout(() => {
        setVisible(true);
      }, delay);
    }
  }, [isControlled, delay]);

  useEffect(() => {
    if (!isControlled && dismissAfter && dismissAfter > 0) {
      setTimeout(() => {
        setVisible(false);
      }, dismissAfter);
    }
  }, [isControlled, dismissAfter]);

  useEffect(() => {
    if (isVisible && !muted) {
      popSfx();
    }
  }, [isVisible, popSfx, muted]);

  return isVisible ? (
    <Container>
      {icon && <IconContainer>{icon}</IconContainer>}
      <ContentContainer>{children}</ContentContainer>
    </Container>
  ) : null;
};
