import React from "react";
import { Vector3 } from "three";

import { ChapterTag, ChapterTagPublicProps } from "../../interactive-tags";

export const position = new Vector3(
  -76.1539540907621,
  3.324841020300685,
  -41.564493192014794
);

export const sweep = "4415f04ecb3e4219b459f7594a76ae0c";

export const CentralAmericaTag = (props: ChapterTagPublicProps) => {
  return (
    <ChapterTag
      name="central-america"
      position={position}
      joshVideo="502307249"
      meliVideo="502306842"
      sweep={sweep}
      {...props}
    />
  );
};
