import React from "react";
import styled from "styled-components";

import { Icon } from "../../../../../components";

import { IconButton } from "../button";

export const CloseIconButton = styled(IconButton)`
  font-size: 1em;
  position: relative;
  z-index: 5;
`;

export const CloseButton = (
  props: React.HtmlHTMLAttributes<HTMLButtonElement>
) => {
  return (
    <CloseIconButton {...props}>
      <Icon name="close" />
    </CloseIconButton>
  );
};
