declare global {
  const safari: Record<string, any>;

  interface Window {
    HTMLElement?: HTMLElement;
    safari: Record<string, any>;
  }
}

export const isSafari =
  /constructor/i.test((window as any).HTMLElement) ||
  (function (p) {
    return p.toString() === "[object SafariRemoteNotification]";
  })(
    !window["safari"] ||
      (typeof safari !== "undefined" && window["safari"].pushNotification)
  );

export const mediaVolumeIsSettable = (function (): Promise<boolean> {
  const audio = new Audio();
  const newVolume = 0.5;

  return new Promise((resolve) => {
    const timer = setTimeout(() => resolve(false), 2000);

    audio.addEventListener("volumechange", () => {
      clearTimeout(timer);
      resolve(true);
    });

    audio.volume = newVolume;
  });
})();

export const isIOS = /iPhone/.test(navigator.userAgent);

if (process.env.NODE_ENV !== "production") {
  (async function () {
    console.log("browser capabilities", {
      isSafari,
      mediaVolumeIsSettable: await mediaVolumeIsSettable,
      isIOS,
    });
  })();
}
