import { useCallback } from "react";
import { Euler, Vector3 } from "three";

import { useMatterportSDK } from "src/hooks";
import { SDK } from "src/types/sdk";

const radToDeg = (rad: number) => (rad * 180) / Math.PI;
const degToRad = (deg: number) => (deg * Math.PI) / 180;

const baseRotation = new Vector3(0, 0, -1);

//Make camera face where the user is looking at
export const lookAt = async (sdk: SDK, targetPosition: Vector3) => {
  const pose = await sdk?.Camera.getPose();

  if (!pose) {
    console.error("could not get current pose");

    return;
  }

  const currentRotation = new Euler(
    degToRad(pose.rotation.x),
    degToRad(pose.rotation.y),
    0,
    "XYZ"
  );

  const currentDirection = baseRotation.clone().applyEuler(currentRotation);

  const currentPosition = new Vector3(
    pose.position.x,
    pose.position.y,
    pose.position.z
  );

  const target = targetPosition.clone();

  // Create vectors to location calculate angle
  const v1 = currentDirection.clone().normalize();
  const v2 = target.clone().sub(currentPosition).normalize();

  const v1XRot = new Vector3(v1.x, 0, v1.z);

  const v2XRot = new Vector3(v2.x, 0, v2.z);

  const xangle = v1XRot.angleTo(v2XRot);

  // Get angle and translate rad to degrees
  // Ensure current position and determines if will rotate left or right
  const direction = v1.x * v2.z - v1.z * v2.x < 0 ? -1 : 1;

  await sdk?.Camera.rotate(radToDeg(xangle) * direction);
};

export const useCameraLookAt = () => {
  const sdk = useMatterportSDK();

  return useCallback(
    (nextStop: Vector3) => {
      if (!sdk) return;

      return lookAt(sdk, nextStop);
    },
    [sdk]
  );
};
