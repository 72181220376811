import React from "react";
import { Vector3 } from "three";
import { Trans, useTranslation } from "react-i18next";

import { ArtworkTag, ArtworkTagPublicProps } from "../../../interactive-tags";

import art from "./art.jpg";

const sweep = "5e31a662edf342ce8ff235a695a0ea68";

const position = new Vector3(
  -57.92538819110782,
  1.8019057715705609,
  -48.6893493275705
);

export const HistoryOfCoffeeTag = (props: ArtworkTagPublicProps) => {
  const { t } = useTranslation();

  return (
    <ArtworkTag
      title={t("tour.artworks.history-of-coffee.title", "History of Coffee")}
      author="Sergio Guillen"
      name="history-of-coffee"
      sweep={sweep}
      position={position}
      image={art}
      {...props}
    >
      <Trans i18nKey="tour.artworks.history-of-coffee.content" t={t}>
        <p>
          After seeing his art all around San Jose, two Starbucks store
          designers ran into artist Sergio Guillen outside a market selling his
          work. They immediately recognized his style and thought it
          serendipitous to have met this way. He was commissioned to create
          these totems, which stretch the length of the Visitor’s Center. They
          depict coffee’s long history which began circa A.D. 800 with an
          Ethiopian goat herder named Kaldi who noticed his goats became
          energetic after eating the berries of a certain shrub.
        </p>
        <p>
          Another set of totems at the Visitor Center also depict the rich
          history of coffee in Costa Rica, beginning with the arrival of coffee
          plants in the late 1700s. A hundred years later, coffee was being
          grown all over the country and moved to ports in the country’s iconic
          ox carts for export. The country’s coffee growing had a major impact
          on its social and economic development, from healthcare and politics
          to education and art.
        </p>
        <p>
          Starbucks joined coffee’s rich global history in 1971 when it opening
          its first store in Seattle’s Pike Place Market. Today, Starbucks is a
          leader in the world of coffee, producing some of the finest coffee
          around the globe.
        </p>
      </Trans>
    </ArtworkTag>
  );
};
