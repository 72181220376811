import { useCallback, useState } from "react";

export interface PaginatorHookState {
  currentPage: number;
  totalPages: number;
  homePage: number;
  direction: "prev" | "next";
}

export type PaginatorHookOptions = Partial<PaginatorHookState>;

export const usePages = ({
  currentPage: currentPageOption = 0,
  totalPages: totalPagesOption = 0,
  homePage: homePageOption = 0,
  direction: directionOption = "next",
}: PaginatorHookOptions = {}) => {
  const [
    { currentPage, totalPages, direction },
    setState,
  ] = useState<PaginatorHookState>({
    currentPage: currentPageOption,
    totalPages: totalPagesOption,
    homePage: homePageOption,
    direction: directionOption,
  });

  const atFirstPage = currentPage === 0;
  const atLastPage = currentPage === totalPages - 1;

  const nextPage = useCallback(() => {
    setState((prevState) => {
      if (prevState.currentPage < prevState.totalPages - 1)
        return {
          ...prevState,
          direction: "next",
          currentPage: prevState.currentPage + 1,
        };

      return prevState;
    });
  }, []);

  const prevPage = useCallback(() => {
    setState((prevState) => {
      if (prevState.currentPage > 0)
        return {
          ...prevState,
          direction: "prev",
          currentPage: prevState.currentPage - 1,
        };

      return prevState;
    });
  }, []);

  const goHome = useCallback(() => {
    setState((prevState) => {
      return {
        ...prevState,
        direction: prevState.homePage > prevState.currentPage ? "next" : "prev",
        currentPage: prevState.homePage,
      };
    });
  }, []);

  const setTotalPages = useCallback((total: number) => {
    setState((prevState) => {
      return { ...prevState, totalPages: total };
    });
  }, []);

  const setCurrentPage = useCallback((current: number) => {
    setState((prevState) => {
      return { ...prevState, currentPage: current };
    });
  }, []);

  return {
    currentPage,
    totalPages,
    direction,
    atFirstPage,
    atLastPage,
    nextPage,
    prevPage,
    goHome,
    setTotalPages,
    setCurrentPage,
  };
};
