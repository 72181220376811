import React from "react";
import { Vector3 } from "three";
import { Trans, useTranslation } from "react-i18next";

import { ArtworkTag, ArtworkTagPublicProps } from "../../../interactive-tags";

import art from "./art.jpg";

const position = new Vector3(
  -64.72329314009147,
  3.360696193513467,
  -39.49333231784146
);

const sweep = "62c46cdfd3fc4ce2a493eaba42980dd5";

export const TheCollectorTag = (props: ArtworkTagPublicProps) => {
  const { t } = useTranslation();

  return (
    <ArtworkTag
      title={`El Collector (${t(
        "tour.artworks.the-collector.title",
        "The Collector"
      )})`}
      author="Jade Rivera"
      name="el-collector-1"
      position={position}
      image={art}
      sweep={sweep}
      {...props}
    >
      <Trans i18nKey="tour.artworks.the-collector.content" t={t}>
        <p>
          The mural El Recolector (The Collector) is inspired by how humans and
          nature interact. The artist, Jade Rivera, knew coffee trees and other
          foliage would be planted in the area directly in front of this mural,
          so he painted a coffee picker who would appeared to be harvesting when
          the trees and plants adjacent to the mural grew bigger. The
          hummingbird, a species prolific in the area, is holding a coffee
          branch with a flower. Lavender and other flowering bushes were planted
          in the pots and the beds below the bird to make it appear it was
          collecting nectar.
        </p>
      </Trans>
    </ArtworkTag>
  );
};
