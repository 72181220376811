import { Vector3 } from "three";
import { useAudio } from "../../audio";
import { MatterportAudio } from "../../matterport-audio";

import audio from "./cafe-ambient.mp3";

const position = new Vector3(
  -42.011889706593365,
  1.5203380674207334,
  -52.68262242130412
);

const inputs = {
  source: audio,
};

export const CafeAudio = () => {
  const sfx = useAudio();

  return (
    <MatterportAudio
      muted={sfx.isMuted()}
      paused={sfx.isAmbientPaused()}
      position={position}
      inputs={inputs}
    />
  );
};
