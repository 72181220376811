declare global {
  interface Window {
    _analytics: any;
    uo_data: any;
  }
}

const useAnalytics = () => {
  const analytics = window._analytics;
  const trackEvent = analytics.trackEvent;
  const trackPageView = analytics.trackPageView;
  const sendEvent = (
    category: string,
    action: string,
    label = null,
    dimensions: object
  ) => {
    console.log(
      `use-analytics in hooks- sendEvent, category: ${category}; action: ${action}`
    );

    trackEvent(category, action, label, dimensions);
    window.uo_data = {
      ...window.uo_data,
      ...dimensions,
    };
  };
  return { sendEvent, trackPageView };
};
export default useAnalytics;
